import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, Button, Paper, Modal, Typography, ListItem, ListItemText, List, ListSubheader, makeStyles, Tooltip, Divider} from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore, } from '../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUser } from '../../../providers/UserContext';
import { useAuth } from '../../../providers/AuthContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import _ from 'lodash';
import moment from 'moment';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

import TicketCard from '../../components/Ticket/TicketCard';
import TicketFilter from '../../components/Ticket/TicketFilter';

const useStyles = makeStyles((theme) => ({
  content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      maxWidth: '90vw',
      margin: 'auto',
      marginTop: '20px',
      marginBottom: '20px',
  },
  modal: {
    width: '500px',
    padding: '16px',
    margin: '0 auto',
    marginTop: '20vh'
  },
  list: {
    borderBottom: '1px solid grey',
    minHeight: '400px', 
    maxHeight: '50vh',
    marginBottom: '5px',
    overflow: 'auto',
  },
}));

const Ticket = (props) => {
    const classes = useStyles();

    const { customers, loading, getCustomers } = useCustomer();
    const { getUsers, users } = useUser();
    const { userSettings } = useAuth();
    const { units, getUnits } = useUnitDescription();
    const throwAsyncError = useAsyncError();
    const darkMode = !_.isUndefined(userSettings['colorMode']) ? userSettings['colorMode'] : 'light';

    //const getTicketNumber = functions.httpsCallable('createTicket');

    //const isMounted = useRef(false)

    //memoize
    const defaultTicket = {
      //ticketId: 'NA', //remove references to?
      ticket: "Unnumbered",
      date: moment().format("YYYY-MM-DD").valueOf(),
      customerId: null,
      //customer: null,
      //job: null,
      rate: null,
      //salesRate: null,
      notes: '', 
      uid: null,
      units: [null, null, null],
      //equipment: null,
      //trailer1: null, // 
      //trailer2: null, //
      cartageSaleUnits: 0,
      cartageSaleRate: "0.00",
      cartageSalesPer: null,
      cartageSalesTotal: "0.00",
      payrollRate: "0.00",
      payrollUnits: 0,
      payrollPer: null,
      payroll: "0.00",
      payrollAdjustedBase: "0.00",   
      payrollMultiplier: "100.00",
      linkOperatorToSalesRate: false,
      material: null,
      materialSalesRate:"0.00",
      quantity: 0,
      materialSalesTotal: "0.00",
      operatorPayDate: null,
      invoicePayDate: null,
      dataEntryDate: moment().valueOf(),
      //orderId: "Unnumbered",
      percentageFuelSurcharge: "0.00",
      dollarFuelSurcharge: "0.00",
      percentageCabonFee: "0.00",
      dollarCarbonFee: "0.00",

      //NEW *** HATE THESE NAMES *******************
      from: '',
      to: '',

      timeIn: null,
      timeOut: null,
      nextDay: false,
      payrollVerified: false,
      invoiceVerified: false,    
    }

    //holds current ticket changes
    const [currentTicket, setCurrentTicket] = useState({})
    //save state for current ticket being held (in filter), as well as ! badge on save ticket
    const [saveState, setSaveState] = useState({})
    //holds tickets generated by user
    const [ticketEntryData, setTicketEntryData] = useState([]);

    //reates data
    const [ratesData, setRatesData] = useState([]);
    //unique lists swap to collection eventually
    const [per, setPer] = useState([])
    const [materials, setMaterials] = useState([])

    //for use of skeleton on page
    const [internalLoading, setInternalLoading] = useState(true);

    //additional loading for when changing/copying tickets

    //true false, loading and not
    const [generating, setGenerating] = useState(false)
    //0 false, 1 true, 2 confirmation
    const [saving, setSaving] = useState(0)

    //modal states
    const [listModalOpen, setListModalOpen] = useState(false)
    const [rowSelected, setRowSelected] = useState(null)

    const [quickRateModal, setQuickRateModal] = useState({
      open: false,
      selected: null,
    })

    const [taxSettings, setTaxSettings] = useState([])

    //WHY ARE THES ALL SEPERATE SESSION STORAGE VALUES ???
    //change current radio into currentValidated
    const [filterValues, setFilterValues] = useState({
      contactAutocompleteValue: "ticketFindByCustomerFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByCustomerFilter")) : null,
      currentRadio: "ticketRadioFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketRadioFilter")) : 'all',
      dateRange: "ticketFilterDateRange" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFilterDateRange")) : {start: moment().subtract(1, 'week').valueOf(), end: moment().valueOf()},
      orderType: "ticketOrderTypeRadio" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketOrderTypeRadio")) : 'all',
    })

    //change to ticketCard autocompletes
    const [autoCompletes, setAutoCompletes] = useState({
        uid: { open: false, value: {}}, units: { open: false, value: {}}, trailer1: { open: false, value: {}}, trailer2: { open: false, value: {}},
        cartageSalesPer: { open: false, value: {}}, customerId: { open: false, value: {}}, job: { open: false, value: {}}, rate: { open: false, value: {}},
        payrollPer: { open: false, value: null}, material: { open: false, value: null},
    })
    //used in callbacks where data is not readibly available
    const autocompleteRef = useRef({
      uid: { open: false, value: {}}, units: { open: false, value: {}}, trailer1: { open: false, value: {}}, trailer2: { open: false, value: {}},
      cartageSalesPer: { open: false, value: {}}, customerId: { open: false, value: {}}, job: { open: false, value: {}}, rate: { open: false, value: {}},
      payrollPer: { open: false, value: null}, material: { open: false, value: null},
  })

    //memoize ?
    const quickRates = {
      farmPickup: {
        notes: 'Farm Pickup',
        payrollRate: "12.00",
        payrollUnits: 1,
        payrollPer: 'Hour',
        cartageSaleRate: "0.00",
        cartageSaleUnits: 2,
        cartageSalesPer: 'Hour',
        invoiceSuffix: 'P',
        payrollAdjustedBase: "1.00",
      },
      waitingTime: {
        notes: 'Waiting Time',
        payrollRate: "10.00",
        payrollUnits: 1,
        payrollPer: 'Hour',
        cartageSaleRate: "50.00",
        cartageSaleUnits: 2,
        cartageSalesPer: 'Hour',
        invoiceSuffix: 'W',
        payrollAdjustedBase: "1.00",
      }
    }

    //gets the inital data
    useEffect(() => {
      if(users.length === 0){ getUsers() }
      if(customers.length === 0){ getCustomers() }
      if(units.length === 0){ getUnits() }

      firestore.collection('shopSettings').doc("taxes").get()
      .then((doc) => {
        let taxData = doc.data()
        setTaxSettings(taxData);

        firestore.collection('rates').get()
        .then(querySnapshot => {
            const ratesResponse = querySnapshot.docs.map((doc) => {
              return {
                  ...doc.data(),
                  'docId': doc.id,     
              }
            })
            setRatesData(ratesResponse);

            //gets all ticket data
            pullTicketData().then((res) => {
              //gets generative data
              setPer(retrieveField(res, ['payrollPer', 'cartageSalesPer']))
              setMaterials(retrieveField(res, ['material']))

              //checks for and sets current ticket
              let currentTicketDocId = "ticketFilterValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFilterValue")) : null
              let currentTicketValue = res.find(x=> x.docId === currentTicketDocId)
              !_.isUndefined(currentTicketValue) ? handleChangeCurrentTicket(currentTicketValue, taxData) : handleChangeCurrentTicket(defaultTicket, taxData)

              setTicketEntryData(res)
              setInternalLoading(false)
            })
          })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //gets ticket data, returns data upon promise resolution
    const pullTicketData = () => {
      return new Promise((resolve) => {
        let query = firestore.collection('deliveries')

        //date range
        query = query.where("date", ">=", moment(filterValues.dateRange.start).format('YYYY-MM-DD'))
        query = query.where("date", "<=", moment(filterValues.dateRange.end).format('YYYY-MM-DD'))
        //contact
        if(filterValues.contactAutocompleteValue !== null){ query = query.where('customerId', '==', filterValues.contactAutocompleteValue.docId) }
        //order type
        if(filterValues.orderType !== 'all'){ query = query.where('orderType', '==', filterValues.orderType) }

        query.get()
        .then((querySnapshot) => { 
          const ticketentryResponse = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,     
            }
          })

          let container = _.cloneDeep(ticketentryResponse)
          //filter here for as there is possibility the fields are not avaiable 
          if(filterValues.currentRadio !== 'all'){
            switch(filterValues.currentRadio) {
              case 'notInvoiced':
                container = container.filter(x=> x.invoicePayDate === null || x.invoicePayDate === undefined)
                break;
              case 'notPayrolled':
                container = container.filter(x=> x.operatorPayDate === null || x.operatorPayDate === undefined)
                break;
              case 'invoicedAndPayrolled':
                container = container.filter(x=> (x.invoicePayDate && x.invoicePayDate !== null) && (x.operatorPayDate && x.operatorPayDate !== null))
                break;
              default:
                throw new Error('Current verified value is not part of radio.')
            } 
          }

          resolve(container.sort((a, b) => (a.date < b.date) ? 1 : -1))
        })
        .catch((e) => {
          throwAsyncError(new Error(e.message, e));
        })
      })
    }

    const handleGenerateTicketData = useCallback(async () => {
      setGenerating(true)
      pullTicketData().then((res) => { 
        setTicketEntryData(res) 
        setListModalOpen(true)
        setGenerating(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValues]);

    const handleInstructionsRateChanges = (previousTicketValue, newTicketValue) => {
      console.log('handleInstructionsRateChanges')
    }

    //gets unit data regardless of source collection ? (as far as i remember - reread, comment proper)
    const retrieveUnitData = useCallback(async (unitDocIdArray) => {
      let unitsContainer = []

      for (const docId of unitDocIdArray) {
        if(docId === null){
          unitsContainer.push(undefined)
        }
        else{
          let findUnit = units.find(x=> x.docId === docId)
          if(!_.isUndefined(findUnit)){  
            unitsContainer.push(findUnit); 
          }
          else{
            await firestore.collection('units').where("docId", "==", docId).get()
            .then(querySnapshot => {
              const unitResponse = querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  'docId': doc.id,
                };
              });

              //get the parent document, if no ref to that document exists add an error field
              let parentDoc = units.find(x=> x.docId === unitResponse[0].parentUnitDocId)
              if( _.isUndefined(parentDoc)){ unitsContainer.push({...unitResponse[0]});  }
              else{ unitsContainer.push(parentDoc);  }
            })
          }
        }
      }
      return unitsContainer;
    },[units],);

    //used to generate a set fom a particular field
    const retrieveField = (data, fieldSet) => {
        let returnContainer = []
        fieldSet.forEach(field => {
            data.forEach((doc) => {  (!_.isEmpty(doc[field]) && !_.isUndefined([field])) && returnContainer.push(doc[field])   }) 
        })
        return ([...new Set(returnContainer)])
    }

    const handleCopyTicket = async () => {
      setInternalLoading(true)
      let container = _.cloneDeep(currentTicket)
      container.ticket = container.ticket.concat(" - copy");
      container.operatorPayDate = null;
      container.invoicePayDate = null;
      container.payrollVerified = false;
      container.invoiceVerified = false;
      //container.ticketId = await getTicketNumber().then((res) => {return res.data})

      firestore.collection('deliveries').add(container)
      .then((docRef) => {
        container.docId = docRef.id;

        handleChangeCurrentTicket(container)
        setTicketEntryData([...ticketEntryData, container])
        setInternalLoading(false)
      })
      .catch(function (error) {
          console.warn(error);
          setInternalLoading(false)
      }); 
    }

    //changes all autocompletes to reflect new ticket values, is callback with customers, users, units, ratesData,  in the dependancy array
    const handleChangeCurrentTicket = useCallback(async (newValue, taxData) => {
      //console.log('handleChangeCurrentTicket', newValue)

      let taxes = taxData ? taxData : taxSettings;
      let container = _.cloneDeep(autocompleteRef.current)
      let ticketContainer = _.cloneDeep(newValue)
      !_.isUndefined(newValue.docId) ? sessionStorage.setItem('ticketFilterValue', JSON.stringify(newValue.docId)) : sessionStorage.removeItem("ticketFilterValue");

      //set values for autocompletes
      for (const [key, value] of Object.entries(ticketContainer)) {
        if(container.hasOwnProperty(key)){
          switch(key) {
            case 'customerId':
              let customerObj = customers.find(x=> x.docId === ticketContainer[key])
              container[key].value = !_.isUndefined(customerObj) ? customerObj : value;
              break;
            case 'uid':
              let usersObj = users.find(x=> x.uuid === ticketContainer[key])
              container[key].value = !_.isUndefined(usersObj) ? usersObj : value;
              break;
            case 'units':
              //returns the parent docs of the units documents that contain the parent doc field
              await retrieveUnitData(ticketContainer.units).then((unitsValue) => {
                let unit0Obj = units.find(x=> x.docId === unitsValue[0]?.docId)
                container['units'].value = !_.isUndefined(unit0Obj) ? unit0Obj : !_.isUndefined(unitsValue[0]) ? {unitNumber: unitsValue[0].unitNumber} : null;
                let unit1Obj = units.find(x=> x.docId === unitsValue[1]?.docId)
                container['trailer1'].value = !_.isUndefined(unit1Obj) ? unit1Obj : !_.isUndefined(unitsValue[1]) ? {unitNumber: unitsValue[1].unitNumber}  : null;
                let unit2Obj = units.find(x=> x.docId === unitsValue[2]?.docId)
                container['trailer2'].value = !_.isUndefined(unit2Obj) ? unit2Obj : !_.isUndefined(unitsValue[2]) ? {unitNumber: unitsValue[2].unitNumber}  : null;   
              })
              break;
            case 'rate':
              let rateObj = ratesData.find(x=> x.docId === ticketContainer[key])
              container[key].value = !_.isUndefined(rateObj) ? rateObj : value;
              break;
            case 'material':
              container[key].value = value;
              break;
            case 'cartageSalesPer':
              container[key].value = value;
              break;
            case 'payrollPer':
              container[key].value = value;
              break;
            default:
              console.log(key)
              throw new Error('key was not part of collection', key)
          } 
        }
      }

      //set any default values the ticket is missing
      for (const [key, value] of Object.entries(defaultTicket)) {
        if(!ticketContainer.hasOwnProperty(key)){
          ticketContainer[key] = value;
        }
        //fix units array to be 3 in length, filling empty values with null
        if(ticketContainer.units.length !== 3){
          for(let i = 0; i < 3; i++){
            if(_.isUndefined(ticketContainer.units[i])){ ticketContainer.units[i] = null; }
          }
        }
      }

      //set carbon fee
      if(ticketContainer.percentageCabonFee === "0.00"){  
        let returnValue;
        if("ticketCarbonFeePercent" in sessionStorage){ returnValue = JSON.parse(sessionStorage.getItem("ticketCarbonFeePercent")) }
        else if(taxes.fuelSurcharge){ returnValue = taxes.fuelSurcharge }
        else{ throw new Error('Carbon Fee value was not found when trying to set.') }

        ticketContainer.percentageCabonFee = returnValue;
        ticketContainer.dollarCabonFee  = Number(Number(returnValue / 100)  * Number(currentTicket.cartageSalesTotal || 0.00)).toFixed(2)
      }

      //set fuel surcharge
      if(ticketContainer.percentageFuelSurcharge === "0.00"){  
        let returnValue;
        if("ticketFuelSurchargePercent" in sessionStorage){ returnValue = JSON.parse(sessionStorage.getItem("ticketFuelSurchargePercent")) }
        else if(taxes.fuelSurcharge){ returnValue = taxes.fuelSurcharge }
        else{ throw new Error('Fuel Surcharge value was not found when trying to set.') }

        ticketContainer.percentageFuelSurcharge = returnValue;
        ticketContainer.dollarFuelSurcharge  = Number(Number(returnValue / 100)  * Number(currentTicket.cartageSalesTotal || 0.00)).toFixed(2)
      }

      setAutoCompletes(container)
      autocompleteRef.current = container
      setCurrentTicket(ticketContainer)
      setSaveState(ticketContainer)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[customers, users, units, ratesData, taxSettings]);

    //callback for setting autocompletes
    const handleSetFilterValues = useCallback((e) => { setFilterValues(e) }, [],);

    const confirmSave = () => {
      setSaving(1);
      let docId = currentTicket.docId;

      if(_.isUndefined(docId)){
        //create query to add docId to document before update
        let query = firestore.collection('deliveries').doc()
        currentTicket.docId = query.id;

        query.set(currentTicket)
          .then(() => {
            setSaveState(_.cloneDeep(currentTicket));

            let container = _.cloneDeep(ticketEntryData)
            container.push(currentTicket)
            setTicketEntryData(container)

            //set to currently selected ticket
            sessionStorage.setItem('ticketFilterValue', JSON.stringify(currentTicket.docId))

            setSaving(2)
            setTimeout(() => { setSaving(0) }, 1000);
          })
          .catch((e) => { console.warn(e); });
      }
      else{
        firestore.collection('deliveries').doc(currentTicket.docId).update(currentTicket)
          .then(() => {
            setSaveState(_.cloneDeep(currentTicket));

            let container = _.cloneDeep(ticketEntryData)
            container.splice(container.findIndex(x=> x.docId === currentTicket.docId), 1, currentTicket)
            setTicketEntryData(container)

            setSaving(2)
            setTimeout(() => { setSaving(0)}, 1000);

          })
          .catch((e) => { console.warn(e); });
      }
    };

    const handleSettingQuickRate = (rateChosen) => {
      //console.log('rateChosen', rateChosen)

      let container = _.cloneDeep(currentTicket)
      let autocompleteContainer = _.cloneDeep(autoCompletes)

      Object.entries(rateChosen).forEach(([key, value]) => {
          if(container.hasOwnProperty(key)){
            container[key] = value;

            //settting autocompletes
            if(key === 'cartageSalesPer' || key === 'payrollPer'){
              autocompleteContainer[key].value = value
            }
          }
          else if(key === 'invoiceSuffix'){  //should only be suffix
            container['ticket'] = container['ticket'].concat(` - ${value}`)
          }
          else{
            throw new Error('no key of that type was found.')
          }

          //additional math, to get totals from various fields
          let payroll = container.payroll;
          let cartageSalesTotal = container.cartageSalesTotal;
          let dollarFuelSurcharge = container.dollarFuelSurcharge;
  
          if(container.linkOperatorToSalesRate){
            payroll = Number((Number((container.payrollAdjustedBase)) * Number(container.payrollUnits)) * (Number((container.payrollRate)))).toFixed(2)
          }
          else{
            payroll = Number((Number(container.payrollUnits)) * (Number((container.payrollRate)))).toFixed(2)
          }
          cartageSalesTotal = Number(Number(container.cartageSaleUnits) * Number((container.cartageSaleRate))).toFixed(2)
          dollarFuelSurcharge = Number(Number((container.percentageFuelSurcharge) / 100)  * Number(Number((container.cartageSaleRate)) * Number((container.cartageSaleUnits)))).toFixed(2)

          container.payroll = payroll;
          container.cartageSalesTotal = cartageSalesTotal;
          container.dollarFuelSurcharge = dollarFuelSurcharge;

          setAutoCompletes({...autocompleteContainer})
          setCurrentTicket({...container})
          //setSaveState(_.cloneDeep({...container}))
      })
    }

    const handleListItemClick = (event, index) => {
      setQuickRateModal({...quickRateModal, selected: index});
    };

    const handleNewTicket = () => {
      handleChangeCurrentTicket(defaultTicket);
    }

    const handleCalcPayrollHours = (timeIn, timeOut, nDay) => {
      let returnValue = 0.00;
      let start = moment(timeIn || currentTicket.timeIn)
      let end = moment(timeOut || currentTicket.timeOut)
      let nextDay = !_.isUndefined(nDay) ? nDay : currentTicket.nextDay

      if((start !== null || start !== undefined) && (end !== null || end !== undefined)){
          if(nextDay){
              let duration = moment.duration(end.diff(start));
              duration = duration.add(24, 'hours');
              returnValue = duration.asHours().toFixed(2);
          }
          else{
              let duration = moment.duration(end.diff(start));
              returnValue = duration.asHours().toFixed(2);
          }
      }
      return returnValue;
    }

    //upadates verifieds to db and sets local states
    const handleVerifyItem = (field) => {
      //make initial changes
      let newBool = currentTicket[field] ? !currentTicket[field] : true
      setCurrentTicket({ ...currentTicket, [field]: newBool })  
      setSaveState({ ...currentTicket, [field]: newBool }) 

      firestore.collection('deliveries').doc(currentTicket.docId).update({[field]: newBool})
      .then(() => { 
        let container = _.cloneDeep(ticketEntryData)
        // gets the entry in ticket entry data and changes only field updated here
        let limitedChangedTicket = container.find(x=> x.docId === currentTicket.docId)
        limitedChangedTicket[field] = newBool
        container.splice(container.findIndex(x=> x.docId === currentTicket.docId), 1, limitedChangedTicket)
        setTicketEntryData(container)
      })
    }

    //returns style object for each list item
    const checkListStyle = (item) => {
      if(item.payrollVerified && item.invoiceVerified){
        if(rowSelected?.docId === item?.docId){ return {backgroundColor: darkMode?'#22401d':'#80DE70'} }
        else{ return {backgroundColor: '#80EF80'} }
      }

      if(item.payrollVerified ){
        if(rowSelected?.docId === item?.docId){ return {backgroundColor: '#FFFF9D'} }
        else{ return {backgroundColor: '#FFEE8C'} }
      }

      if(item.invoiceVerified ){
        if(rowSelected?.docId === item?.docId){ return {backgroundColor: '#83DAE1'} }
        else{ return {backgroundColor: darkMode?'#003647':'#83EBF2'} }
      }
    }

  return (
    <Grid>
        {internalLoading 
        ?
            <Skeleton variant='rect' width={'91vw'} height={'70vh'} style={{margin: 'auto', marginTop: '75px'}} />
        :      
            <Grid>
              {/* quick rate modal */}
              <Modal
                  open={quickRateModal.open}
                  onClose={() => { setQuickRateModal({...quickRateModal, open: false})}}
                  style={{display:'flex', alignItems:'center', justifyContent:'center'}}
              >
                  <Paper style={{padding:'10px', margin:'20px',}}>
                    <Grid container justifyContent='center'>
                      <Typography variant='h6'>Quick Rates:</Typography>
                    </Grid>

                    <Grid container>
                      <Grid container justifyContent='center'>
                        <List component="nav" style={{backgroundColor: 'lightGrey', border: '1px solid black'}}>
                          {Object.entries(quickRates).map(([key, value], index) => {
                            return (
                                <ListItem
                                  key={key}
                                  selected={quickRateModal.selected === key}
                                  onClick={(event) => {
                                    handleListItemClick(event, key)
                                  }}
                                >
                                  <ListItemText primary={`${value.notes} - Payroll Rate: $${Number(value.payrollRate).toFixed(2)}, Payroll Units: ${value.payrollUnits}, Payroll Per: ${value.payrollPer}, 
                                                Cartage Rate: $${Number(value.cartageSaleRate).toFixed(2)},  Cartage Units: ${value.cartageSaleUnits},  Cartage Per: ${value.cartageSalesPer}, 
                                                Invoice Suffix: ${value.invoiceSuffix},`} />
                                </ListItem>
                            )
                          })}
                        </List>
                      </Grid>

                      <Grid container justifyContent='flex-end'>
                          <Button
                            style={{margin: '10px'}}
                            variant='contained'
                            onClick={(e)=> { 
                              handleSettingQuickRate(quickRates[quickRateModal.selected])
                              setQuickRateModal({selected: null, open: false})
                            }}
                          >
                            Confirm
                          </Button>
                      </Grid>
                    </Grid>
                  </Paper>
              </Modal>

              {/* list modal */}
              <Modal
                  open={listModalOpen}
                  onClose={() => setListModalOpen(false)}
              >
                <Paper className={classes.modal}>
                  <ListSubheader component="div" style={{borderBottom: '1px solid grey',}}>
                    <Grid container>
                      <Grid item xs={6}>
                        Choose a Ticket
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container justifyContent='flex-end'>
                            <Tooltip
                              title={
                                <Grid container>
                                  <Grid item xs={6}>
                                    <div style={{ borderRadius: '50%', width: '25px', height: '25px', backgroundColor: '#FFEE8C', }} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>Verified for Payroll</Typography>
                                  </Grid>
                                  <Divider style={{width: '100%', marginBottom: '5px'}}/>
                                  <Grid item xs={6}>
                                    <div style={{ borderRadius: '50%', width: '25px', height: '25px', backgroundColor: '#83EBF2', }} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>Verified for Invoice</Typography>
                                  </Grid>
                                  <Divider style={{width: '100%', marginBottom: '5px'}}/>
                                  <Grid item xs={6}>
                                    <div style={{ borderRadius: '50%', width: '25px', height: '25px', backgroundColor: '#80EF80', }} />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>Verified for Both</Typography>
                                  </Grid>
                                </Grid>
                              }
                            >
                              <Typography style={{marginTop: '10px'}}>Legend</Typography>
                            </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListSubheader>
                  <List component="nav" disablePadding={true} dense={true} className={classes.list}>
                      {ticketEntryData.map((item, index) => {
                        return (
                          <ListItem 
                            key={index}
                            selected={rowSelected?.docId === item?.docId} 
                            style={checkListStyle(item)}
                            button 
                            onClick={()=> { setRowSelected(item) }}
                          >
                            <ListItemText 
                              primary={`Ticket # ${item.ticket} - ${moment(item.date).format('DD-MMM-YYYY')}`} 
                              secondary={customers.find(x=> x.docId === item.customerId)?.name || item.customer} 
                            /> 
                          </ListItem>
                        )
                      })}
                  </List>
                  <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
                    <Button variant='contained' style={{marginRight: '10px'}} onClick={() => { setListModalOpen(false) }}>Cancel</Button>
                    <Button 
                      variant='contained' 
                      onClick={() => {  
                        handleChangeCurrentTicket(rowSelected);
                        setListModalOpen(false)
                      }}
                    >
                        Open
                    </Button>
                  </Grid>
                </Paper>
              </Modal>

              <Grid container>
                  <TicketCard 
                      units={units}
                      users={users}
                      customers={customers}
                      per={per}
                      ratesData={ratesData}
                      taxes={taxSettings}
                      materials={materials}

                      internalLoading={internalLoading}

                      currentTicket={currentTicket}
                      setCurrentTicket={setCurrentTicket}

                      saveState={saveState}
                      handleVerifyItem={handleVerifyItem}

                      autoCompletes={autoCompletes}
                      setAutoCompletes={setAutoCompletes}

                      handleCopyTicket={handleCopyTicket}

                      quickRateModal={quickRateModal}
                      setQuickRateModal={setQuickRateModal}

                      handleInstructionsRateChanges={handleInstructionsRateChanges}
                      confirmSave={confirmSave}
                      saving={saving}
                      handleCalcPayrollHours={handleCalcPayrollHours}

                      handleNewTicket={handleNewTicket}

                  />
                </Grid>
                
                <Grid container>
                  <TicketFilter 
                      customers={customers}
                      loading={loading}
                      internalLoading={internalLoading}

                      ticketEntryData={ticketEntryData}
                      handleChangeCurrentTicket={handleChangeCurrentTicket}

                      filterValues={filterValues}
                      handleSetFilterValues={handleSetFilterValues}

                      handleGenerateTicketData={handleGenerateTicketData}

                      saveState={saveState}
                      generating={generating}
                  />  
                </Grid>
            </Grid>
        }

    </Grid>
  )
}

export default withRouter(Ticket);