import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Grid, Paper, Snackbar, TextField, Typography, Chip, FormControlLabel, Checkbox, Divider, Collapse } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore, removeFile } from '../../../firebase/firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useAuth } from '../../../providers/AuthContext';
import Dropzone from 'react-dropzone'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { FindReplace } from '@material-ui/icons';
import { storage } from '../../../firebase/firebase';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        padding: theme.spacing(2),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
        minWidth: '150px',
        minHeight: '50px',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
        minWidth: '150px',
        minHeight: '50px',
    },
}));

const HRCard = (props) => {

    const classes = useStyles();

    const [edit, setEdit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = useState({})
    const [newEmail, setNewEmail] = React.useState("");
    const [emails, setEmails] = React.useState(props.hrData.emails || []);
    const [error, setError] = React.useState("");
    const [replacing, setReplacing] = useState(false);
    const [replacingPDF, setReplacingPDF] = useState(0)

    const { roles } = useAuth();
    let hrCard = null;

    const addEmail = () => {
        //props.updateSafetyData({ ...props.safetyData, 'emails': emails })
        if(props.hrData?.emails.includes(newEmail.toLowerCase())){
            setError("Email already exists!")
        } else {
            emails.push(newEmail.toLowerCase())
            setNewEmail("")
            props.updateHrData({ ...props.hrData, 'emails': emails })
            props.saveHrData();
        }
        
    }

    const deleteEmail = (email) => {
        //setTags(props.product.tags)
        const index = emails.indexOf(email);
        if (index > -1) {
            emails.splice(index, 1);
        }
        props.updateHrData({ ...props.hrData, 'emails': emails })
        props.saveHrData();

    }

    useEffect(() => {
        if(newEmail === "")
            setError("")
    }, [props.hrData, setEmails, newEmail])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEdit = () => {
        if (edit)
            props.cancelSave();

        setEdit(!edit);
    }
    const removePDF = async (pdfURL) => {
        let query = await removeFile(pdfURL);
        return query.fileDeleted;
    }

    const handleDeleteClick = async () => {
        props.hrData.pdfURL !== null && await removePDF(props.hrData.pdfURL);
        firestore.collection('humanResources').doc(props.hrData.docId).delete().then(() => {
            console.log("HR Document successfully deleted!");
            props.history.goBack();
        }).catch((error) => {
            console.error("Error removing HR Document: ", error);
        });
    }

    const handleReplaceClick = async () => {
        setReplacingPDF(1)

        //delete pdf (if it has one)
        props.hrData.pdfURL !== null && removePDF(props.hrData.pdfURL);

        //ref storage location of pdfs
        const storageRef = storage.ref();
        //let docId = firestore.collection('pdfforms').doc().id;
        const formRef = storageRef.child('HR/' + props.hrData.name + '.pdf');

        //pdf upload 
        formRef.put(formData).then((ref) => {
            formRef.getDownloadURL().then((url) => {
                //formData.pdfURL = url;

                //update pdfURL
                props.saveHrData({...props.hrData, 'pdfURL': url.toString()});
                //update save state
                props.setSaveState({...props.saveState, 'pdfURL': url.toString()});
            
                setReplacingPDF(-1)
                
                setTimeout(() => { 
                    setReplacing(false); 
                    setFormData({})
                    setReplacingPDF(0)
                }, 1000)
                })
            });
    }

    const replacingOpen = () => {
        setReplacing(true);
    };

    const replacingClose = () => {
        setReplacing(false);
    };

    const handleUpload = (file) => {
        setFormData(file[0]);
    };

    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    if (!_.isUndefined(props.hrData)) {
        hrCard = (
            <Grid className={classes.content}>

                <Grid container className={classes.backButton}>
                    <Grid item xs={1}> 
                        <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                    </Grid>

                    <Grid item xs={11}>
                        <Grid container justifyContent='flex-end'>
                            <Button 
                                variant="contained" 
                                disabled={!roles.includes("Create|Update|Delete Human Resource Documents")}
                                style={edit ? { backgroundColor: 'green', marginRight: '5px' } : {marginRight: '5px'}}
                                startIcon={<EditIcon/>} 
                                onClick={handleEdit}
                            >
                                Edit
                            </Button>

                            <Button 
                                variant="contained" 
                                style={{marginRight: '5px'}} 
                                startIcon={<FindInPageIcon/>} 
                                onClick={() => { window.open(props.hrData.pdfURL, "_blank")}}
                            >
                                View In Browser
                            </Button>

                            <Button 
                                variant="contained" 
                                style={{marginRight: '5px'}} 
                                startIcon={<FindReplace/>} 
                                disabled={!roles.includes("Create|Update|Delete Human Resource Documents")}
                                onClick={replacingOpen}
                            >
                                Replace Form
                            </Button>

                            <Button 
                                variant="contained" 
                                style={{marginRight: '5px'}} 
                                startIcon={<DeleteIcon/>}
                                disabled={!roles.includes("Create|Update|Delete Human Resource Documents")}
                                onClick={handleClickOpen}
                            >
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>


                <Paper className={classes.paper}>
                    {/* delete dialog */}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete HR Document?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this HR Document?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" color="secondary">
                                No
                            </Button>
                            <Button onClick={handleDeleteClick} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* replace dialog */}
                    <Dialog
                        open={replacing}
                        onClose={replacingClose}
                        aria-labelledby="replace-dialog-title"
                        aria-describedby="replace-dialog-description"
                    >
                        <DialogTitle id="replace-dialog-title">{"Replace PDF With:"}</DialogTitle>
                        <DialogContent style={{minWidth: '500px'}}>
                            <Grid container className={formData?.name ? classes.fileInputAccept : classes.fileInput}>
                                <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}} onDrop={handleUpload}>
                                    {({ getRootProps, getInputProps }) => (
                                        <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                            <input {...getInputProps()} style={{ display: 'none' }} />
                    
                                            <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                            <Typography variant='h6'>
                                                {formData?.name ? substringText(formData?.name) : "Upload Document (.pdf)"}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Dropzone>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container justifyContent='flex-end'> 
                                <Button onClick={replacingClose} variant="contained" color="secondary" style={{marginRight: '5px'}}>
                                    Back
                                </Button>
                                <Button onClick={handleReplaceClick} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                                    {replacingPDF === 0 ? "Submit" : replacingPDF === 1 ? "Submitting..." : "Sumbitted!"}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>


                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            {edit ?
                                <React.Fragment>
                                    <TextField
                                        label='Name'
                                        value={props.hrData?.name}
                                        variant='outlined'
                                        onChange={(event) => { props.updateHrData({ ...props.hrData, 'name': event.target.value }) }}
                                        inputProps={{ style: { fontSize: '2.125rem' } }} />

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Typography align='left' variant='h4'>{props.hrData?.name}</Typography>
                                </React.Fragment>
                            }
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                <Grid item sm={5} className={classes.row}>
                                        <Typography variant='body1'>Form Created</Typography>
                                    </Grid>
                                    <Grid item sm={7} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                        {edit
                                            ? <KeyboardDatePicker
                                                format='YYYY/MM/DD'
                                                value={moment.unix(props.hrData.dateCreated)}
                                                onChange={(date) => { props.updateHrData({ ...props.hrData, 'dateCreated': date.unix() }) }}
                                                fullWidth />
                                            : <Typography>{moment(props.hrData.dateCreated * 1000).format('YYYY/MM/DD')}</Typography>
                                        }
                                    </Grid>
                                    <Grid item sm={5} className={classes.row}>
                                        <Typography variant='body1'>Last Updated</Typography>
                                    </Grid>
                                    <Grid item sm={7} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                        {edit
                                            ? <KeyboardDatePicker
                                                format='YYYY/MM/DD'
                                                value={moment.unix(props.hrData.dateUpdated)}
                                                onChange={(date) => { props.updateHrData({ ...props.hrData, 'dateUpdated': date.unix() }) }}
                                                fullWidth />
                                            : <Typography>{props.hrData.dateUpdated ? moment(props.hrData.dateUpdated * 1000).format('YYYY/MM/DD') : ''}</Typography>
                                        }
                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    {!edit
                                        ? <Grid item sm={12}>
                                            <Typography variant='h6'>Description</Typography>
                                        </Grid>
                                        : null}

                                    <Grid item sm={12}>
                                        {edit
                                            ? <TextField variant='outlined'
                                                label='Description'
                                                multiline rows={5}
                                                fullWidth
                                                value={props.hrData.description}
                                                onChange={(event) => { props.updateHrData({ ...props.hrData, 'description': event.target.value }) }} />
                                            : <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.hrData.description}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={!_.isUndefined(props.hrData.allowAppSubmission)? props.hrData.allowAppSubmission : false}
                                onChange={props.handleCheckboxChange}
                                name="allowAppSubmission"
                                color="primary"
                            />
                            }
                            label="Allow App Submission?"
                        />
                        <Divider/>
                        </Grid>
                        <Grid item sm={12}>
                            <Collapse in={props.hrData.allowAppSubmission} timeout="auto">
                                <Grid item sm={12}>
                                    <Typography variant='h6'>App Settings</Typography>
                                </Grid>
                                <Grid item sm={12}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={props.hrData.gathersWeatherData}
                                        onChange={props.handleCheckboxChange}
                                        name="gathersWeatherData"
                                        color="primary"
                                    />
                                    }
                                    label="Grabs Weather Data on Submission?"
                                />
                                </Grid>

                                <Grid item sm={12}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={props.hrData.hasPhotos}
                                        onChange={props.handleCheckboxChange}
                                        name="hasPhotos"
                                        color="primary"
                                    />
                                    }
                                    label="Allow Photo(s) to be attached?"
                                />
                                </Grid>

                                <Grid item sm={12}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={props.hrData.appearsInEmployeePage}
                                        onChange={props.handleCheckboxChange}
                                        name="appearsInEmployeePage"
                                        color="primary"
                                    />
                                    }
                                    label="Store submit forms in Employee page?"
                                />
                                </Grid>

                                <Grid item sm={12}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={props.hrData.sendsEmail}
                                        onChange={props.handleCheckboxChange}
                                        name="sendsEmail"
                                        color="primary"
                                    />
                                    }
                                    label="Send Email(s) on submit?"
                                />
                                </Grid>

                                <Grid item sm={12}>
                                    <Typography>Email(s) to send on submit*</Typography>
                                    <Divider/>
                                    <TextField
                                        placeholder={"Add new Email"}
                                        disabled={!props.hrData.sendsEmail}
                                        type="email"
                                        name="emailenter"
                                        value={newEmail || ""}
                                        onChange={(event) => setNewEmail(event.target.value)}
                                        onKeyPress={(ev) => {
                                            if (ev.key === 'Enter' && newEmail !== "") {
                                                addEmail()
                                                ev.preventDefault();
                                            }
                                        }}
                                        />
                                        {error &&
                                <p style={{color:"red"}}><small>{error}</small></p>}
                                <Typography></Typography>
                                {props.hrData?.emails && props.hrData?.emails.length > 0 ? props.hrData.emails.map((email, index) =>  {
                                        return (
                                            <Chip
                                            key={index}
                                            size="small"
                                            style={{padding:'4px', margin:'4px'}}
                                            label={email}
                                            disabled={!props.hrData.sendsEmail}
                                            onDelete={(event) => deleteEmail(email)}
                                            color="secondary"
                                            />
                                        )
                                    })
                                    : <p><small>No emails available.</small></p>}
                                </Grid>
                                </Collapse>
                            </Grid>
                       

                        <Grid item xs={12}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'50px'}}>
                                <iframe
                                    src={props.hrData.pdfURL}
                                    title="PDF Viewer"
                                    width="1000px"
                                    height="800px"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <div className={classes.content}>
                {hrCard}
            </div>
            <Snackbar
                open={!_.isEqual(props.hrData, props.saveState)}
                message={props.saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {props.saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={props.saveHrData}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
        </React.Fragment>
    )
}

export default withRouter(HRCard)