import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import { Paper, IconButton} from '@material-ui/core';
import clsx from 'clsx';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
import PhotoOptions from '../../../components/Reports/Photos/PhotosOptions';
import moment from 'moment';
import _, { isNull } from 'lodash';
import { firestore } from '../../../../firebase/firebase';
import { Skeleton } from '@material-ui/lab';
//import MediaViewer from '../../../components/UI/MediaViewer/MediaViewer';
import { useUser } from '../../../../providers/UserContext'
import { useCustomer } from '../../../../providers/CustomerContext';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import PhotoTable from '../../../components/Reports/Photos/PhotoTable';
import PhotoDownloadModal from '../../../components/Reports/Photos/PhotoDownloadModal';
import retrieveEFIX from '../../../components/UI/RetrieveEFIX/RetrieveEFIX';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //overflow: "scroll",
    },
    scroll: {
        maxHeight: '800px',
        overflowY: "scroll",
    },
    downloadModal: {
        marginRight: '60px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingBottom: '100px',
        marginTop: '50px',
    },
    paper: {
        backgroundColor: 'rgba(1,1,1,0.9)',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dlpaper: {
        width: '390px',
     
        margin: '5px 5px 5px 5px',
    },
    form: {
        padding: theme.spacing(4),
        width: '600px',
    },
    card: {
        padding: theme.spacing(3),
        display: 'flex',
        height: '100%',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: 'anywhere',
    },

    weatherTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    },

    end: {
        justifyContent: 'flex-end',
    },

    temp: {
        justifyContent: 'center',
        border: '4px solid white',
        borderRadius: '64px',
    },

    weatherCard: {
        backgroundColor: 'rgb(59, 98, 177)',
        boxShadow: 'inset 0px 0px 2px 2px rgba(0,0,0,0.3)',
    },
    downloadImg: {
        height: '225px',
        maxWidth: '300px'
    },
}));

const PhotosReport = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { users, getUsers, userLoading } = useUser();
    const { customers, getCustomers, customerLoading} = useCustomer();
    const tableRef = useRef(MaterialTable);

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [progress, setProgress] = useState(0);
    const [loadingText, setLoadingText] = useState("");
    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [photoData, setPhotoData] = useState("photoData" in sessionStorage ? (JSON.parse(sessionStorage.getItem("photoData"))) : []);
    const [filteredPhotoData, setFilteredPhotoData] = useState("filteredPhotoData" in sessionStorage ? (JSON.parse(sessionStorage.getItem("filteredPhotoData"))) : []);
    const [autoCompleteValue, setAutoCompleteValue] = useState("photoAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoAutoComplete")) : "")

    const [locationFilterData, setLocationFilterData] = useState(
        "geolocationPhotoData" in sessionStorage ? JSON.parse(sessionStorage.getItem("geolocationPhotoData")) :
        {
            filterBool: false,
            modalOpen: false,
            location: { center: {}, radius: 0},
        }
    )

    const [ignoreList, setIgnoreList] = useState("ignoreListData" in sessionStorage ? (JSON.parse(sessionStorage.getItem("ignoreListData"))) : []);
    const [customerAutoCompleteValue, setCustomerAutoCompleteValue] = useState("photoCustomerAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoCustomerAutoComplete")) : "")
    const [searchState, setSearchState] = useState({        
        type: JSON.parse(sessionStorage.getItem("photoReportFilterType")) || 'employee',
        value: JSON.parse(sessionStorage.getItem("photoReportFilterValue")) || '',
    });
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [downloadData, setDownloadData] = useState([]);
    const [filteredDownloadData, setFilteredDownloadData] = useState([]);
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    
    useEffect(() => {
        getCustomers()
        getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [smallScreen, ignoreList]);

    const handleChecklistChange = (event) => {
        let containerArray = ignoreList;
        //if is already a part of array (remove from array)
        if(containerArray.includes(event.target.id)){
            containerArray.splice(containerArray.indexOf(event.target.id), 1)
        }
        //if is not included in the array (add to end of array)
        else{
            containerArray.splice(containerArray.length, 1, event.target.id)
        }
        setIgnoreList(containerArray);
        sessionStorage.setItem("ignoreListData", JSON.stringify(containerArray));
        if(photoData.length > 0){
            filterPhotoData(photoData);
        }
    }

    //ky and kx values are km per degree for the latitude and longitude
    const checkIfWithinCircle = (checkPoint, centerPoint, radius) => {
        var ky = 40000 / 360;
        var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
        var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
        var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;

        return Math.sqrt(dx * dx + dy * dy) <= radius;
    }
    
    const filterPhotoData = async (data) => {
        let dataContainer = data;
        for(let i = 0; i < ignoreList.length; i++){
            dataContainer = dataContainer.filter(data => data.origin !== ignoreList[i])
        }

        if(locationFilterData.filterBool){
            let valueContiner = []
            let nextValueContainer = []
            
            //creates url array for efix retrieval
            dataContainer.forEach((photo, index) => {
                valueContiner.push(Array.isArray(photo.photoURLs) ? photo.photoURLs[0] : photo.photoURLs)
            })

            //gets the efix data
            if(!_.isEmpty(valueContiner)){
                await retrieveEFIX(valueContiner).then((value) => {
                    //removes photos with no coordinates
                    valueContiner = value.filter(x=> !_.isEmpty(x.GPScoordinates))

                    //filters based on coordinates within cirlce and appends the data back together
                    valueContiner.forEach((efixValue) => {
                        if(checkIfWithinCircle(efixValue.GPScoordinates, locationFilterData.location.center, (locationFilterData.location.radius / 1000))){

                            let index = dataContainer.findIndex(x=> (Array.isArray(x.photoURLs) ? x.photoURLs[0] : x.photoURLs) === efixValue.url)
                            delete efixValue.url
                            nextValueContainer.push({...dataContainer[index], efixData: {...efixValue}})

                            setFilteredPhotoData(nextValueContainer);
                            try{
                                sessionStorage.setItem("filteredPhotoData", JSON.stringify(nextValueContainer)); 
                            }
                            catch(e){
                                console.log(e.message)
                            }
                        }

                    })
                })
            } 
        }
        else{
            setFilteredPhotoData(dataContainer); 
            try{
                sessionStorage.setItem("filteredPhotoData", JSON.stringify(dataContainer)); 
            }
            catch(e){
                console.log(e.message)
            }
        }
    }
    
    const handleCloseDownloadModal = () => {
        setOpenDownloadModal(false);
    }

    const handleOpenDownloadModal = () => {
        setDownloadData( ([...new Set(tableRef.current.state.data.map(photo => photo))]) );
        setFilteredDownloadData( ([...new Set(tableRef.current.state.data.map(photo => photo))]) );
        setOpenDownloadModal(true);
    }

    const handleDownloadCheckBox = (photo, index) => {
        if(filteredDownloadData.map(x => (x.photoURLs + "_" + x.docId)).includes(photo.photoURLs + "_" + photo.docId) ){
            filteredDownloadData.splice(filteredDownloadData.indexOf(photo), 1);
        }
        else if(!filteredDownloadData.map(x => (x.photoURLs + "_" + x.docId)).includes(photo.photoURLs + "_" + photo.docId) ){
            filteredDownloadData.splice(filteredDownloadData.length, 1, downloadData[index])
        }
    }

    const handleDownloadSingle = (rowSelected) => {
        let fileDate = rowSelected.origin === "checklist" ? new Date(moment.unix(rowSelected.date)).toDateString() : new Date(rowSelected.date).toDateString()
        let fileName = rowSelected.origin.charAt(0).toUpperCase() + rowSelected.origin.slice(1) + "-" + fileDate + "-" + rowSelected.employee;
        let url = (Array.isArray(rowSelected.photoURLs) ? rowSelected.photoURLs[0] : rowSelected.photoURLs);

        var xhr = new XMLHttpRequest();
        xhr.responseType = "arraybuffer";
        xhr.open("GET", url, true);
        
        xhr.onreadystatechange = function () {
            if (xhr.readyState === xhr.DONE) {
                //When request is done
                var file = new Blob([xhr.response], {type:'image/png'});
                saveAs(file, fileName);
            }
        };
        xhr.send();
    }
      
    const handleDownload = () => {
        //creating zip folder
        var zip = new JSZip();
        var zipFilename = "Photos-" + new Date().toDateString() + ".zip";

        //generating content
        filteredDownloadData.forEach((photo, index) =>{
            let fileDate = photo.origin === "checklist" ? new Date(moment.unix(photo.date)).toDateString() : new Date(photo.date).toDateString()
            let employee = photo.employee

            zip.file(
                photo.origin.charAt(0).toUpperCase() + photo.origin.slice(1) + "-" + fileDate + "-" + employee + "-" + index + '.png',
                downloadURL(photo.photoURLs)
              );
        });

        //zip folder and save as
        zip.generateAsync({type:'blob'}).then(function(content) {
        saveAs(content, zipFilename);
        });
    }

    const downloadURL = (url) => {
        return new Promise(function(resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "arraybuffer";
            xhr.open("GET", url, true);
            xhr.onreadystatechange = function(evt) {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  resolve(xhr.response);
                } else {
                  reject(new Error("Error for " + url + ": " + xhr.status));
                }
              }
            };
            xhr.send();
          });
    }

    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }

    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const photosClickHandler = (path, origin) => {
        props.photosClickHandler(path, origin);
    }

    const searchStateHandler = (event) => {
        setSearchState({ ...searchState, [event.target.name]: event.target.value })
        if(event.target.name === "type") sessionStorage.setItem("photoReportFilterType", JSON.stringify(event.target.value))
        if(event.target.name === "value") sessionStorage.setItem("photoReportFilterValue", JSON.stringify(event.target.value)) 
    }

    async function queryGenerator(collection) {
        let query = firestore.collection(collection)

            switch (searchState.type) {
                case 'employee':
                    let uid = users.find(user => user.displayName === autoCompleteValue)?.uuid
                    if (uid) query = query.where('uid', '==', uid)
                    break;
                case 'customer':
                    if (collection === 'jobs'){
                        let cust = customers.find(customer => customer.docId === customerAutoCompleteValue?.docId)
                        if (cust) query = query.where('customerID', '==', cust.docId)
                    }
                    else if (collection === 'deliveries'){
                        let cust = customers.find(customer => customer.docId === customerAutoCompleteValue?.docId)
                        if (cust) query = query.where('customerId ', '==', cust.docId)
                    }
                    else{
                        //THIS WILL RETURN WITH NATTA IN EACH COLLECTION BESIDES ABOVE TWO BY DESIGN (FOR NOW LOL)
                        let cust = customers.find(customer => customer.docId === customerAutoCompleteValue?.docId)
                        if (cust) query = query.where('customerId ', '==', cust.docId)
                    }
                    break;
                default:
                    break;
            }

        query.orderBy('date')

        //queries against each line using startDate and endDate. Each line converts different types of date types. if date type != query date type- will retun empty result set
        const stringDate = query.where("date", ">=", startDate.format('YYYY-MM-DD').toString()).where("date", "<=", endDate.format('YYYY-MM-DD').toString()).get()
        const timeStampDate = query.where("submitTime", ">=", startDate.startOf('day').toDate()).where("submitTime", "<=", endDate.endOf('day').toDate()).get()
        const oldDate = query.where("date", ">=", startDate.startOf('day').unix()).where("date", "<=", endDate.endOf('day').unix()).get()
        const newDate = query.where("date", ">=", startDate.startOf('day').utc().valueOf()).where("date", "<=", endDate.endOf('day').utc().valueOf()).get()

        //waits for all queries to complete, errror handling
        const [oldDateSnapshot, newDateSnapshot, timeStampDateSnapshot, stringDateSnapshot] = await Promise.all([newDate, oldDate, timeStampDate, stringDate]).catch((err) => console.log(err))

        //generates arrays out of query results
        const oldDateArray = oldDateSnapshot.docs
        const newDateArray = newDateSnapshot.docs
        const timeStampDateArray = timeStampDateSnapshot.docs
        const stringDateArray = stringDateSnapshot.docs

        //concats all arrays together
        return _.concat(oldDateArray, newDateArray, timeStampDateArray, stringDateArray)
    }
    
    const deconstructChecklist = (array) => {
        //console.log('deconstructChecklist', array)
        var containerArray = []; 

        //loop through entire array
        for(var i = 0; i < array.length; i++){
            //both defined
            if((!_.isUndefined(array[i].photoURLs.inaccessible) && array[i].photoURLs.inaccessible.length > 4) &&
               (!_.isUndefined(array[i].photoURLs.damage)  && array[i].photoURLs.damage.length > 4)){
                let tempContainer = (array[i].photoURLs.inaccessible) + ', ' + (array[i].photoURLs.damage)
                tempContainer = tempContainer.replaceAll('"', '');
                tempContainer = tempContainer.replaceAll('[', '');
                tempContainer = tempContainer.replaceAll(']', '');
                let stringCount = tempContainer.split(',').length;
                let singleURLsArray = tempContainer.split(',');

                if(stringCount > 0){
                    for(let x = 0; x < stringCount; x++){
                        if(singleURLsArray[x].length > 0 ){
                            let checkListContainer = {employee: array[i].employee,
                                                origin: "checklist",
                                                reason: array[i].photoURLs.inaccessible.contains(singleURLsArray[x]) ? 'inaccessible' : 'damage',
                                                photoURLs: singleURLsArray[x],
                                                date: array[i].date,
                                                docId: array[i].docId,
                                                serviceType: array[i].serviceType,
                                                sandAmount: array[i].sandAmount,
                                                serviceReason: array[i].serviceReason,
                                                equipmentType: array[i].equipmentType,
                                                checkedItems: array[i].checkedItems,
                                                current: array[i].current,
                                                jobId: array[i].jobID,
                            };
                            containerArray.push(checkListContainer);
                        }
                    }
                }
            }

            //inaccessible defined url count > 1
            if(!_.isUndefined(array[i].photoURLs.inaccessible) && (array[i].photoURLs.inaccessible.length > 4) && (array[i].photoURLs.inaccessible.split(',').length > 1)){
                let tempContainer = (array[i].photoURLs.inaccessible);
                tempContainer = tempContainer.replaceAll('"', '');
                tempContainer = tempContainer.replaceAll('[', '');
                tempContainer = tempContainer.replaceAll(']', '');
                let stringCount = tempContainer.split(',').length;
                let singleURLsArray = tempContainer.split(',');

                if(stringCount > 0){
                    for(let x = 0; x < stringCount; x++){
                        if(singleURLsArray[x].length > 0 ){
                            let checkListContainer = {employee: array[i].employee,
                                                origin: "checklist",
                                                reason: 'inaccessible',
                                                photoURLs: singleURLsArray[x],
                                                date: array[i].date,
                                                docId: array[i].docId,
                                                serviceType: array[i].serviceType,
                                                sandAmount: array[i].sandAmount,
                                                serviceReason: array[i].serviceReason,
                                                equipmentType: array[i].equipmentType,
                                                checkedItems: array[i].checkedItems,
                                                current: array[i].current,
                                                jobId: array[i].jobID,
                            };
                            containerArray.push(checkListContainer);
                        }
                    }
                }
            }

            //inaccessible defined url count == 1
            if(!_.isUndefined(array[i].photoURLs.inaccessible) && (array[i].photoURLs.inaccessible.length > 4) && (array[i].photoURLs.inaccessible.split(',').length === 1)){
                let tempContainer = (array[i].photoURLs.inaccessible);
                tempContainer = tempContainer.replaceAll('"', '');
                tempContainer = tempContainer.replaceAll('[', '');
                tempContainer = tempContainer.replaceAll(']', '');

                let checkListContainer = {employee: array[i].employee,
                    origin: "checklist",
                    reason: 'inaccessible',
                    photoURLs: tempContainer,
                    date: array[i].date,
                    docId: array[i].docId,
                    serviceType: array[i].serviceType,
                    sandAmount: array[i].sandAmount,
                    serviceReason: array[i].serviceReason,
                    equipmentType: array[i].equipmentType,
                    checkedItems: array[i].checkedItems,
                    current: array[i].current,
                    jobId: array[i].jobID,
                };
                containerArray.push(checkListContainer);
            }

            //damage defined url count > 1
            if(!_.isUndefined(array[i].photoURLs.damage) && (array[i].photoURLs.damage.length > 4) && (array[i].photoURLs.damage.split(',').length > 1)){
                let tempContainer = (array[i].photoURLs.damage);
                tempContainer = tempContainer.replaceAll('"', '');
                tempContainer = tempContainer.replaceAll('[', '');
                tempContainer = tempContainer.replaceAll(']', '');
                let stringCount = tempContainer.split(',').length;
                let singleURLsArray = tempContainer.split(',');

                if(stringCount > 0){
                
                    for(let x = 0; x < stringCount; x++){
                        if(singleURLsArray[x].length > 0 ){
                            let checkListContainer = {employee: array[i].employee,
                                                origin: "checklist",
                                                reason: 'damage',
                                                photoURLs: singleURLsArray[x],
                                                date: array[i].date,
                                                docId: array[i].docId,
                                                serviceType: array[i].serviceType,
                                                sandAmount: array[i].sandAmount,
                                                serviceReason: array[i].serviceReason,
                                                equipmentType: array[i].equipmentType,
                                                checkedItems: array[i].checkedItems,
                                                current: array[i].current,
                                                jobId: array[i].jobID,
                            };
                            containerArray.push(checkListContainer);
                        }
                    }
                }
            }

            //damage defined url count == 1
            if(!_.isUndefined(array[i].photoURLs.damage) && (array[i].photoURLs.damage.length > 4) && (array[i].photoURLs.damage.split(',').length === 1)){
                let tempContainer = (array[i].photoURLs.damage);
                tempContainer = tempContainer.replaceAll('"', '');
                tempContainer = tempContainer.replaceAll('[', '');
                tempContainer = tempContainer.replaceAll(']', '');

                let checkListContainer = {employee: array[i].employee,
                    origin: "checklist",
                    photoURLs: tempContainer,
                    reason: 'damage',
                    date: array[i].date,
                    docId: array[i].docId,
                    serviceType: array[i].serviceType,
                    sandAmount: array[i].sandAmount,
                    serviceReason: array[i].serviceReason,
                    equipmentType: array[i].equipmentType,
                    checkedItems: array[i].checkedItems,
                    current: array[i].current,
                    jobId: array[i].jobID,
                };
                containerArray.push(checkListContainer);
            }
        }
        return containerArray;
    };


   //takes array and breaks multiples URLS ojects into singles
   const deconstructArray = (array, collection) => {
        var containerArray = [];
        for(let i = 0; i < array.length; i++){

            if(array[i].photoURLs != null && !_.isUndefined(array[i].photoURLs) && array[i].photoURLs.length > 1){

                if(!_.isUndefined(array[i].date)){
                    for(let x = 0; x < array[i].photoURLs.length ; x++){
                        let jobContainer = {employee: array[i].employee,
                                            origin: collection,
                                            photoURLs: array[i].photoURLs[x] ? array[i].photoURLs[x] : "",
                                            date: array[i].date,
                                            docId: array[i].docId,
                                            customer: array[i].customer,
                                            address: array[i].address,
                                            timeIn: array[i].timeIn,
                                            timeOut: array[i].timeOut,
                                            jobType: array[i].jobType,
                                            notes: array[i].notes,
                                            inCoordinate: array[i].inCoordinate,
                                            outCoordinate: array[i].outCoordinate,
                                            //deliveries
                                            from: array[i].from,
                                            to: array[i].to,
                                            material: array[i].material,
                                            quantity: array[i].quantity,
                                            ticket: array[i].ticket,    
                                            customerDisplay: array[i].customerDisplay,

                        };
                        //push adds to the end of the array
                        containerArray.push(jobContainer);
                    }
                }

                //allowance for defects which uses submitTime instead of date
                if(!_.isUndefined(array[i].submitTime) && _.isUndefined(array[i].date)){
                    for(let x = 0; x < array[i].photoURLs.length ; x++){
                        let jobContainer = {employee: array[i].employee,
                                            origin: collection,
                                            photoURLs: array[i].photoURLs[x] ? array[i].photoURLs[x] : "",
                                            date: array[i].submitTime.toMillis(),
                                            docId: array[i].docId,
                                            trailerID: array[i].trailerID,
                                            defects: array[i].defects,
                                            additionalDetails: array[i].additionalDetails,
                                            unitNumber: array[i].unitNumber,
                                            customerDisplay: array[i].customerDisplay,
                        };
                        //push adds to the end of the array
                        containerArray.push(jobContainer);
                    }
                }
            }
        }
        return containerArray;
    }

    const getCustomerData = (data, collection) => {
        switch (collection) {
            case 'jobs':
                if(!_.isEmpty(data.customerID)){
                    let customer = customers.find(customer => customer.docId === data.customerID)
                    if(!_.isUndefined(customer)){ return `${customer?.name} - ${customer?.streetNumber} ${customer?.streetName}` }
                    else{ return `${data?.customer} ${!_.isEmpty(data?.address) ? ` - ${data?.address}` : ''}` }
                }
                else{
                    return `${data?.customer} ${!_.isEmpty(data?.address) ? ` - ${data?.address}` : ''}`
                }
            case 'deliveries':
                if(!_.isEmpty(data.customerId)){
                    let customer = customers.find(customer => customer.docId === data.customerId)
                    if(!_.isUndefined(customer)){ return `${customer?.name} - ${customer?.streetNumber} ${customer?.streetName}` }
                    else{ return `${data?.customer} ${!_.isEmpty(data?.customerAddress) ? ` - ${data?.customerAddress}` : ''}` }
                }
                else{
                    return `${data?.customer} ${!_.isEmpty(data?.customerAddress) ? ` - ${data?.customerAddress}` : ''}`
                }
            default:
                break;
        }
    }

    const handleGeneratePhotos = async () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
        sessionStorage.removeItem("defectData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");

        var globalArray = [];
        var allJobsContainer = [];

        try{
            setProgress(1)
            setLoadingText("Preparing Unit Photos")
            setError(null);

            if (endDate.isBefore(startDate)) {
                setError('Start Date later than End Date!')
                setProgress(0)
                setLoadingText("")
                return
            }
            if(endDate.diff(startDate, 'd') > 365){
                setError('Query cannot be more than a one year period.')
                setProgress(0)
                setLoadingText("")
                return
            }

            setLoading(true);
            //if searchState === customer
            //used to compare jobs.docId with checklists.jobID in checklists query to filter where customers do not match and assign customers to checklists where it does
            firestore.collection('jobs').where("date", ">=", startDate.startOf('day').utc().valueOf()).where("date", "<=", endDate.endOf('day').utc().valueOf()).get()
            .then(querySnapshot => {
              let response = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.data().docId,
                    'photoURLs': doc.data().photoURLs ? doc.data().photoURLs : "",
                    'origin': "jobs",
                    'employee': users.find(user => user.uuid === doc.data().uid)?.displayName,
                    'customerDisplay': getCustomerData(doc.data(), 'jobs'),
                    'date': doc.data().date,
                }
              })

              allJobsContainer = response;
            })


            await queryGenerator('units').then(querySnapshot => {
                var response = querySnapshot.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.data().docId,
                        'photoURLs': doc.data().photoURLs ? doc.data().photoURLs : "",
                        'origin': "unit",
                        'employee': users.find(user => user.uuid === doc.data().uid)?.displayName,
                        'customerDisplay': null,
                        'date': doc.data().date,
                        'trailerID': doc.data().trailerID,
                    }
                })

                    var containerArray = [];
                    containerArray = deconstructArray(response, "unit");
                    response = response.filter(unit => !isNull(unit.photoURLs) && !_.isUndefined(unit.photoURLs) && unit.photoURLs.length === 1);
                    globalArray.push(...containerArray, ...response);
            })

            setProgress(20)
            setLoadingText("Preparing Job Photos")
            await queryGenerator('jobs').then(querySnapshot => {
                var response = querySnapshot.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.data().docId,
                        'photoURLs': doc.data().photoURLs ? doc.data().photoURLs : "",
                        'origin': "job",
                        'employee': users.find(user => user.uuid === doc.data().uid)?.displayName,
                        'customerDisplay': getCustomerData(doc.data(), 'jobs'),
                        'date': doc.data().date,
                        'jobId': doc.data().jobID,
                    }
                })

                    var containerArray = [];
                    containerArray = deconstructArray(response, "job");
                    response = response.filter(job => !isNull(job.photoURLs) && !_.isUndefined(job.photoURLs) && job.photoURLs.length === 1);
                    globalArray.push(...containerArray, ...response);
            });

            setProgress(40)
            setLoadingText("Preparing Deliveries Photos")
            await queryGenerator('deliveries').then(querySnapshot => {
                var response = querySnapshot.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.data().docId,
                        'photoURLs': doc.data().photoURLs ? doc.data().photoURLs : "",
                        'origin': "delivery",
                        'employee': users.find(user => user.uuid === doc.data().uid)?.displayName,
                        'customerDisplay': getCustomerData(doc.data(), 'deliveries'),
                        'date': doc.data().date,
                    }
                })

                    var containerArray = [];
                    containerArray = deconstructArray(response, "delivery");
                    response = response.filter(deliveries => !isNull(deliveries.photoURLs) && !_.isUndefined(deliveries.photoURLs) && deliveries.photoURLs.length === 1);
                    globalArray.push(...containerArray, ...response);
            })

            setProgress(60)
            setLoadingText("Preparing Defect Photos")
            await queryGenerator('defectReports').then(querySnapshot => {
                var response = querySnapshot.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                        'photoURLs': doc.data().photoURLs ? doc.data().photoURLs : "",
                        'origin': "defect",
                        'employee': users.find(user => user.uuid === doc.data().uid)?.displayName,
                        'customerDisplay': null,
                        'date': doc.data().submitTime.toMillis(),
                    }
                })

                    var containerArray = [];
                    containerArray = deconstructArray(response, "defect");
                    response = response.filter(defects => !isNull(defects.photoURLs) && !_.isUndefined(defects.photoURLs) && defects.photoURLs.length === 1);
                    globalArray.push(...containerArray, ...response);
            })

            setProgress(80)
            setLoadingText("Preparing Checklists Photos")
            await queryGenerator('checklists').then(querySnapshot => {
                var response = querySnapshot.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.data().docId,
                        'photoURLs': doc.data().photoURLs ? doc.data().photoURLs : "",
                        'origin': "checklist",
                        'employee': users.find(user => user.uuid === doc.data().uid)?.displayName,  
                        'customerDisplay': null,
                        'date': doc.data().date,
                        'jobId': doc.data().jobID,
                    }
                })

                    var containerArray = [];
                    containerArray = deconstructChecklist(response);

                    if(searchState.type === 'customer' && !_.isEmpty(customerAutoCompleteValue)){
                        // was assigning checklists a customer, not really neccessary
                        //all jobs to account for if job does not contain any photos (and would haave been filtered out previously)
                        // for(let x = 0; x < containerArray.length; x++){
                        //     for(let i = 0; i < allJobsContainer.length; i++){
                        //         if (containerArray[x].jobId === allJobsContainer[i].docId){
                        //             containerArray[x]={
                        //                 ...containerArray[x],
                        //                 customer: allJobsContainer[i].customer
                        //             }
                        //             break;
                        //         }
                        //     }
                        // }

                        //filter for customer (includes ?) can use customerDisplay here
                        //containerArray = containerArray.filter(data => data.customerDisplay.includes(customerAutoCompleteValue.customer) )
                    }
                    globalArray.push(...containerArray)
            })

                setPhotoData(globalArray);
                sessionStorage.setItem("photoData", JSON.stringify(globalArray));

                setProgress(90)
                setLoadingText("Applying Filters.")

                await filterPhotoData(globalArray);

                setProgress(99)
                setLoading(false);
                setLoadingText("Complete.")
                setTimeout(() => {setProgress(100)}, 3000);
        }
        catch(e){
            //remove all pieces of data grabbed up to this point
            setPhotoData([]);
            filterPhotoData([]);
            sessionStorage.removeItem("photoData");
            sessionStorage.removeItem("filteredPhotoData");
            
            setLoading(false);
            setProgress(0)
            switch(e.name){
                case 'QuotaExceededError':
                    setError("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                  default:
                    setError("Unable to process.");
                    console.log(e.message);
            }
        }
    }

    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };

    return(
        <React.Fragment>

            <PhotoDownloadModal 
                openDownloadModal={openDownloadModal}
                downloadData={downloadData}
                filteredDownloadData={filteredDownloadData}
                handleDownloadCheckBox={handleDownloadCheckBox}
                handleDownload={handleDownload}
                handleCloseDownloadModal={handleCloseDownloadModal}
            />

            <div className={classes.content} style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {progress === 0 || progress === 100 || progress === 99 ?
                    <PhotoTable 
                        tableRef={tableRef}
                        filteredPhotoData={filteredPhotoData}
                        loading={loading}
                        photosClickHandler={photosClickHandler}
                        handleDownloadSingle={handleDownloadSingle}
                    />
                : 
                    <Skeleton variant='rect' width={'80vw'} height={'165vh'}/>
                }

                {smallScreen 
                ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto">
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <PhotoOptions
                                loading={loading}
                                error={error}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                handleGeneratePhotos={handleGeneratePhotos}
                                progress={progress} loadingText={loadingText}
                                handleChecklistChange={handleChecklistChange}
                                searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                                ignoreList={ignoreList}
                                customerSearchValue={customerAutoCompleteValue} customerSearchValueChanged={setCustomerAutoCompleteValue}
                                searchState={searchState} searchStateHandler={searchStateHandler}
                                handleOpenDownloadModal={handleOpenDownloadModal}
                                customers={customers}
                                customerLoading={customerLoading}
                                users={users}
                                userLoading={userLoading}
                                locationFilterData={locationFilterData}
                                setLocationFilterData={setLocationFilterData}
                            />

                        </Paper>
                    </Collapse>  
                </div>
                :
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto">
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <PhotoOptions
                                loading={loading}
                                error={error}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                handleGeneratePhotos={handleGeneratePhotos}
                                progress={progress} loadingText={loadingText}
                                handleChecklistChange={handleChecklistChange}
                                searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                                ignoreList={ignoreList}
                                customerSearchValue={customerAutoCompleteValue} customerSearchValueChanged={setCustomerAutoCompleteValue}
                                searchState={searchState} searchStateHandler={searchStateHandler}
                                handleOpenDownloadModal={handleOpenDownloadModal}
                                customers={customers}
                                customerLoading={customerLoading}
                                users={users}
                                userLoading={userLoading}
                                locationFilterData={locationFilterData}
                                setLocationFilterData={setLocationFilterData}
                            />

                        </Paper>
                    </Collapse>  
                </div>
                }

            </div>
        </React.Fragment>
    )

}

export default withRouter(PhotosReport)