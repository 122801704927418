import React, {useState, useEffect} from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import DeliveryCard from '../../../components/Reports/Deliveries/DeliveryCard'
import { Skeleton } from '@material-ui/lab';
import { makeStyles, Snackbar, Button } from '@material-ui/core';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

const Delivery = (props) => {
    const [deliveryData, setDeliveryData] = useState({});
    const [saveState, setSaveState] = useState({});
    const [userData, setUserData] = useState({});
    const [saving, setSaving] = useState(false);
    const [unitData, setUnitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);
    const path = new URLSearchParams(props.location.search).get("docId");
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
    
        const fetchDeliveryData = async () => {
            try {
    
                // Fetch the delivery document
                const deliveryDoc = await firestore.collection("deliveries").doc(path).get();
    
                if (!deliveryDoc.exists) {
                    setErrorMessage("No document found");
                    setLoading(false);
                    return;
                }
    
                const deliveryResponse = {
                    ...deliveryDoc.data(),
                    docId: deliveryDoc.id,
                };
    
                setDeliveryData(deliveryResponse);
                setSaveState(deliveryResponse);
    
                // Fetch user data
                const userSnapshot = await firestore
                    .collection("users")
                    .where("uuid", "==", deliveryResponse.uid)
                    .get();
                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        docId: doc.id,
                    }))[0];
                    setUserData(userData);
                } else {
                    setUserData(null);
                }
    
                // Fetch unit data
                if(deliveryResponse.timesheetID !== '') {
                    const unitSnapshot = await firestore
                        .collection("units")
                        .where("timesheetID", "==", deliveryResponse.timesheetID)
                        .get();
                    const unitData = unitSnapshot.docs.map((doc) => ({
                        ...doc.data(),
                        docId: doc.id,
                    }));
                    setUnitData(unitData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setErrorMessage("Unable to fetch some data");
            } finally {
                setLoading(false);
            }
        };
    
        fetchDeliveryData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps    
    
    const saveDelivery = async () => {
        setSaving(true);
        try {
            await firestore.collection("deliveries").doc(path).update(deliveryData);
            setSaveState(deliveryData);
            setSaving(false)
        } catch (error) {
            console.error("Error saving delivery:", error);
            setErrorMessage("Unable to save delivery");
            setSaving(false)
        }
    }

    const cancelSave = () => {
        setDeliveryData(saveState);
    }

    const handleDeliveryChange = (event) => {
        setDeliveryData({
            ...deliveryData,
            [event.target.name]: event.target.value,
        });
    }

    return (
        loading 
        ? 
        <div className={classes.content}>
            <Skeleton variant='rect' width={'41vw'} height={'95vh'}/>
        </div>
        : 
        <React.Fragment>
        <DeliveryCard 
        saveDelivery={saveDelivery} deliveryData={deliveryData} handleDeliveryChange={handleDeliveryChange}
        setDeliveryData={setDeliveryData}
        userData={userData} unitData={unitData} errorMSG={errorMessage} cancelSave={cancelSave}/>
        <Snackbar
            open={!_.isEqual(deliveryData, saveState)}
            message={saving ? 'Saving...' : 'Save Delivery?'}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            action={
                <React.Fragment>
                    {saving
                        ? null
                        : <React.Fragment>
                            <Button variant='text' color='primary' onClick={()=>cancelSave()} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                            <Button variant='contained' onClick={() => { saveDelivery(); }}>Confirm</Button>
                        </React.Fragment>}
                </React.Fragment>
            } />
        </React.Fragment>

    )
}

export default withRouter(Delivery)