import {
    Button, Grid, makeStyles, Paper, TextField, Typography,  Divider, Snackbar} from "@material-ui/core"
import React, { useEffect } from "react";
import _ from 'lodash';
import FileUploader from "../../UI/FileUploader/FileUploader";
import Img from "react-cool-img";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
    },
    paper: {
        width: '100%',
        height: '100%',
        maxWidth:'1200px',
        padding: theme.spacing(2),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    modal: {
        width: '250px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        backgroundColor: 'rgb(49, 49, 49)',
        border: '1px solid black'
    },
    quillEditor: {
        '& .ql-editor p': {
            marginBottom: theme.spacing(2), // Match this to your desired paragraph spacing
        },
        '& .ql-editor p:last-child': {
            marginBottom: 0,
        },
        '& .ql-editor h1, & .ql-editor h2, & .ql-editor h3, & .ql-editor h4, & .ql-editor h5, & .ql-editor h6': {
            marginBottom: theme.spacing(2), // Same spacing after headings
        }
    },
    
}));

const ServiceSettingsCard = (props) => {
    const styles = useStyles();

    const [formState, setFormState] = React.useState({
        title: '',
        short: '',
        html: '',
        photoURL: '',
        id: -1,
        row: 0
    });

    useEffect(() => {
        if (props.selectedService) {
            setFormState(props.selectedService);
        }
    }, [ props.serviceSettings, props.selectedService]);



    return (
        <Grid container spacing={2} className={styles.root}>
          <Grid item xs={3}>
            <Paper className={styles.paper}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>Services</Typography>
                  <Divider></Divider>
                </Grid>
                    <Grid item xs={12}>
                        <Button
                        onClick={() => {
                            props.createService()
                            //set formstate to the last service in serviceSettings
                            props.services.length > 0 && setFormState(props.services[props.services.length - 1])

                        }}
                        style={{ width: '100%', marginBottom:'25px' }}
                        disabled= {props.saving
                            || formState.id === props.services.length
                        }
                        variant={
                            formState.id === props.services.length ? 'outlined' :
                            'contained'}
                        color={
                            formState.id === props.services.length ? 'secondary' :
                            'primary'}
                        >
                        New Service
                        </Button>
                    </Grid>
                {props.services.sort(
                    (a, b) => a.id - b.id
                ).map((service, index) => (
                    <Grid item xs={12} key={index}>
                        <Button
                        onClick={() => setFormState(service)}
                        style={{ width: '100%' }}
                        disabled= {props.saving || formState.id === service.id}
                        variant={ formState.id === service.id ? 'outlined' :
                            'contained'}
                        color={ formState.id === service.id ? 'secondary' :
                            'primary'}
                        >
                        {service.title}
                        </Button>
                    </Grid>
                ))}

              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className={styles.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container display='flex'>
                    <Grid item xs={6}>
                      <Typography variant={'h6'}>Content</Typography>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                      <Button
                        color='secondary'
                        variant='contained'
                        size='small'
                        onClick={() => {
                          props.setSelectedService(formState);
                          props.setDeleteOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={12} className={styles.row}>
                  <Typography>Title:</Typography>
                </Grid>
                <Grid item xs={12} className={styles.row}>
                  <TextField
                    style={{ width: '100%' }}
                    variant='outlined'
                    size='small'
                    value={formState.title}
                    onChange={(event) => setFormState({
                      ...formState,
                      title: event.target.value
                    })}
                  />
                </Grid>
                <Grid item xs={12} className={styles.row}>
                  <Typography>Short Description:</Typography>
                </Grid>
                <Grid item xs={12} className={styles.row}>
                  <TextField
                    style={{ width: '100%' }}
                    variant='outlined'
                    size='small'
                    multiline
                    minRows={4}
                    value={formState.short}
                    onChange={(event) => setFormState({
                      ...formState,
                      short: event.target.value
                    })}
                  />
                </Grid>

                <Grid item xs={12} className={styles.row}>
                  <Typography>HTML:</Typography>
                </Grid>
                <Grid item xs={12} className={styles.row}>
                <ReactQuill 
                    className={styles.quillEditor}
                    style={{ width: '100%', height: '300px', marginBottom:'50px'}}
                    theme="snow" 
                    defaultValue={formState.html} 
                    value={formState.html}
                    formats={[
                        'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet', 'indent', 'link', 'image'
                    ]}
                    modules={{
                        toolbar: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                            ['link', 'image'],
                            ['clean']
                        ]
                    }}
                    preserveWhitespace={false}
                    onChange={(html, delta, source) => {
                        if (source === 'user') {
                            setFormState({
                                ...formState,
                                html: html
                            })
                        }
                    }}
                />
                </Grid>

                <Grid container item xs={12} style={{ marginBottom: '15px' }}>
                  {formState.photoURL && (
                    <Img 
                      src={formState.photoURL} 
                      alt="Service Image" 
                      style={{ objectFit: 'cover',
                        width: '100%',
                        maxHeight: '350px',}} 
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={styles.row}>
                  <Typography>Replace/Upload Image:</Typography>
                </Grid>
                <Grid container item xs={12} style={{ marginBottom: '15px' }}>
                  <FileUploader 
                    acceptImage 
                    filePath={'Services/'} 
                    onUploadSuccess={(url) => setFormState({
                      ...formState,
                      photoURL: url
                    })}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Snackbar
                open={ (!_.isEqual(
                    _.omit(formState, ['row']),
                    _.omit(props.saveState[formState.id], ['row'])
                ) && !_.isEmpty(formState.title) ) }
                message={props.saving ? 'Saving...' : 'Save Services?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        <Button
                            color='primary'
                            size='small'
                            variant="contained"
                            onClick={() => props.handleSave(formState)}
                        >
                            Save
                        </Button>
                        <Button
                            color='secondary'
                            variant="contained"
                            size='small'
                            onClick={() => props.cancelSave()}
                        >
                            Cancel
                        </Button>
                    </React.Fragment>
                }
                />
        </Grid>
    )
}


export default ServiceSettingsCard;