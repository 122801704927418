import React, { useEffect, useState, useCallback } from 'react';
import EquipmentForm from '../../components/Equipment/EquipmentForm'
import { firestore } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';
import { useCustomer } from '../../../providers/CustomerContext';

const EquipmentNew = (props) =>  {

    const { customers, loading, getCustomers } = useCustomer();
    const throwAsyncError = useAsyncError();

    const initialequipmentData = {
        unitNumber: '',
        year: '',
        class: null,
        model: '',
        make: '',
        licensePlate: '',
        features: '',
        serialNum: '',
        tare: '',
        isOwned: true,
        isActive: true,
        owner: null,
        marketValue: '',
        purchasePrice: '',
        purchasedFrom: '',
        purchaseDate: moment().valueOf(),
        leasedFinancedFrom: '',
        description: '',
        registrations: [],
    }

    const [duplicateSerial, setDuplicateSerial] = useState(false)
    const [allEquipmentData, setAllEquipmentData] = useState([])

    const [equipmentData, setEquipmentData] = useState(initialequipmentData);
    //0 nothing, 1 creating, 2 success, -1 error
    const [submitting, setSubmitting] = useState(0);
    const [error, setError] = useState("");
    const [editedDescription, setEditedDescription] = useState(false)

    const equipmentClasses = [
        'Equipment - Dozer', 'Equipment - Drum Roller', 'Equipment - Excavator', 'Equipment - Grader', 'Equipment - Loader', 'Equipment - Skid Steer',
        'Equipment - Other', 'Truck - Tandem', 'Truck - Tractor', 'Trailer - Belly Dump', 'Trailer - Deck', 'Trailer - End Dump', 'Trailer - Grain Bulker',
        'Trailer - Side Dump', 'Truck - Pick up or Similar', 'Trailer - Cargo/Utility', 'Trailer - Water',
    ];

    //gets initial data
    useEffect(() => {
        firestore.collection('unitDescriptions').get()
        .then(querySnapshot => {
            setAllEquipmentData(querySnapshot.docs.map((doc) => {
                return {
                ...doc.data(),
                'docId': doc.id,
                }
            }))

            getCustomers()
        })
        .catch(e => {
            console.log(e.message);
            throwAsyncError(new Error(e.message, e));
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateEquipmentData = useCallback((object, name) => {
        //if manually altering description, dont autopoplate afterwards
        if(name === 'description'){ setEditedDescription(true) }

        //alter description when certain fields are changed
        if(!editedDescription && (name === 'year' || name === 'make' || name === 'model' || name === 'licensePlate')){
            object['description'] = object.year + ' ' + object.make + ' ' + object.model + ' ' + object.licensePlate.replace(/\s/g, '')
        }

        //checks for serialNum duplicates
        if(name === 'serialNum' && object.serialNum !== ''){
            setDuplicateSerial( handleDuplicateSerialCheck(object.serialNum, allEquipmentData) )
        }

        setEquipmentData(object)
    }, [allEquipmentData, editedDescription], );

    const handleSubmit = (event) => {
        event.preventDefault();
        createEquipmentForm();
    }

    const createEquipmentForm = async () => {
        setSubmitting(1);

        let newEquipmentForm = equipmentData;
        newEquipmentForm.dateCreated = Date.now()/1000;
        newEquipmentForm.archived = false;

        firestore.collection('unitDescriptions').add(newEquipmentForm)
            .then((data) => {
                setSubmitting(2)
                setTimeout(() => {
                    props.history.push({
                        pathname: '/dashboard/equipment/form',
                        search: "?" + new URLSearchParams({ docId: data.id }).toString(),
                    })
                }, 1000);
        })
        .catch((e) => {
            setSubmitting(0);
            console.log(e)
            setError("Unable to create new Equipment, Error: " + e);
        });
    }

    const handleDuplicateSerialCheck = (serialNumber, checkAgainstArray) => {
        for(const equipment of checkAgainstArray) { if(equipment.serialNum === serialNumber){ return true } }
        return false
    }

    return (
        <React.Fragment>
            <EquipmentForm
                equipmentData={equipmentData}
                updateEquipmentData={updateEquipmentData}
                handleSubmit={handleSubmit}
                submitting={submitting}
                error={error}
                equipmentClasses={equipmentClasses}
                customers={customers}
                loading={loading}

                duplicateSerial={duplicateSerial}
            />
        </React.Fragment>
    )
}

export default withRouter(EquipmentNew);