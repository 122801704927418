import React, { useState } from 'react';
//import { makeStyles } from '@material-ui/core';
import { Grid, Paper, Typography, Button, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core';
// import { firestore, removeFile } from '../../../firebase/firebase'
// import { storage } from '../../../firebase/firebase'
import Dropzone from 'react-dropzone'
import ClearIcon from '@material-ui/icons/Clear';
import PublishIcon from '@material-ui/icons/Publish';
// import moment from 'moment';
//import { Document, Page } from 'react-pdf';
import DeleteIcon from '@material-ui/icons/Delete';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import useAsyncError from '../UI/AsyncError/AsyncError';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom:'10px',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
    },
    label: {
        lineHeight: 2,
    },
    checkbox: {
        lineHeight: 2,
        padding:'0px',
        marginTop: '4px',
        marginBottom: '4px',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
         width: '100%'
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
         width: '100%'
    },
}));

const RegistrationTab = (props) => {
    const classes = useStyles();

    const [expandedDocument, setExpandedDocument] = useState([])

    // //substring text to use ... if too long
    // const substringText = (text) => {
    //     if(text.length > 20){
    //         return text.substring(0, 20) + '...'
    //     } else {
    //         return text
    //     }
    // }

    const addDropDown = (value) => {
        setExpandedDocument([...expandedDocument, value]) 
    }

    const removeDropDown = (value) => {
        let container = _.cloneDeep(expandedDocument)
        container.splice(expandedDocument.findIndex(x=> x === value), 1)
        setExpandedDocument(container)
    }

    return(
        <Grid container style={{justifyContent: 'center'}}>
            {/* DELETE DIALOG */}
            <Dialog
                open={props.deleteData !== null}
                onClose={() => { props.setDeleteData(null) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Document?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this document?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.setDeleteData(null)}} variant="contained" color="secondary">
                        No
                    </Button>
                    <Button onClick={() => { props.handleDeleteClick(props.deleteData) }} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper className={classes.paper}>
                <Grid container spacing={2} >
                    <Grid item sm={12} className={classes.fileInput}>
                        <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}} onDrop={props.handleAddToLocal}>
                            {({ getRootProps, getInputProps }) => (
                                <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                    <input {...getInputProps()} style={{ display: 'none' }} />
                                    <PublishIcon style={{color:'grey', marginRight:'20px'}}/>
                                    <Typography variant='h6'> Upload Document (.pdf) </Typography>
                                </Grid>
                            )}
                        </Dropzone>
                    </Grid>

                    <Grid item sm={12}>
                        {/* set up this way to allow for index to be correct on removal */}
                        {props.equipmentData.registrations?.map((attachment, index) => (
                            typeof attachment.pdfURL === 'object' ?
                            <Grid item key={index} xs={12} style={{display:'flex', alignItems:'center'}}>
                                <Typography variant='body2' style={{marginRight:'10px'}}>{attachment.name}</Typography>
                                <IconButton 
                                    onClick={() => {
                                        props.handleRemoveFromLocal(index);
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                            : null
                        ))}
                    </Grid>

                    <Grid item sm={12}>
                        <Typography type='url' align="center" style={{color:'red'}}>{props.error}</Typography>
                    </Grid>

                    {props.equipmentData.registrations?.filter(x=> typeof x.pdfURL === 'string' ).map((reg, i) => {
                        return (
                            <Grid container justifyContent='center' key={i} style={{marginBottom: '25px', }}>
                                <Paper style={{backgroundColor:'lightGrey', paddingRight: '40px', paddingLeft: '40px', width: '100%'}}>
                                    
                                    <Grid container >
                                        <Grid item xs={10} >
                                            <Typography style={{marginTop: '10px'}}>{reg.name}</Typography>
                                        </Grid>

                                        <Grid item xs={1}>
                                            <Grid container justifyContent='flex-end'>
                                                <IconButton onClick={(e)=> { !expandedDocument.includes(i) ? addDropDown(i) : removeDropDown(i) }} >
                                                    {!expandedDocument.includes(i) ? <ArrowDropDownIcon /> : <ArrowDropUpIcon /> }
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={1}>
                                            <Grid container justifyContent='flex-end'>
                                                <IconButton>
                                                    <DeleteIcon
                                                        onClick={(e)=> { props.setDeleteData(reg.pdfURL) }}
                                                    />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                              
                                    {expandedDocument.includes(i) &&
                                        <Grid item xs={12}>
                                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'50px'}}>
                                                <iframe
                                                    src={reg.pdfURL}
                                                    title="PDF Viewer"
                                                    width="800px"
                                                    height="600px"
                                                />
                                            </Grid>
                                        </Grid>
                                    }    
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
        </Grid>
    )
}
export default withRouter(RegistrationTab)