import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core';
import { Checkbox, CircularProgress, FormControlLabel, Grid, Paper, TextField, Typography, InputAdornment,
 Select, FormControl, } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },
}));

const InformationTab = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [typedValue, setTypedValue] = useState('')

    return (
        <Grid container spacing={2}>
            <Grid container>
                <Grid container item xs={12} >
                    {!props.edit ?
                        <Typography variant="h4" style={{marginLeft:'10px'}}>{props.equipmentData.description}</Typography>:
                        <TextField
                            label="Description"
                            variant="outlined"
                            value={props.equipmentData.description}
                            onChange={(e) => props.updateEquipmentData({...props.equipmentData, description: e.target.value}, 'description')}
                            style={{width:'100%'}}
                            inputProps={{ style: { fontSize: '2rem' } }}
                        />
                    }
                </Grid>

                <Grid container item xs={6} >
                    <Paper className={classes.paper} >
                        <Grid container spacing={2}>
                            <Typography variant="h5">Unit Info</Typography>
                        </Grid>
                        <hr></hr>
                        <Grid container spacing={2}>
                            <Grid container>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Unit Number:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.unitNumber ? props.equipmentData.unitNumber : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.unitNumber}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'unitNumber': event.target.value }, 'unitNumber') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Year:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.year ? props.equipmentData.year : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.year}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'year': event.target.value }, 'year') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Model:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.model ? props.equipmentData.model : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.model}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'model': event.target.value }, 'model') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Make:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.make ? props.equipmentData.make : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.make}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'make': event.target.value }, 'make') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Equipment Type:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.class && props.equipmentData.class?.charAt(0).toUpperCase() + props.equipmentData.class?.slice(1)}</Typography>
                                    :
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            native
                                            value={props.equipmentData.class}
                                            onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'class':  event.target.value }, 'class') }}
                                        >
                                            {props.equipmentClasses.sort().map((equipment, index) => {
                                                return (
                                                    <option key={index} value={equipment}>{equipment}</option>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>}
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Equipment Features:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.features ? props.equipmentData.features : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.features}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'features': event.target.value }, 'features') }}
                                    />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid container item xs={6} >
                    <Paper className={classes.paper} >
                        <Grid container spacing={2}>
                            <Typography variant="h5">Unit Info</Typography>
                        </Grid>
                        <hr></hr>
                        <Grid container spacing={2}>
                            <Grid container>
                            <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>License Plate:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.licensePlate ? props.equipmentData.licensePlate : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.licensePlate}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'licensePlate': event.target.value }, 'licensePlate') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Serial #:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.serialNum ? props.equipmentData.serialNum : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.serialNum}
                                        size="small"
                                        error={props.duplicateSerial}
                                        helperText={props.duplicateSerial && "Dupliate Serial Number."}
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'serialNum': event.target.value }, 'serialNum') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Tare:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.tare ? props.equipmentData.tare : '0'} kgs</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.tare}
                                        size="small"
                                        InputProps={{ endAdornment: <InputAdornment position="end">kgs</InputAdornment>, }}
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'tare': event.target.value }, 'tare') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} > <br></br></Grid>
                                <Grid item xs={6} > <br></br></Grid>


                                <Grid item xs={12} >
                                    <FormControlLabel
                                        control={<Checkbox disabled={!props.edit} checked={props.equipmentData.isActive} name="active"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'isActive': event.target.checked  }, 'isActive') }}/>}
                                        label="Equipment is Active"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <FormControlLabel
                                        control={<Checkbox disabled={!props.edit} checked={props.equipmentData.isPremiumTrailer} name="isPremiumTrailer"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'isPremiumTrailer': event.target.checked  }, 'isPremiumTrailer') }}/>}
                                        label="Premium Trailer"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid container item xs={12} >
                    <Paper className={classes.paper} >
                        <Grid container spacing={2}>
                            <Grid container>
                                <Grid item xs={6} >
                                    <FormControlLabel
                                        control={<Checkbox disabled={!props.edit} checked={props.equipmentData.isOwned} name="owned"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'isOwned': event.target.checked  }, 'isOwned') }}/>}
                                        label="Equipment is Owned"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {props.equipmentData?.isOwned ? null :
                                        <Autocomplete
                                            open={open}
                                            onOpen={() => {
                                                setOpen(true);
                                            }}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            loading={props.loading}
                                            label="Search"
                                            id="autocomplete"
                                            disabled={!props.edit}
                                            autoComplete
                                            autoHighlight
                                            style={{ maxWidth: '300px', width: '100%' }}
                                            inputValue={typedValue}
                                            onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                            value={props.equipmentData?.owner}
                                            onChange={(event, newValue) => { props.updateEquipmentData({ ...props.equipmentData, 'owner': newValue }, 'owner') }}
                                            options={props.customers.map((customer) => customer.name ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                            getOptionSelected={(option, value) => option?.name === value?.name}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ maxWidth: '300px', width: '100%' }}
                                                    label="Owner"
                                                    //variant="outlined"
                                                    size='small'

                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Market Value:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.equipmentData.marketValue ? props.equipmentData.marketValue : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.marketValue}
                                        size="small"
                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'marketValue': event.target.value }, 'marketValue') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Purchase Price:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.equipmentData.purchasePrice? props.equipmentData.purchasePrice : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.purchasePrice}
                                        size="small"
                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'purchasePrice': event.target.value }, 'purchasePrice') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Purchased From:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.purchasedFrom ? props.equipmentData.purchasedFrom : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.purchasedFrom}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'purchasedFrom': event.target.value }, 'purchasedFrom') }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Purchase Date:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.purchaseDate !== "1990/01/01" ? moment(props.equipmentData.purchaseDate).format('MMM D YYYY') : ''}</Typography>
                                    :
                                    <KeyboardDatePicker
                                        style={{maxWidth:'150px'}}
                                        autoOk
                                        format="yyyy/MM/DD"
                                        value={moment(props.equipmentData.purchaseDate).format('YYYY-MM-DD')}
                                        error={props.equipmentData.purchaseDate === null}
                                        helperText={props.equipmentData.purchaseDate === null ? "Improper format, try again." : null}
                                        onChange={(value) => { 
                                            if(_.isNaN(moment(value).valueOf())){
                                                props.updateEquipmentData({ ...props.equipmentData, 'purchaseDate': null }, 'purchaseDate') 
                                            }
                                            else{
                                                props.updateEquipmentData({ ...props.equipmentData, 'purchaseDate': moment(value).valueOf() }, 'purchaseDate') 
                                            }
                                        }}
                                    />
                                    }
                                </Grid>

                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Leased/Financed From:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.leasedFinancedFrom ? props.equipmentData.leasedFinancedFrom : ''}</Typography>
                                    :
                                    <TextField
                                        style={{maxWidth:'150px'}}
                                        value={props.equipmentData.leasedFinancedFrom}
                                        size="small"
                                        onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'leasedFinancedFrom': event.target.value }, 'leasedFinancedFrom') }}
                                    />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default withRouter(InformationTab)