import {
    Button, Grid, makeStyles, Paper, Snackbar, TextField, Typography, Divider, Modal, Input, FormControlLabel,
    InputAdornment
} from "@material-ui/core"
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import _ from 'lodash';
import { Circle, GoogleMap, Marker, DrawingManager, useLoadScript, Polygon } from '@react-google-maps/api';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAuth } from '../../../../providers/AuthContext';

// Memoized Zone Item Component
const MemoizedZoneItem = React.memo(({ 
    zone, 
    index, 
    selectedZone, 
    updateZone, 
    setSelectedZone, 
    setOpen,
    zoneRef
}) => {
    return (
        <Grid item xs={4} key={index} ref={zoneRef}>
            <Paper style={selectedZone === index ? {
                border: '3px solid lightGreen', 
                padding: '6px',
                marginBottom: '2px',
            } : {
                border: '1px solid black',
                padding: '6px',
                marginBottom: '2px',
            }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Zone #{index+1}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                style={{ 
                                    float: 'right', 
                                    paddingRight:'0px', 
                                    paddingLeft:'10px', 
                                    backgroundColor: 'red', 
                                    color: 'white' 
                                }}
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                    setOpen(true);
                                    setSelectedZone(index);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Divider style={{marginBottom:'4px', marginTop:'4px'}}/>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                label="Zone Name/Label"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={zone.name || ''}
                                onChange={(e) => {
                                    updateZone(index, 'name', e.target.value);
                                }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        </Grid>
                    
                        <Grid item xs={2}>
                            <Typography>Color: </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <input 
                                type="color" 
                                id={`color-${index}`} 
                                name="color"
                                onChange={(e) => {
                                    updateZone(index, 'color', e.target.value);
                                }}
                                value={zone.color || '#000000'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>Tandem Rate</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Stonewall"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={zone.tandemStonewall || ''}
                                onChange={(e) => {
                                    updateZone(index, 'tandemStonewall', e.target.value);
                                }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Pineridge"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={zone.tandemPineridge || ''}
                                onChange={(e) => {
                                    updateZone(index, 'tandemPineridge', e.target.value);
                                }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>Trailer Rate</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Stonewall"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={zone.trailerStonewall || ''}
                                onChange={(e) => {
                                    updateZone(index, 'trailerStonewall', e.target.value);
                                }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Pineridge"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={zone.trailerPineridge || ''}
                                onChange={(e) => {
                                    updateZone(index, 'trailerPineridge', e.target.value);
                                }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}, (prevProps, nextProps) => {
    // Custom comparison function for React.memo
    // Only re-render if these specific props change
    return (
        prevProps.selectedZone === nextProps.selectedZone &&
        _.isEqual(prevProps.zone, nextProps.zone) &&
        prevProps.index === nextProps.index
    );
});

const libraries = ['drawing'];
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        padding:'10px',
    },
    paper: {
        width: '100%',
        maxWidth: '400px',
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    modal: {
        width: '250px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        backgroundColor: 'rgb(49, 49, 49)',
        border: '1px solid black'
    },
}));

const ZoneSettingsCard = (props) => {
    const styles = useStyles();
    const [zones, setZones] = useState(props.shopSettings.zones || []);
    const [drawing, setDrawing] = useState(false);
    const [open, setOpen] = useState(false);
    const [opacity, setOpacity] = useState(0.55);
    const { currentUser } = useAuth();
    const [selectedZone, setSelectedZone] = useState(null);
    const [mapCenter] = useState({ lat: 49.901956, lng: -96.932431 });
    const [mapZoom] = useState(9);
    const mapRef = useRef();
    const zoneRefs = useRef([]);

    // Debounced save state function to improve performance
    const debouncedSaveState = useRef(
        _.debounce((updatedZones) => {
            props.setSaveState(prevState => ({ 
                ...prevState, 
                zones: updatedZones 
            }));
        }, 300) // 300ms debounce
    ).current;

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });    

    // Initialize refs for each zone
    useEffect(() => {
        zoneRefs.current = Array(zones.length).fill().map((_, i) => zoneRefs.current[i] || React.createRef());
    }, [zones]);

    // Set zones from save state
    useEffect(() => {
        if(_.isUndefined(props.saveState.zones)){
            setZones([]);
        } else{
            setZones([...props.saveState.zones]);
        }
    }, [props.saveState, props]);

    // Scroll to selected zone
    useEffect(() => {
        if (selectedZone !== null && zoneRefs.current[selectedZone]) {
            // Scroll the selected zone into view with smooth behavior
            zoneRefs.current[selectedZone].current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [selectedZone]);

    // Clean up debounce on unmount
    useEffect(() => {
        return () => {
            debouncedSaveState.cancel();
        };
    }, [debouncedSaveState]);



    // Optimized update zone function
    const updateZone = useCallback((zoneIndex, key, value) => {
        setZones(prevZones => {
            const updatedZones = [...prevZones];
            updatedZones[zoneIndex] = { 
                ...updatedZones[zoneIndex], 
                [key]: value 
            };
            
            // Schedule the debounced save
            debouncedSaveState([...updatedZones]);
            
            return updatedZones;
        });
    }, [debouncedSaveState]);

    // Optimized export zones function
    const exportZones = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(zones)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "zones.json";
        document.body.appendChild(element);
        element.click();
    }

    // Import zone data from json file
    const importZones = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function (e) {
            let importedZones = JSON.parse(e.target.result);
            setZones(importedZones);
            debouncedSaveState(importedZones);
        }
    }

    const onPolygonComplete = useCallback((polygon) => {
        const coordinates = polygon.getPath().getArray().map((latLng) => {
            return {
                lat: latLng.lat(),
                lng: latLng.lng()
            }
        });
    
        let zoneContainer = {
            coordinates: coordinates,
            name: 'Zone ' + (Object.keys(zones).length + 1),
            deliveryFee: 0.00,
            color: '#000000'
        };
    
        setDrawing(false);
        
        const updatedZones = [...zones, zoneContainer];
        setZones(updatedZones);
        debouncedSaveState(updatedZones);
    
        polygon.setMap(null);
    }, [zones, setDrawing, setZones, debouncedSaveState]);

    const handleDeleteZone = () => {
        setZones(prevZones => {
            const updatedZones = [...prevZones];
            updatedZones.splice(selectedZone, 1);
            
            // Schedule update to parent
            debouncedSaveState([...updatedZones]);
            
            return updatedZones;
        });
        
        setOpen(false);
        setSelectedZone(null);
    };

    const zonePolygons = useMemo(() => {
        return zones.map((zone, index) => (
            <Polygon
                key={`polygon-${index}`}
                path={zone.coordinates}
                onClick={(e) => { 
                    if (e.domEvent) {
                        e.domEvent.stopPropagation();
                    } 
                    setSelectedZone(selectedZone === index ? null : index);
                }}
                options={{
                    fillColor: zone.color,
                    fillOpacity: opacity,
                    strokeColor: selectedZone === index ? 'lightGreen' : zone.color,
                    strokeOpacity: 0.8,
                    strokeWeight: selectedZone === index ? 4 : 2,
                    clickable: true,
                    editable: false,
                    zIndex: 6
                }}
            />
        ));
    }, [zones, selectedZone, opacity]);


    const googleMapSubComponents = useMemo(() => {
        if (!isLoaded || !window.google) {
            return null;
        }
        if (drawing === false) {
            return (
                <DrawingManager 
                    onPolygonComplete={onPolygonComplete}
                    options={{
                        drawingControlOptions: {
                            position: window.window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: ['polygon'],
                            zIndex: 6
                        },
                        polygonOptions: {
                            strokeColor: '#000',
                            zIndex: 5
                        }
                    }}
                />
            );
        }
        return null;
    }, [drawing, isLoaded, onPolygonComplete]); 

    const zoneList = useMemo(() => {
        return zones.map((zone, index) => (
            <MemoizedZoneItem
                key={`zone-${index}`}
                zone={zone}
                index={index}
                selectedZone={selectedZone}
                updateZone={updateZone}
                setSelectedZone={setSelectedZone}
                setOpen={setOpen}
                zoneRef={zoneRefs.current[index]}
            />
        ));
    }, [zones, selectedZone, updateZone]);


    if (loadError) return "Error";
    if (!isLoaded) return "";

    return (
        <div className={styles.root}>
            <Paper className={styles.paper} style={{maxWidth:'1000px'}}>
                <Modal 
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Paper className={styles.paper} style={{margin:'500px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Delete this Zone?</Typography>
                                <Divider/>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Typography variant='body1'>This action cannot be undone.</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    style={{ float: 'right' }}
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDeleteZone}
                                >Yes</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    style={{ float: 'right' }}
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >No</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Modal>
                <Typography variant='h5' className={styles.title}>Zone Settings</Typography>
                
                <Grid container xs={12}>
                <Divider style={{marginBottom:'10px'}}/>
                    <Grid container item xs={12}>
                        <div style={{width:'1000px', height:'650px', marginBottom:'10px'}}>
                            <GoogleMap
                                ref={mapRef}
                                mapContainerStyle={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                options={{
                                    fullscreenControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    mapTypeId: 'hybrid'
                                }}
                                zoom={mapZoom}
                                center={mapCenter}
                                onLoad={(map) => {
                                    mapRef.current = map;
                                }}
                            >
                                {googleMapSubComponents}
                                {zonePolygons}
                                <Marker position={props.coordinates} />
                                <Circle
                                    center={{ lat: -49.904415, lng: -96.932431 - 180 }}
                                    radius={20037508.34 - 100000 * .70}
                                    options={{
                                        clickable: false,
                                        fillOpacity: .4,
                                        fillColor: 'black',
                                    }}
                                />
                            </GoogleMap>
                        </div>
                    </Grid>
                    <Divider style={{margin:'10px'}}/>
                    {currentUser.email === "derekmilleryoung@gmail.com" ?
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography variant='h6'>Zone List</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={exportZones} variant='contained' color='secondary'>Export Zones</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel control={<Input
                                id="replacePDFButton"
                                type='file'
                                accept='.json'
                                onChange={(e) => importZones(e)}
                                required/>} label="Import Zones File" />
                            
                        </Grid>
                    </Grid>:null}
                    <Grid item xs={1}>
                        <TextField
                            label="Transparency"
                            variant="standard"
                            size="small"
                            fullWidth
                            value={Math.round((opacity * 100 + Number.EPSILON) * 100) / 100}
                            onChange={(e) => {
                                let value = parseFloat(e.target.value);
                                if(value > 100) value = 100;
                                if(value < 0) value = 0;
                                setOpacity(value / 100);
                            }}
                            type='number'
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: { min: 0, max: 100 }
                            }}
                        ></TextField>
                    </Grid>
                </Grid>
                <Grid container xs={12} style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {zones.length > 0 ? zoneList : 
                            <Grid item xs={12}>
                                <Typography variant='body1'>No Zones Currently Set. Use the Toolbar on the Google Map to set up Zones.</Typography>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>

                <Snackbar
                    open={!_.isEqual(props.shopSettings, props.saveState)}
                    message={props.saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {props.saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' onClick={() => props.handleSave()}>Confirm</Button>
                                </React.Fragment>}
                        </React.Fragment>
                    }
                />
            </Paper>
        </div>
    );
}

export default ZoneSettingsCard;