import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Paper, useTheme, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import moment from 'moment';
import { useCustomer } from '../../../providers/CustomerContext';

import InvoiceReport from '../../components/Invoice/InvoiceReport';
import InvoiceOptions from '../../components/Invoice/InvoiceOptions';

const useStyles = makeStyles((theme) => ({
    card: {
      padding: theme.spacing(4),
      width: '100%',
      height: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    // content: {
    //     display: 'flex',
    //     paddingTop: '32px',
    //     paddingLeft: '32px',
    //     width: '100%',
    //     [theme.breakpoints.down('xs')]: {
    //         paddingLeft: '5px',
    //         paddingTop: '3px',
    //         width: '100%',
    //     }
    // },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    sidebarSpacer: {
        marginLeft: '32px',
        maxWidth: '256px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
            maxWidth: '0px',
            width: '0%',
        }
    },
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1200px',
    },
  }));

const InvoiceHome = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { customers, getCustomers, customerLoading} = useCustomer();

    const [invoiceData, setInvoiceData] = useState("invoiceData" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoiceData")) : []);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [searchBarValue, setSearchBarValue] = useState("invoiceSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoiceSearchBarValue")) : null)
    const [page, setPage] = useState("invoicePageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoicePageValue")) : 0)

    const [customerOpen, setCustomerOpen] = useState(false)
    const [options, setOptions] = useState("invoiceOptions" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoiceOptions")) : {
        startDate: moment().valueOf(),
        endDate: moment().valueOf(),
        customer: null,
    })

    //gets initial data
    useEffect(() => {
        getCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSearchChange = (value) => {
        setSearchBarValue(value);  
        sessionStorage.setItem("invoiceSearchBarValue", JSON.stringify(value));
        setPage(0);  
        sessionStorage.setItem("invoicePageValue", JSON.stringify(0));
    }

    const handleOnPageChange = (pageNum) => {
        setPage(pageNum);  
        sessionStorage.setItem("invoicePageValue", JSON.stringify(pageNum));
    }

    const handleChangeOrderOption = (field, value) => { 
        let newValue = {...options, [field]: value}
        setOptions(newValue);  
        sessionStorage.setItem("invoiceOptions", JSON.stringify(newValue));
    }

    const tileClickHandler = (path) => {
        props.history.push({
            pathname: 'invoicing/' + path,
        })
    };

    const generateHandler = () => {
        setLoading(true);
        firestore.collection('invoices')
        .where("createdOn", ">=", moment(options.startDate).startOf('day').valueOf())
        .where("createdOn", "<=", moment(options.endDate).endOf('day').valueOf())
        .get()
        .then(querySnapshot => {
            let invoiceResponse = querySnapshot.docs.map((doc) => {
                return {
                ...doc.data(),
                'docId': doc.id,
                }
            })

            if(options.customer !== null){ invoiceResponse = invoiceResponse.filter(x=> x.customers.includes(options.customer.docId)) }
            sessionStorage.setItem("invoiceData", JSON.stringify(invoiceResponse));
            setInvoiceData(invoiceResponse);
            setLoading(false);
        })
        .catch(e => {
            setError("Unable to Process");
            setLoading(false);
            console.log(e.message);
        })
    }

    return (
        <Grid className={classes.content}>
            <Grid container className={classes.content}>
                <InvoiceReport 
                    invoiceData={invoiceData}
                    loading={loading}
                    error={error}
                    searchBarValue={searchBarValue}
                    page={page}
                    
                    customers={customers}

                    handleOnSearchChange={handleOnSearchChange}
                    handleOnPageChange={handleOnPageChange}
                    tileClickHandler={tileClickHandler}
                />
            </Grid>

            <Grid className={classes.sidebarSpacer} >
                {!smallScreen ?
                    <Paper className={classes.sidebar}>
                        <InvoiceOptions 
                            options={options}
                            customers={customers}
                            customerLoading={customerLoading}
                            customerOpen={customerOpen}
                            setCustomerOpen={setCustomerOpen}
                            handleChangeOrderOption={handleChangeOrderOption}
                            generateHandler={generateHandler}
                        />
                    </Paper>
                : null}
            </Grid>
        </Grid>
    )
}

export default withRouter(InvoiceHome);