import React, {useEffect, useState} from 'react';
import { Button, makeStyles, Grid } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AreYouSure from '../../UI/AreYouSure.js/AreYouSure';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        maxWidth: '1200px',
        margin: '10px',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
	family: 'Oswald',
	src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',
        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

const TimesheetTablePayrollPDF = (props) => {
	const classes = useStyles();
    const [marking, setMarking] = useState(false);
    console.log(props.fullData.timesheetData)

    useEffect(() => {

    }, [marking]);

    //bool true if theres any paid timesheets or unpayrolled timesheets
    const hasPaidTimesheets = props.fullData.timesheetData.some((timesheet) => timesheet.paid === true);
    const hasUnpayrolledTimesheets = props.fullData.timesheetData.some((timesheet) => timesheet.payrolled === false);

    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';

        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>
                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 12 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];

                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }

	/**
	 * Process data for a specific UID to calculate weekly totals and segregate weeks.
	 */
	const processDataForUID = (uid) => {
		let weekData = props.fullData.timesheetData.filter((data) => data.uid === uid);
		let user = props.users.find((user) => user.displayName === uid);
		let OT = user?.department === 'Office' ? 40 : 50;

		let firstWeek = [];
		let secondWeek = [];
		let firstWeekEnd = moment(props.fullData.firstDate).endOf('week');

		weekData = weekData.sort((a, b) => (a.date > b.date ? 1 : -1));

		// Week calculations
		const firstWeekTotal = weekData.reduce((total, data) => {
			if (moment(data.date).isBefore(firstWeekEnd)) {
				return total + Number(data.operatorHours);
			}
			return total;
		}, 0);

		const secondWeekTotal = weekData.reduce((total, data) => {
			if (moment(data.date).isAfter(firstWeekEnd)) {
				return total + Number(data.operatorHours);
			}
			return total;
		}, 0);

		const firstWeekRegular = Math.min(firstWeekTotal, OT);
		const firstWeekOT = Math.max(0, firstWeekTotal - OT);
		const secondWeekRegular = Math.min(secondWeekTotal, OT);
		const secondWeekOT = Math.max(0, secondWeekTotal - OT);

		weekData.forEach((data) => {
			if (moment(data.date).isBefore(firstWeekEnd)) {
				firstWeek.push(data);
			} else {
				secondWeek.push(data);
			}
		});

		return {
			firstWeek,
			secondWeek,
			firstWeekTotal,
			firstWeekRegular,
			firstWeekOT,
			secondWeekTotal,
			secondWeekRegular,
			secondWeekOT,
		};
	};

	/**
	 * Generate pages for each UID.
	 */
	const generatePDFPages = () => {
		const uniqueUIDs = [...new Set(props.fullData.timesheetData.map((data) => data.uid))];
        //sort the unique uids by the displayname and
        const sortedUIDs = uniqueUIDs.sort((a, b) => {
            const userA = props.users.find((user) => user.displayName === a);
            const userB = props.users.find((user) => user.displayName === b);
    
            // Fallback to empty string if displayName is missing
            const displayNameA = userA?.displayName?.toLowerCase() || '';
            const displayNameB = userB?.displayName?.toLowerCase() || '';
    
            return displayNameA.localeCompare(displayNameB);
        });

		return sortedUIDs.map((uid) => {
			const {
				firstWeek,
				secondWeek,
				firstWeekTotal,
				firstWeekRegular,
				firstWeekOT,
				secondWeekTotal,
				secondWeekRegular,
				secondWeekOT,
			} = processDataForUID(uid);

			const firstDayOfFirstWeekString = moment(props.fullData.firstDate).format('MMMM Do, YYYY');
			const lastDayOfSecondWeekString = moment(props.fullData.lastDate).format('MMMM Do, YYYY');
			const firstDayOfSecondWeekString = moment(props.fullData.lastDate).startOf('week').format('MMMM Do, YYYY');
			const lastDayOfFirstWeekString = moment(props.fullData.firstDate).endOf('week').format('MMMM Do, YYYY');
            let OT= uid?.department === 'Office' ? 40 : 50;

			return (
				<Page size="A4" style={styles.page} wrap key={uid}>
                    <View style={{ ...styles.section,border:'0px', fontSize:'12px',  }}>
                        <Text>{uid} - Total Hours: {(firstWeekTotal + secondWeekTotal).toFixed(2)}</Text>
                        <Text>{firstDayOfFirstWeekString} - {lastDayOfSecondWeekString}</Text>
                    </View>
                    <View>
                        <View style={styles.summary} wrap={false}>
                            <Text>{firstDayOfFirstWeekString+' - '+lastDayOfFirstWeekString}</Text>
                        </View>
                        <Table
                        columns={[
                            { title: 'Timesheet ID', field: 'timesheetID', defaultSort: 'asc' },
                            { title: 'Timesheet Type', field: 'timesheetType' },
                            { title: 'Date', field: 'date' },
                            { title: 'Hours', field: 'operatorHours' },

                        ]}
                        data={firstWeek}
                        title={'Week 1'} />
                        <View style={styles.summary} wrap={false}>
                            <View style={{ ...styles.table, border:'0px',marginRight: 5, fontSize:'12px' ,width:'50%'}}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}> </Text>
                                    <Text style={styles.tableTextBoldRed}></Text>
                                </View>
                            </View>
                            <View style={{ ...styles.table, marginLeft: 5, fontSize:'12px',backgroundColor:'#e8e8e8'  }}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Total Hours: </Text>
                                    <Text style={styles.tableTextBoldRed}>{firstWeekTotal.toFixed(2)}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Regular Hours: </Text>
                                    <Text style={styles.tableTextBoldRed}>{firstWeekRegular.toFixed(2)}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>{`Overtime >${OT.toFixed(0)} Hours: `} </Text>
                                    <Text style={styles.tableTextBoldRed}>{firstWeekOT.toFixed(2)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.summary} wrap={false}>
                            <Text>{firstDayOfSecondWeekString+' - '+lastDayOfSecondWeekString}</Text>
                        </View>
                        <Table
                        columns={[
                            { title: 'Timesheet ID', field: 'timesheetID', defaultSort: 'asc' },
                            { title: 'Timesheet Type', field: 'timesheetType' },
                            { title: 'Date', field: 'date' },
                            { title: 'Hours', field: 'operatorHours' },

                        ]}
                        data={secondWeek}
                        title={'Week 2'} />
                        <View style={styles.summary} wrap={false}>
                            <View style={{ ...styles.table, border:'0px',marginRight: 5, fontSize:'12px' ,width:'50%'}}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}> </Text>
                                    <Text style={styles.tableTextBoldRed}></Text>
                                </View>
                            </View>
                            <View style={{ ...styles.table, marginLeft: 5, fontSize:'12px',backgroundColor:'#e8e8e8'  }}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Total Hours: </Text>
                                    <Text style={styles.tableTextBoldRed}>{secondWeekTotal.toFixed(2)}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Regular Hours: </Text>
                                    <Text style={styles.tableTextBoldRed}>{secondWeekRegular.toFixed(2)}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>{`Overtime >${OT.toFixed(0)} Hours: `}  </Text>
                                    <Text style={styles.tableTextBoldRed}>{secondWeekOT.toFixed(2)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.summary} wrap={false}>
                            <View style={{ ...styles.table, border:'0px',marginRight: 5, fontSize:'12px' ,width:'50%'}}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}> </Text>
                                    <Text style={styles.tableTextBoldRed}></Text>
                                </View>
                            </View>
                            <View style={{ ...styles.table,border:'0px', marginLeft: 5, fontSize:'12px',  }}>

                                <View style={{...styles.tableRow, borderBottom:'1px solid grey'}}>
                                    <Text style={{...styles.tableText}}>Regular Hours: </Text>
                                    <Text style={{...styles.tableText}}>{(firstWeekRegular + secondWeekRegular).toFixed(2)}</Text>
                                </View>
                                <View style={{...styles.tableRow, borderBottom:'1px solid black'}}>
                                    <Text style={{...styles.tableText}}>{`Overtime Hours: `}  </Text>
                                    <Text style={{...styles.tableText}}>{(firstWeekOT + secondWeekOT).toFixed(2)}</Text>
                                </View>
                                <View style={{...styles.tableRow, borderBottom:'1px solid grey'}}>
                                    <Text style={{...styles.tableText}}>Total Hours: </Text>
                                    <Text style={{...styles.tableTextBold}}>{(firstWeekTotal + secondWeekTotal).toFixed(2)}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
			);
		});
	};
    

	return (
		<Grid className={classes.root}>
            <AreYouSure timer={0} customMessage={`You are about to mark all timesheets mentioned in these reports as PAID OUT. Are you sure?`} open={marking} setOpen={setMarking} passedFunction={() => props.markAsPaid()}/>
			<Grid container xs={12} className={classes.content} justifyContent='flex-end'>
				<Grid item xs={6} style={{paddingBottom:'4px'}}>
					<Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => props.history.goBack()}>
						Back
					</Button>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' ,paddingBottom:'4px'}}>
                    <Button variant="contained" startIcon={<LocalAtmIcon />} disabled={hasPaidTimesheets || hasUnpayrolledTimesheets} onClick={() => setMarking(true)}>
						Mark As Paid
					</Button>
				</Grid>
				<PDFViewer style={styles.viewer}>
					<Document>{generatePDFPages()}</Document>
				</PDFViewer>
			</Grid>
		</Grid>
	);
};

export default withRouter(TimesheetTablePayrollPDF);
