import React, { useState } from 'react';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons'
import moment from 'moment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withRouter } from "react-router-dom";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Checkbox, Chip, FormControlLabel, Typography, Button, Divider, Grid } from '@material-ui/core';


const OrderTable = (props) => {
    
    const [selecting, setSelecting] = useState(false);

    let selectedData = []
    let tableOrders = [];
    let failedOrders = []
    let filteredOrders = !props.showComplete && !props.showCancelled ? props.orders?.filter(order => order?.order?.status !== 'COMPLETED' && order?.order?.status !== 'CANCELLED')  
    : props.showComplete && !props.showCancelled ? props.orders?.filter(order => order?.order?.status !== 'CANCELLED') : !props.showComplete && props.showCancelled ? props.orders?.filter(order => order?.order?.status !== 'COMPLETED')
    : props.orders
    //let filteredOrders = props.orders
    let checkedStatus = props.checked;
    let updateChecked = props.onChange;
    let cancelledStatus = props.showCancelled;
    let updateCancelled = props.onChangeComplete;
    let smallScreen = props.smallScreen;

    filteredOrders.forEach((order) => {

        let date = order.order.deliverDate ? moment(order.order.deliverDate).format('MMM D YYYY') : ""
        if (date === 'Invalid date') {date = order?.payment?.created_at ? moment(order.payment.created_at).format('MMM D YYYY') : ""}

        date = date + ' ' + order.order.deliverTime

        let mobileDate = order.order.deliverDate ? moment(order.order.deliverDate).format('YYYY-MM-DD') : ""
        

        //add 1 hour to winnipeg time because it gives us an hour earlier than it should be. converting to Winnipeg timezone also gives us
        //an even more wrong time so idk whats going on lmao
        //fuckin timezone shit
        let winnipegDate = order?.payment?.created_at ? moment(order.payment.created_at).format('MMM D YYYY, h:mm a') : ""

        let orderData = {
            'docId': order?.docId,
            'bol': order?.bol,
            'created': winnipegDate,
            'amount': order?.payment?.paymentHandler ? order?.order?.fees?.total : order?.order?.fees?.total / 100,
            'status': order?.order?.status,
            'customer': order?.customer?.givenName + " " + order?.customer?.familyName,
            'phone': order?.customer.phone,
            'orderID': order?.order?.orderID,
            'preferredDate': date,
            'deliverables': order?.order?.products?.filter(product => (product.shippingMethod === 'Delivery')),
            'pickupables': order?.order?.products?.filter(product => (product.shippingMethod === 'Pickup')),
            'notes': order?.order?.notes,
            'coupon': order?.order?.fees?.couponCode?.code ? order?.order?.fees?.couponCode?.code.toUpperCase() : "None",
            'mobileDate': mobileDate,
            'mobileTime': order?.order?.deliverTime,
            'location': order?.customer?.destination?.location[0],
            'products': order?.order?.products,
            'refundStatus': order?.order?.refund ? order?.order?.refund : '',
            'truckNumber': order?.truckNumber ? order?.truckNumber : '',
            'operator': order?.operator ? order?.operator : '',
        }

        order.payment ? tableOrders.push(orderData) : failedOrders.push(orderData)
    })


    tableOrders = tableOrders.sort((order1, order2) => moment(order2.created, 'MMM D YYYY, h:mm a').valueOf() - moment(order1.created, 'MMM D YYYY, h:mm a').valueOf());
    failedOrders = failedOrders.sort((order1, order2) => moment(order2.created, 'MMM D YYYY, h:mm a').valueOf() - moment(order1.created, 'MMM D YYYY, h:mm a').valueOf());

    const handlePrintSelected = () => {
        props.history.push({
            pathname: '/dashboard/reporting/orders/printing/selected/',
            state: { data: selectedData }
        })
    }

    return (
        !smallScreen ?
            <React.Fragment>
                <div style={{ marginLeft: '24px' }}>
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Show Completed"
                        checked={checkedStatus}
                        onChange={updateChecked} />
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Show Cancelled"
                        checked={cancelledStatus}
                        onChange={updateCancelled} />
                </div>
                <MaterialTable
                    tableRef={props.tableRef}
                    title="Orders"
                    loading={props.loading}
                    columns=
                    {[
                        { title: 'Order #', field: 'orderID' },
                        { title: 'BOL', field: 'bol' },
                        { title: 'Customer', field: 'customer' },
                        { title: 'Submitted Date', field: 'created', type: 'date' },
                        { title: 'Preferred Date/Time', field: 'preferredDate', type: 'date' },
                        { title: 'Amount', field: 'amount', type: 'currency', align: 'left' },
                        { title: 'Coupon', field: 'coupon', render: rowData => rowData.coupon !== "None" ? <Typography align='center' style={{backgroundColor:'lightGreen',
                         padding:'5px', borderRadius:'5px'}}><b>{rowData.coupon}</b></Typography> : "None" },
                        { title: 'Status', field: 'status' },
                        { title: 'Delivery', width: '1%', field: 'deliverables', render: rowData => rowData.deliverables.length !== 0 ? <CheckCircleOutlineIcon /> : "" },
                        { title: 'Pickup', width: "1%", field: 'pickupables', render: rowData => rowData.pickupables.length !== 0 ? <CheckCircleOutlineIcon /> : "" },
                        { title: 'Refund', field: 'refundStatus' },
                        /*  { title: 'Coupon', field: 'coupon' }, */
                    ]}
                    data={tableOrders}
                    icons={TableIcons}
                    onSearchChange={ (value) => {sessionStorage.setItem("orderSearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("orderApprovalRowsPerPage", JSON.parse(num))}}
                    onSelectionChange={(rows) =>{selectedData = rows}}
                    actions={[
                        {
                            icon: OpenInNewIcon,
                            tooltip: 'Open',
                            onClick: (event, rowData) => {
                                props.history.push({
                                    pathname: "orders/order",
                                    search: "?" + new URLSearchParams({ orderId: rowData['docId'] }).toString(),
                                    state: { order: props.orders.find((order) => rowData['docId'] === order.docId) },
                                });
                            }
                        }
                    ]}
                    onPageChange={ (pageNum) => { sessionStorage.setItem("orderReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        initialPage: "orderReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("orderReportPageValue")) : 0,
                        pageSize: "orderApprovalRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("orderApprovalRowsPerPage")) : 15,
                        pageSizeOptions: [15, 30, 60],
                        actionsColumnIndex: -1,
                        columnsButton: true,
                        showSelectAllCheckbox: true,
                        toolbar: true,
                        selection: selecting,
                        searchText: "orderSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("orderSearchBarValue")) : null,
                        rowStyle: rowData => {
                            if (rowData.status === "COMPLETED") {
                                return { backgroundColor: '#f0fff8' };
                            }
                            if (rowData.status === "CANCELLED") {
                                return { backgroundColor: '#d1d1d1' };
                            }

                            return {};
                        }
                    }}
                    components={{
                        Toolbar: props => (
                          <div>
                            <MTableToolbar {...props} />
                                <div style={{padding: '0px 10px'}}>
                                <Button 
                                    variant="contained" 
                                    size={"small"} 
                                    style={{marginRight: 5}} 
                                    onClick={() => {setSelecting(!selecting); sessionStorage.setItem('timesheetSelecting', JSON.stringify(!selecting))}}>
                                        {selecting ? 'Cancel Selecting' : 'Select For Print'}
                                </Button>
                                    {selecting ? 
                                        <Button
                                            variant="contained" 
                                            size={"small"} 
                                            style={{marginRight: 5}} 
                                            onClick={() => handlePrintSelected()}
                                        >
                                            Print Selected
                                        </Button> 
                                    : null}
                                </div>
                          </div>
                        ),
                      }}
                    detailPanel={[
                        rowData => ({
                            tooltip: 'Show Details',
                            icon: () => <small><ArrowForwardIosIcon style={{ fontSize: '12px' }} /></small>,
                            render: () =>
                                <React.Fragment>
                                    <Grid container xs={12}>
                                        {rowData.notes !== "" ?
                                        <><Grid item xs={12}>
                                            <Typography variant="h6" style={{ padding: '10px' }}>Notes: </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography style={{ padding: '10px' }}>{rowData.notes}</Typography>
                                        </Grid></> :null}
                                        <Grid item xs={6}>
                                            <Typography variant="h6" style={{ padding: '10px' }}>Address: </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{ padding: '10px' }}>{rowData.location}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{ padding: '10px' }}>Deliverables: </Typography>
                                        </Grid>
                                        {rowData.deliverables.length !== 0 ? 
                                        <Typography style={{ padding: '10px' }}> {rowData.deliverables.map((deliverable, index) => <span key={index}>{deliverable.productName} - 
                                            &nbsp;{deliverable.qty} {deliverable.price.unit} <br /></span>)}</Typography>    
                                        : <Typography style={{ padding: '10px' }}>None</Typography>}

                                    </Grid>
                                    <Divider/>
                                    <Grid container xs={12}>
                                    {rowData?.operator !== '' ?
                                        <><Grid item xs={6}>
                                            <Typography variant="h6" style={{ padding: '10px' }}>Operator Assigned: </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                            <Typography style={{ padding: '10px' }}>{rowData?.operator}</Typography>
                                            </Grid></> :null}
                                        {rowData?.truckNumber !== '' ?
                                        <><Grid item xs={6}>
                                            <Typography variant="h6" style={{ padding: '10px' }}>Truck Assigned: </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{ padding: '10px' }}>Unit #{rowData?.truckNumber?.unitNumber} - {rowData?.truckNumber?.description}</Typography>
                                            </Grid></> :null}
                                    </Grid>
                                </React.Fragment>,
                        }),
                    ]}
                    onRowClick={(event, rowData, togglePanel) => {
                        props.history.push({
                            pathname: "orders/order",
                            search: "?" + new URLSearchParams({ orderId: rowData['docId'] }).toString(),
                            state: { order: props.orders.find((order) => rowData['docId'] === order.docId) },
                        });
                    }}
                />
            </React.Fragment>
            :
            <React.Fragment>
                <Grid container >
                    <Grid item xs={3} style={{ marginLeft: '10px' }}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Completed"
                            checked={checkedStatus}
                            onChange={updateChecked} />
                    </Grid>
                    <Grid item xs={3} style={{ marginLeft: '70px' }}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Cancelled"
                            checked={cancelledStatus}
                            onChange={updateCancelled} />
                    </Grid>
                </Grid>
                <MaterialTable
                    tableRef={props.tableRef}
                    title={<small style={{ marginRight: '10px' }}><b>Orders</b></small>}
                    loading={props.loading}
                    columns=
                    {[
                        { title: '#', field: 'orderID', render: rowData => <small>{rowData.orderID}</small> },
                        { title: 'BOL', field: 'bol', render: rowData => <small>{rowData.bol}</small> },
                        { title: 'Customer', field: 'customer', render: rowData => <small>{rowData.customer.toUpperCase()}</small> },
                        { title: 'Submitted', field: 'created', type: 'date', render: rowData => <small>{moment(rowData.created).format("YYYY-MM-DD")}</small> },
                        {
                            title: 'Preferred', field: 'mobileDate', type: 'date', render: rowData => rowData.mobileTime === "AM" || rowData.mobileTime === "PM" ? <small >{rowData.mobileDate}
                                <Chip style={{ marginLeft: '5px' }} color="secondary" size="small" label={rowData.mobileTime} /></small> : <small>{rowData.mobileDate}</small>
                        },
                    ]}
                    data={tableOrders}
                    icons={TableIcons}
                    onSearchChange={ (value) => {sessionStorage.setItem("orderSearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("orderApprovalRowsPerPage", JSON.parse(num))}}
                    onSelectionChange={(rows) =>{selectedData = rows}}
                    components={{
                        Toolbar: props => (
                          <div>
                            <MTableToolbar {...props} />
                                <div style={{padding: '0px 10px'}}>
                                <Button 
                                    variant="contained" 
                                    size={"small"} 
                                    style={{marginRight: 5}} 
                                    onClick={() => {setSelecting(!selecting); sessionStorage.setItem('timesheetSelecting', JSON.stringify(!selecting))}}>
                                        {selecting ? 'Cancel Selecting' : 'Select For Print'}
                                </Button>
                                    {selecting ? 
                                        <Button
                                            variant="contained" 
                                            size={"small"} 
                                            style={{marginRight: 5}} 
                                            onClick={() => handlePrintSelected()}
                                        >
                                            Print Selected
                                        </Button> 
                                    : null}
                                </div>
                          </div>
                        ),
                      }}
                    actions={[
                        {
                            icon: OpenInNewIcon,
                            tooltip: 'Open',
                            onClick: (event, rowData) => {
                                props.history.push({
                                    pathname: "orders/order",
                                    search: "?" + new URLSearchParams({ orderId: rowData['docId'] }).toString(),
                                    state: { order: props.orders.find((order) => rowData['docId'] === order.docId) },
                                });
                            }
                        }
                    ]}
                    options={{
                        pageSize: 15,
                        pageSizeOptions: [15, 30, 60],
                        actionsColumnIndex: -1,
                        columnsButton: true,
                        showSelectAllCheckbox: true,
                        toolbar: true,
                        selection: selecting,
                        searchText: "orderSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("orderSearchBarValue")) : null,
                        rowStyle: rowData => {
                            if (rowData.status === "COMPLETED") {
                                return { backgroundColor: '#f0fff8' };
                            }
                            if (rowData.status === "CANCELLED") {
                                return { backgroundColor: '#d1d1d1' };
                            }

                            return {};
                        }
                    }}
                    detailPanel={[
                        rowData => ({
                            tooltip: 'Show Details',
                            icon: () => <small><ArrowForwardIosIcon style={{ fontSize: '12px' }} /></small>,
                            render: () =>
                                <React.Fragment>
                                    <table>
                                        <tbody>
                                            <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                                <td style={{ padding: '16px' }}><b>Notes: </b></td>
                                                <td colSpan="100" style={{ padding: '11px' }}>{rowData.notes}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </React.Fragment>,
                        }),
                    ]}
                    onRowClick={(event, rowData, togglePanel) => {
                        if (rowData.notes !== "") {
                            togglePanel();
                        } else {
                            return null;
                        }
                    }}
                    
                />
            </React.Fragment>
    );
};

export default withRouter(OrderTable);