import {
    Button, Grid, makeStyles, Paper, Snackbar, TextField, Typography, FormControlLabel, Checkbox,
    FormControl, InputLabel, Select, MenuItem, Switch, InputAdornment, Divider
} from "@material-ui/core"
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { Alert } from "@material-ui/lab";
import { useAuth } from '../../../../providers/AuthContext';
import moment from "moment";
import HistoricChart from "./HistoricChart";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
    },
    paper: {
        width: '100%',
        maxWidth: '600px',
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    modal: {
        width: '250px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
        backgroundColor: 'rgb(49, 49, 49)',
        border: '1px solid black'
    },
}));

const ShopSettingsCard = (props) => {
    const styles = useStyles();
    const [errorMessage, setErrorMessage] = useState("")
    const { currentUser } = useAuth();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        visible: props.saveState[props.documents['shopSettings']].notice.visible || false,
    });

    useEffect(() => {
    }, [open] )

    const handleSwitch = (event) => {
        if(event.target.checked)
            props.setSaveState({
                ...props.saveState,
                [props.documents['shopSettings']]: {
                    ...props.saveState[props.documents['shopSettings']],
                    'isOpen': true,
                    'openedBy': currentUser.email,
                    'openedDate' : moment.utc(new Date()).format('dddd, MMMM Do YYYY [@] h:mm a'),
                 }
            })
        else
            props.setSaveState({
                ...props.saveState,
                [props.documents['shopSettings']]: {
                    ...props.saveState[props.documents['shopSettings']],
                    'isOpen': false,
                    'closedBy': currentUser.email,
                    'closedDate' : moment.utc(new Date()).format('dddd, MMMM Do YYYY [@] h:mm a'),
                }
            })
    }

    const handleChange = (event) => {
        setErrorMessage("")
        if (event.target.checked){
            if (props.saveState[props.documents['shopSettings']].notice.message === "" && props.saveState[props.documents['shopSettings']].notice.importantMessage === "") {
                setErrorMessage("Cant activate empty Alert! Please fill out a message.")
                props.setSaveState({
                    ...props.saveState,
                    [props.documents['shopSettings']]: {
                        ...props.saveState[props.documents['shopSettings']],
                        'notice': {
                            ...props.saveState[props.documents['shopSettings']].notice,
                            visible: false
                        }
                    }
                })
            }
            else {
                props.setSaveState({
                    ...props.saveState,
                    [props.documents['shopSettings']]: {
                        ...props.saveState[props.documents['shopSettings']],
                        'notice': {
                            ...props.saveState[props.documents['shopSettings']].notice,
                            visible: event.target.checked
                        }
                    }
                })
            }
        }
        else {
            props.setSaveState({
                ...props.saveState,
                [props.documents['shopSettings']]: {
                    ...props.saveState[props.documents['shopSettings']],
                    'notice': {
                        ...props.saveState[props.documents['shopSettings']].notice,
                        visible: event.target.checked
                    }
                }
            })
        }
    };

    useEffect(() => {
        if (props.saveState[props.documents['shopSettings']].notice.message === "" && props.saveState[props.documents['shopSettings']].notice.importantMessage === "") {
            setState({ ...state, visible: false });
            props.setSaveState({
                ...props.saveState,
                [props.documents['shopSettings']]: {
                    ...props.saveState[props.documents['shopSettings']],
                    'notice': {
                        ...props.saveState[props.documents['shopSettings']].notice,
                        visible: false
                    }
                }
            })
        }
        // eslint-disable-next-line
    }, [props.saveState[props.documents['shopSettings']].notice.message, props.saveState[props.documents['shopSettings']].notice.importantMessage])

    return <form className={styles.root} onSubmit={(event) => props.handleSave(event)}>
        <Paper className={styles.paper}>
            <HistoricChart open={open} setOpen={setOpen}/>
            <Grid container spacing={1}>
                <Grid item xs={12} className={styles.title}>
                    <Typography variant={'h5'}>Shop Settings</Typography>
                </Grid>
                {/*   <Grid item xs={5} className={styles.row}>
                    <Typography>Max Shipping Distance</Typography>
                </Grid>
                <Grid item xs={7} className={styles.row}>
                    <TextField
                        required
                        type='number'
                        inputProps={{
                            step: 0.01,
                            textAlign: 'end'
                        }}
                        InputProps={{
                            endAdornment: < InputAdornment position='end' > <Typography>KM</Typography></InputAdornment>
                        }}
                        fullWidth
                        variant='outlined'
                        value={props.saveState.maxDistance}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            'maxDistance': event.target.value
                        })}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Shop Availability</Typography>
                </Grid>
                <Grid item xs={3} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Shop Status:</Typography>
                </Grid>
                <Grid item xs={2} className={styles.row}>
                    
                    <FormControlLabel control={<Switch
                    color={props.saveState[props.documents['shopSettings']].isOpen ? "primary" : "secondary"}
                    checked={props.saveState[props.documents['shopSettings']].isOpen}
                    onChange={handleSwitch}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />} label={props.saveState[props.documents['shopSettings']].isOpen ? "Open" : "Closed"} />
                </Grid>
                
                    <Grid item xs={12} className={styles.row}>
                    {!_.isUndefined(props.saveState[props.documents['shopSettings']].closedBy) || !_.isUndefined(props.saveState[props.documents['shopSettings']].openedBy) ?
                    <Typography variant={'caption'}>{props.saveState[props.documents['shopSettings']].isOpen ? "Opened by " + props.saveState[props.documents['shopSettings']].openedBy + " on "+ props.saveState[props.documents['shopSettings']].openedDate  
                    : "Closed by " + props.saveState[props.documents['shopSettings']].closedBy + " on "+ props.saveState[props.documents['shopSettings']].closedDate}</Typography>
                 : null}       
                </Grid>

                <Grid item xs={12} className={styles.title}>
                    <Typography variant={'h6'}>Shop Fees</Typography>
                    <Divider></Divider>
                </Grid>

                <Grid item xs={2} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Base Fee</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                            style={{ maxWidth: '150px' }}
                            variant='outlined'
                            size='small'
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$ </InputAdornment>,
                            }}
                            value={props.saveState[props.documents['taxes']].cartFees.baseDEFAULT}
                            onChange={(event) => props.setSaveState({
                                ...props.saveState,
                                [props.documents['taxes']]: {
                                    ...props.saveState[props.documents['taxes']],
                                    'cartFees': {
                                        ...props.saveState[props.documents['taxes']].cartFees,
                                        'baseDEFAULT': event.target.value
                                    }
                                    
                                }
                            })} />
                </Grid>

                <Grid item xs={2} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Base Fee <small>(Stonewall)</small></Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                            style={{ maxWidth: '150px' }}
                            variant='outlined'
                            size='small'
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$ </InputAdornment>,
                            }}
                            value={props.saveState[props.documents['taxes']].cartFees.baseSTONEWALL}
                            onChange={(event) => props.setSaveState({
                                ...props.saveState,
                                [props.documents['taxes']]: {
                                    ...props.saveState[props.documents['taxes']],
                                    'cartFees': {
                                        ...props.saveState[props.documents['taxes']].cartFees,
                                        'baseSTONEWALL': event.target.value
                                    }
                                    
                                }
                            })} />
                </Grid>

                <Grid item xs={2} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Brick Fee</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        value={props.saveState[props.documents['taxes']].brickFee}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'brickFee': event.target.value
                            }
                        })} />
                </Grid>
                <Grid item xs={12} className={styles.title}/>
                <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography><small>Carbon Fee</small></Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    <small>{(props.saveState[props.documents['taxes']].carbonFee * 100).toFixed(2)}%</small>
                                </React.Fragment>
                            ),
                        }}
                        value={props.saveState[props.documents['taxes']].carbonFee}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'carbonFee': event.target.value
                            }
                        })} />
                </Grid>
                <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography><small>Fuel Surcharge</small></Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    <small>{(props.saveState[props.documents['taxes']].fuelSurcharge * 100).toFixed(2)}%</small>
                                </React.Fragment>
                            ),
                        }}
                        value={props.saveState[props.documents['taxes']].fuelSurcharge}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'fuelSurcharge': event.target.value
                            }
                        })} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant='contained' size="small" color='primary' onClick={() => setOpen(true)}>View Monthly Data</Button>
                </Grid>

                {/*
                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Cartage Rate:</Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
          
                        value={props.saveState[taxes].cartageRate}
                        onInput={() => setErrorMessage("")}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [taxes]: {
                                ...props.saveState[taxes],
                                cartageRate: event.target.value
                            }
                        })}
                        />
                </Grid>
                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Typography><small>{(props.saveState[taxes].cartageRate * 100).toFixed(2)}%</small></Typography>
                </Grid> */}

                <Grid item xs={12} className={styles.title}/>
                <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>GST:</Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px',}}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    <small>{(props.saveState[props.documents['taxes']].salesTax.GST * 100).toFixed(2)}%</small>
                                </React.Fragment>
                            ),
                        }}
                        value={props.saveState[props.documents['taxes']].salesTax.GST}
                        onInput={() => setErrorMessage("")}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'salesTax': {
                                    ...props.saveState[props.documents['taxes']].salesTax,
                                    GST: event.target.value
                                }
                            }
                        })} 
                        />
                </Grid>
                <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>PST:</Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px', }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <React.Fragment>
                                    <small>{(props.saveState[props.documents['taxes']].salesTax.PST * 100).toFixed(2)}%</small>
                                </React.Fragment>
                            ),
                        }}
                        value={props.saveState[props.documents['taxes']].salesTax.PST}
                        onInput={() => setErrorMessage("")}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'salesTax': {
                                    ...props.saveState[props.documents['taxes']].salesTax,
                                    PST: event.target.value
                                }
                            }
                        })} 
                        />
                </Grid>

                <Grid item xs={12} className={styles.title}>
                    <Typography variant={'h6'}>Delivery Fees</Typography>
                    <Divider></Divider>
                </Grid>   

                {/*
                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Max Delivery Distance:</Typography>
                </Grid>
                <Grid item xs={8} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[shopSettings].maxDistance}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">km</InputAdornment>,
                          }}
                        onInput={() => setErrorMessage("")}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [shopSettings]: {
                                ...props.saveState[shopSettings],
                                maxDistance: event.target.value
                            }
                        })}
                        />
                </Grid>
                    */}

                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Delivery Minimum</Typography>
                </Grid>
                <Grid item xs={8} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$ </InputAdornment>,
                          }}
                        value={props.saveState[props.documents['taxes']].cartFees.deliveryMinimum}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'cartFees': {
                                    ...props.saveState[props.documents['taxes']].cartFees,
                                    'deliveryMinimum': event.target.value
                                }
                                
                            }
                        })} />
                </Grid>         
                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Delivery Low Rate</Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$  </InputAdornment>,
                            endAdornment: <InputAdornment position="end">  </InputAdornment>
                          }}
                        value={props.saveState[props.documents['taxes']].cartFees.deliveryLowRate}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'cartFees': {
                                    ...props.saveState[props.documents['taxes']].cartFees,
                                    'deliveryLowRate': event.target.value
                                }
                                
                            }
                        })} />
                </Grid>    
                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Typography><small>Below or equal to 20km</small></Typography>
                </Grid>

                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Delivery Mid Rate</Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$  </InputAdornment>,
                            endAdornment: <InputAdornment position="end">  </InputAdornment>
                          }}
                        value={props.saveState[props.documents['taxes']].cartFees.deliveryMidRate}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'cartFees': {
                                    ...props.saveState[props.documents['taxes']].cartFees,
                                    'deliveryMidRate': event.target.value
                                }
                                
                            }
                        })} />
                </Grid>    
                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Typography><small>Above 20km and below or equal to 40km</small></Typography>
                </Grid>

                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Delivery High Rate</Typography>
                </Grid>
                <Grid item xs={4} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '150px' }}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$  </InputAdornment>,
                            endAdornment: <InputAdornment position="end">  </InputAdornment>
                          }}
                        value={props.saveState[props.documents['taxes']].cartFees.deliveryHighRate}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['taxes']]: {
                                ...props.saveState[props.documents['taxes']],
                                'cartFees': {
                                    ...props.saveState[props.documents['taxes']].cartFees,
                                    'deliveryHighRate': event.target.value
                                }
                                
                            }
                        })} />
                </Grid>    
                <Grid item xs={4} className={styles.row} style={{ justifyContent: 'flex-start' }}>
                    <Typography><small>Above 40km</small></Typography>
                </Grid>

            </Grid>
        </Paper>

        <Paper className={styles.paper} style={{marginBottom: '75px'}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                    <Typography variant={'h6'}>Landing Page Alert</Typography>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Title:</Typography>
                </Grid>
                <Grid item xs={10} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['shopSettings']].notice.message}
                        onInput={() => setErrorMessage("")}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['shopSettings']]: {
                                ...props.saveState[props.documents['shopSettings']],
                                'notice': {
                                    ...props.saveState[props.documents['shopSettings']].notice,
                                    message: event.target.value
                                }
                            }
                        })} />
                    <FormControlLabel
                        control={<Checkbox checked={props.saveState[props.documents['shopSettings']].notice.visible} onChange={handleChange} name="visible" />}
                        label="Active"
                    />
                </Grid>
                <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Message:</Typography>
                </Grid>
                <Grid item xs={10} className={styles.row}>
                    <TextField
                        style={{ maxWidth: '200px', marginRight: '22px' }}
                        variant='outlined'
                        size='small'
                        value={props.saveState[props.documents['shopSettings']].notice.importantMessage}
                        onInput={() => setErrorMessage("")}
                        onChange={(event) => props.setSaveState({
                            ...props.saveState,
                            [props.documents['shopSettings']]: {
                                ...props.saveState[props.documents['shopSettings']],
                                'notice': {
                                    ...props.saveState[props.documents['shopSettings']].notice,
                                    importantMessage: event.target.value
                                }
                            }
                        })} />
                    <FormControl className={styles.formControl} style={{ width: '100px' }}>
                        <InputLabel>Alert Type</InputLabel>
                        <Select
                            value={props.saveState[props.documents['shopSettings']].notice.type}
                            onChange={(event) => props.setSaveState({
                                ...props.saveState,
                                [props.documents['shopSettings']]: {
                                    ...props.saveState[props.documents['shopSettings']],
                                    'notice': {
                                        ...props.saveState[props.documents['shopSettings']].notice,
                                        type: event.target.value
                                    }
                                }
                            })}
                        >
                            <MenuItem value="info">Info</MenuItem>
                            <MenuItem value="error">Error</MenuItem>
                            <MenuItem value="warning">Warning</MenuItem>
                            <MenuItem value="success">Success</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} className={styles.row} style={{ justifyContent: 'flex-end' }}>
                    <Typography>Preview:</Typography>
                </Grid>
                <Grid item xs={10} className={styles.row}>
                    <Alert variant="filled" severity={props.saveState[props.documents['shopSettings']].notice.type} style={{ color: 'black' }}>
                        {props.saveState[props.documents['shopSettings']].notice.message} <strong>{props.saveState[props.documents['shopSettings']].notice.importantMessage}</strong></Alert>
                </Grid>
                <Typography color="error">{errorMessage}</Typography>
                
            </Grid>
        </Paper>

        <Snackbar
            open={!_.isEqual(props.shopSettings, props.saveState)}
            message={props.saving ? 'Saving...' : 'Save Document?'}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            action={
                <React.Fragment>
                    {props.saving
                        ? null
                        : <React.Fragment>
                            <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                            <Button variant='contained' type='submit'>Confirm</Button>
                        </React.Fragment>}
                </React.Fragment>
            }
        />
    </form>
}

export default ShopSettingsCard;