import React, { useState, useEffect } from 'react';
import UserForm from '../../components/Users/UserForm'
import { functions } from '../../../firebase/firebase'
import { firestore } from '../../../firebase/firebase'
import { storage } from '../../../firebase/firebase'
//import { useAuth } from '../../../providers/AuthContext'
import { useUser } from '../../../providers/UserContext';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const UserNew = (props) => {

    const initialUserForm = {
        admin: false,
        firstName: '',
        lastName: '',
        driversBackURL: '',
        driversFrontURL: '',
        email: '',
        phone: '',
        role: '',
        department: 'None',
        employment:{active: true, startDate: moment().format('YYYY-MM-DD'), endDate: ''},
        mfaEnabled:false,
    }

    const [userForm, setUserForm] = useState({ ...initialUserForm });
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    //const { resetPassword } = useAuth();
    const { getUsers, loading, users } = useUser();
    const sendEmail = functions.httpsCallable('sendPasswordEmail');


    useEffect(() => {
        getUsers();
        if(userForm.lastName === '' || userForm.firstName === '' || userForm.email === ''){setError('All required fields must be filled out')}
      }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleEmploymentChange = (event) => {
        setUserForm({ ...userForm, 'employment': {...userForm.employment, [event.target.name]: event.target.value} });
    }

    const handleChange = (event) => {
        if(event.target.name === 'email' && !loading && users.filter(user => user.email === event.target.value).length > 0){
            setError('Email already in use!')
        }
        else if(event.target.name === 'email' && !isEmail(event.target.value) && event.target.value !== ''){
            setError('Invalid email.')
        }
        else if(event.target.name === 'phone' && !isMobilePhone(event.target.value) && event.target.value !== ''){
            setError('Invalid phone number.')
        }
        else if(event.target.name === 'lastName' && event.target.value.length <= 2){
            setError('Last name must be more than 2 characters.')
        }
        else if(event.target.name === 'phone' && isMobilePhone(event.target.value) && event.target.value.length >=10){
            let f_val = event.target.value.replace('-', '');
            event.target.value = event.target.value.replace('-', '');
            event.target.value = "("+f_val.slice(0,3)+") "+f_val.slice(3,6)+"-"+f_val.slice(6);

        }
        else if(userForm.lastName === '' || userForm.role === '' || userForm.firstName === '' || userForm.email === ''){setError('All required fields must be filled out')}
        else if(!loading && users.filter(user => user.email === userForm.email).length > 0 ){setError('Email already in use!')}
        else {
            setError('')
        }
        setUserForm({ ...userForm, [event.target.name]: event.target.value });
    }

    const handleCheck = (event) => {
        setUserForm({ ...userForm, [event.target.name]: event.target.checked });

    }

    const handleFileUpload = (event) => {
        setUserForm({ ...userForm, [event.target.name]: event.target.files[0] });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createUser();
    }

    const createUser = async () => {

        setSubmitting(true);

        let newUserForm = userForm;
        let uid = '';
        let imgFrontRef = '';
        let imgBackRef = '';

        let storageRef = storage.ref();

        newUserForm.dateCreated = Date.now() / 1000;
        newUserForm.email = userForm.email.toLowerCase()
        newUserForm.displayName = userForm.firstName + " " + userForm.lastName;

        const createUser = functions.httpsCallable('createUser');

        //Send formdata
        createUser({
            userData: userForm,
        })
            .then((response) => {

                uid = response.data.uid;
                newUserForm.uuid = uid;
                imgFrontRef = storageRef.child('users/' + response.data.uid + '/driversFront');
                imgBackRef = storageRef.child('users/' + response.data.uid + '/driversBack');

                return true;
            })

            .then(() => {
                return newUserForm.driversFront ? imgFrontRef.put(newUserForm.driversFront) : '';
            })
            .then((ref) => {
                return ref !== '' ? imgFrontRef.getDownloadURL() : '';
            })
            .then((url) => {
                newUserForm.driversFront = url;
            })

            .then(() => {
                return newUserForm.driversBack ? imgBackRef.put(newUserForm.driversBack) : '';
            })
            .then((ref) => {
                return ref !== '' ? imgBackRef.getDownloadURL() : '';
            })
            .then((url) => {
                newUserForm.driversBack = url;
            })

            .then(() => {
                firestore.collection('users').add(newUserForm)
                    .then(() => {
                        setUserForm({ ...initialUserForm,

                        });
                        setSubmitting(false);
                        setError('');
                    })
            })
            .then(() =>{
                //resetPassword(newUserForm.email)
                sendEmail({
                    email: newUserForm.email,
                })
                setSuccess(true)
                setTimeout(() => {setSuccess(false)}, 2 * 1000);
            })
            .catch(function (error) {
                setSubmitting(false);
                console.log(error);
                setError("Unable to create user, Error: " + error);
            });
    }

    return (
        <UserForm
            userForm={userForm}
            handleChange={handleChange} handleSubmit={handleSubmit}
            handleCheck={handleCheck} handleEmploymentChange={handleEmploymentChange}
            handleFileUpload={handleFileUpload} error={error}
            submitting={submitting} success={success}
        />
    )
}

export default withRouter(UserNew);