import React from 'react';
import moment from 'moment';
import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { getChecklistType } from '../../../utility/utility'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },

    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    dTableCellBoldRed: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 15,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        color: 'red',
    },
    dTableCellBoldRedSmall: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 11,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        color: 'red',
    },
    dTableCellCustomer: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        color: 'red',
    },
    dTableCellJobNum: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 8,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        color: '#4169e1' //royal blue,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    //tableText bold and red
    tableTextBoldRed: {
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        color: 'red',
    },
    tableTextBoldRedCustomer: {
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        color: 'red',
    },
    tableTextBoldBlueJobNumber: {
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Helvetica-Bold',
        color: '#4169e1' //royal blue,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    QRImage: {
        width: '100%',
        height: '100%',
    },
});

// Create Document Component
const TimesheetPDF = (props) => {

    const [showChecklists, setShowChecklists] = React.useState(true);
    const [showJobs, setShowJobs] = React.useState(true);
    const [showCosts, setShowCosts] = React.useState(true);
    const [showDeliveries, setShowDeliveries] = React.useState(true);
    const [showUnits, setShowUnits] = React.useState(true);
    const [showVehicleInspection, setShowVehicleInspection] = React.useState(true);

    const classes = useStyles();

    React.useEffect(() => {
        
        //eslint-disable-next-line
    }, [])

    //Path for returning to timesheet
    //let timesheetPath = "/dashboard/reporting/timesheets?docId=" + props.timesheetData.docId;

    if (props.timesheetData.date < 9999999999) props.timesheetData.date *= 1000
    if (props.timesheetData.inTime < 9999999999) props.timesheetData.inTime *= 1000
    if (props.timesheetData.outTime < 9999999999) props.timesheetData.outTime *= 1000

    //Calculates the total time during the shift
    let totalTimesheetTime = (props.timesheetData.usingGateOutTime? props.timesheetData.gateOut15 : props.timesheetData.outTime) - (props.timesheetData.usingGateInTime ? props.timesheetData.gateIn15 : props.timesheetData.inTime);
    totalTimesheetTime = (props.timesheetData.skipLunch === "na" || props.timesheetData.skipLunch === "declined" ? totalTimesheetTime - 1800000 : totalTimesheetTime);
    totalTimesheetTime = totalTimesheetTime > 0 ? totalTimesheetTime.toFixed(2) : 0;

    //Calcluate operator hours
    let operatorHours = totalTimesheetTime - props.timesheetData.offHours;
    operatorHours = operatorHours > 0 ? operatorHours.toFixed(2) : 0;

    //Calculate total job time
    let totalJobTime = 0;

    if (props.jobData.length > 0)
        totalJobTime = (props.jobData.map((job) => {
            if (job.timeIn < 9999999999) job.timeIn *= 1000
            if (job.timeOut < 9999999999) job.timeOut *= 1000
            return job.timeOut - job.timeIn
        }).reduce((total, time) => total + time) / 3600000).toFixed(2);

    totalJobTime = totalJobTime > 0 ? totalJobTime : 0;

    //Calculate expanded time per job
    let expandedTimePerJob = 0;
    expandedTimePerJob = ((operatorHours / 3600000) - totalJobTime) / (props.jobData.length);
    expandedTimePerJob = expandedTimePerJob > 0 ? expandedTimePerJob : 0;

    //Add expanded to job total
    //expandedTimePerJob > 0 ? totalJobTime = totalJobTime + expandedTimePerJob : totalJobTime = totalJobTime + 0

    let hasNotes = props.timesheetData.notes !== "" || props.timesheetNotes.length > 0;


    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */
    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';
        let noteWidth = (100 / props.columns.length) * (props.columns.length - 1) + "%";


        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>

                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 10 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];
                             
                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={column.field === 'adminTime' ? styles.dTableCellBoldRedSmall : column.field === 'customer' ?  styles.dTableCellCustomer : column.field === 'jobNumber' ? styles.dTableCellJobNum : styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>


                            {'checkedItems' in data
                                ? <View wrap={false} style={{ ...styles.dTableRow, borderTop: '1 solid black', borderBottom: index + 1 === props.data.length ? ' ' : '1 solid black' }} key={index + 'notes'}>
                                    <View style={{ ...styles.dTableCol, width: columnWidth, backgroundColor: '#ddd' }}>
                                        <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Checked Items</Text>
                                    </View>
                                    <View style={{ ...styles.dTableCol, width: noteWidth, marginLeft: 2.5 }}>
                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>
                                            {getChecklistType(data).map(checklist => {
                                                return checklist[0]
                                                    ? "[X] " + checklist[1] + '\n'
                                                    : "[   ] " + checklist[1] + '\n'
                                            })}
                                        </Text>
                                    </View>
                                </View>
                                : null}


                            {'notes' in data && data.notes !== ""
                                ? <View wrap={false} style={{ ...styles.dTableRow, borderTop: '1 solid black', borderBottom: index + 1 === props.data.length ? ' ' : '1 solid black' }} key={index + 'notes'}>
                                    <View style={{ ...styles.dTableCol, width: columnWidth, backgroundColor: '#ddd' }}>
                                        <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Notes</Text>
                                    </View>
                                    <View style={{ ...styles.dTableCol, width: noteWidth, marginLeft: 2.5 }}>
                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>{data.notes}</Text>
                                    </View>
                                </View>
                                : null}
                            {'adminNotes' in data && data.adminNotes !== ""
                                ? <View wrap={false} style={{ ...styles.dTableRow, borderTop: '1 solid black', borderBottom: index + 1 === props.data.length ? ' ' : '1 solid black' }} key={index + 'notes'}>
                                    <View style={{ ...styles.dTableCol, width: columnWidth, backgroundColor: '#ddd' }}>
                                        <Text style={{ ...styles.dTableCell, justifyContent: 'center' }}>Admin Notes</Text>
                                    </View>
                                    <View style={{ ...styles.dTableCol, width: noteWidth, marginLeft: 2.5 }}>
                                        <Text style={{ ...styles.dTableCell, width: '100%' }}>{data.adminNotes}</Text>
                                    </View>
                                </View>
                                : null}
                        </View>
                    )
                })}
            </View>
        )
    }

    let jobData = props.jobData.sort((a, b) => (a.timeIn > b.timeIn) ? 1 : ((b.timeIn > a.timeIn) ? -1 : 0));
    let costData = props.timesheetData?.additionalCosts ?? [];
    let checklistData = props.checklistData.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))

    return (
        <div className={classes.root}>
            <div className={classes.content}>

                <div className={classes.buttonRow}>
                    <Button variant='contained' onClick={(e) => {props.history.goBack()}} startIcon={<ArrowBackIcon/>}>Back</Button>
                </div>
                <div className={classes.checkRow}>
                    <FormControlLabel
                        control={<Checkbox checked={showJobs} onChange={() => setShowJobs(!showJobs)} />}
                        label="Show Jobs" />
                    <FormControlLabel
                        control={<Checkbox checked={showCosts} onChange={() => setShowCosts(!showCosts)} />}
                        label="Show Costs" />
                    <FormControlLabel
                        control={<Checkbox checked={showChecklists} onChange={() => setShowChecklists(!showChecklists)} />}
                        label="Show Checklists" />
                    <FormControlLabel
                        control={<Checkbox checked={showDeliveries} onChange={() => setShowDeliveries(!showDeliveries)} />}
                        label="Show Deliveries" />
                    <FormControlLabel
                        control={<Checkbox checked={showUnits} onChange={() => setShowUnits(!showUnits)} />}
                        label="Show Units" />
                    <FormControlLabel
                        control={<Checkbox checked={showVehicleInspection} onChange={() => setShowVehicleInspection(!showVehicleInspection)} />}
                        label="Show Vehicle Inspection" />
                </div>

                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>

                            <View style={styles.section}>
                                <View style={styles.tableRow}>
                                    <Text>Timesheet {props.timesheetData?.timesheetID} - {props.userData.displayName}</Text>
   
                                    
                                    <Image source={ {uri: props.QRCODE} } style={{width: '64px', height:'64px'}} />
                                    
                                </View>
                       
                            </View>

                            <View style={styles.summary} wrap={false}>
                                <View style={{ ...styles.table, marginRight: 5 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Date</Text>
                                        <Text style={styles.tableTextBoldRed}>{moment.utc(props.timesheetData.date).format('YYYY-MM-DD')}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Type</Text>
                                        <Text style={styles.tableTextBold}>{props.timesheetData.timesheetType || props.timesheetData.timesheetType === "" ? props.timesheetData.timesheetType : "NA"}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>{`In Time   ` /* some spaces for good measure! :) */}</Text>

                                        <Text style={styles.tableText}>{moment(props.timesheetData.usingGateInTime ? props.timesheetData.gateInTime : props.timesheetData.inTime).format('YYYY-MM-DD')} </Text>
                                        <Text style={styles.tableTextBold}>{moment(props.timesheetData.usingGateInTime ? props.timesheetData.gateInTime : props.timesheetData.inTime).format('h:mm a')}</Text>

                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Out Time</Text>

                                        <Text style={styles.tableText}>{moment(props.timesheetData.usingGateOutTime ? props.timesheetData.gateOutTime : props.timesheetData.outTime).format('YYYY-MM-DD')} </Text>
                                        <Text style={styles.tableTextBold}>{moment(props.timesheetData.usingGateOutTime ? props.timesheetData.gateOutTime : props.timesheetData.outTime).format('h:mm a')}</Text>

                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Off Hours</Text>
                                        <Text style={styles.tableText}>{props.timesheetData.offHours}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Skipped Lunch</Text>
                                        <Text style={styles.tableText}>{props.timesheetData.skipLunch === "na" ? <Text>took lunch</Text> : props.timesheetData.skipLunch}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Timesheet Status</Text>
                                        <Text style={styles.tableText}>{props.timesheetData.timesheetStatus}</Text>
                                    </View>
                                </View>

                                <View style={{ ...styles.table, marginLeft: 5 }}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Timesheet Hours</Text>
                                        <Text style={styles.tableTextBoldRed}>{(((totalTimesheetTime / 3600000).toFixed(2)) - (props.timesheetData.offHours)).toFixed(2)}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Operator Hours</Text>
                                        <Text style={styles.tableText}>{(((operatorHours / 3600000).toFixed(2)) - (props.timesheetData.offHours)).toFixed(2)}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Job Time</Text>
                                        <Text style={styles.tableText}>{totalJobTime}</Text>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Expanded Time</Text>
                                        <Text style={styles.tableText}>{props.expandedTime > 0 ? Number(props.expandedTime).toFixed(2) : (0).toFixed(2)}</Text>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Admin Time</Text>
                                        <Text style={styles.tableText}>{props.adminTime > 0 ? Number(props.adminTime).toFixed(2) : (0).toFixed(2)}</Text>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Total Job Time</Text>
                                        <Text style={styles.tableText}>{
                                            props.timesheetData.submitted
                                                ? (Number(totalJobTime) + Number(props.expandedTime ? props.expandedTime : expandedTimePerJob)).toFixed(2)
                                                : ''}
                                        </Text>
                                    </View>

                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Number of Jobs</Text>
                                        <Text style={styles.tableText}>{props.jobData.length}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Number of Deliveries</Text>
                                        <Text style={styles.tableText}>{props.deliveryData.length}</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableText}>Units in Timesheet</Text>
                                        <Text style={styles.tableText}>{Array.from(new Set(props.unitData.map(unit => unit.unitNumber))).join(', ')}</Text>
                                    </View>
                                </View>
                            </View>

                            {hasNotes
                                ? <View style={styles.summary} wrap={false}>
                                    <View style={{ ...styles.table }}>
                                        
                                        <View style={{...styles.tableRow}}>
                                            <Text style={{ ...styles.tableText}}>Timesheet Notes</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={{ ...styles.tableText, fontSize: 10 }}>{props.timesheetData.notes}</Text>
                                        </View>
                                        {props.timesheetNotes.length > 0 && props.timesheetNotes.map(note => {
                                            return <View style={{...styles.tableRow, backgroundColor: '#fff'  }}>
                                                <Text style={{ ...styles.tableTextBoldRed, fontSize: 12 }}>{note.note}</Text>
                                            </View>
                                        })}
                                    </View>
                                </View>
                                : null}

                            {showCosts && costData.length > 0
                                ? <Table
                                    columns={[
                                        { title: 'Name', field: 'name' },
                                        { title: 'Job', field: 'job' },
                                        { title: 'Address', field: 'address' },
                                        { title: 'Cost', field: 'cost', render: rowData => '$' + rowData.cost },
                                    ]}
                                    data={costData}
                                    title={'Additional Costs'} />
                                : null}

                            {showJobs && props.jobData.length > 0
                                ? <Table
                                    columns={[
                                        { title: 'Customer', field: 'customer' },
                                        { title: 'Job #', field: 'jobNumber' },
                                        { title: 'Address', field: 'address' },
                                        { title: 'Time In', render: rowData => moment(rowData.timeIn).format('YYYY-MM-DD hh:mm') },
                                        { title: 'Time Out', render: rowData => moment(rowData.timeOut).format('YYYY-MM-DD hh:mm') },
                                        { title: 'Hours', render: rowData => { return ((rowData.timeOut - rowData.timeIn) / 3600000).toFixed(2) } },
                                        { title: 'Expand', render: rowData => { return (expandedTimePerJob).toFixed(2) } },
                                        {
                                            title: 'Hours', render: rowData => {
                                                if (rowData.timeIn < 9999999999) rowData.timeIn *= 1000
                                                if (rowData.timeOut < 9999999999) rowData.timeOut *= 1000
                                                return props.timesheetData.submitted ? (Number((rowData.timeOut - rowData.timeIn) / 3600000) + Number(rowData.expandedTime ? rowData.expandedTime : expandedTimePerJob)).toFixed(2) : ''
                                            }
                                        },
                                        /*
                                            title: 'Units', render: rowData => {
                                                return (
                                                    <View>
                                                        {rowData.units.map(jobUnit => props.unitData.find(unit => unit.docId === jobUnit)).map(unit => {
                                                            return <Text>{unit?.unitNumber}: {unit?.jobTimes && unit?.jobTimes.filter((time)=> time.jobId === rowData.docId).length !==0 
                                                                ? 
                                                                Number(unit?.jobTimes.filter((time)=> time.jobId === rowData.docId)[0].hours).toFixed(2) 
                                                                : 
                                                                ((rowData.timeOut - rowData.timeIn) / 3600000).toFixed(2)}
                                                                </Text>
                                                        }
                                                        )}
                                                    </View>
                                                )
                                                
                                            }
                                        */
                                        { title: 'Units', render: rowData => {
                                            let container = [];
                                            rowData.units.forEach((jobUnit) => {
                                                let item = props.unitData.find(unit => unit.docId === jobUnit)
                                                if(!_.isUndefined(item)){ container.push(item) }
                                            })
                                            let setContainer = ([...new Set(container)])
                                            
                                            return (
                                                <View>
                                                    {setContainer.map(unit => {
                                                        return (
                                                        <Text style={{color:'#006400',  fontWeight:'bold', fontFamily: 'Helvetica-Bold'}} >
                                                            {unit?.unitNumber}: 
                                                            {
                                                                unit?.jobTimes && unit?.jobTimes.filter((time)=> time.jobId === rowData.docId).length !==0 
                                                                ? 
                                                                Number(unit?.jobTimes.filter((time)=> time.jobId === rowData.docId)[0].adminHours).toFixed(2)+'\n'
                                                                : 
                                                                ((rowData.timeOut - rowData.timeIn) / 3600000).toFixed(2)+'\n'
                                                            }
                                                        </Text>
                                                        )

                                                    })}
                                                </View>
                                            )
                                            
                                        } },
                                        { title: 'Billed Time', field: 'adminTime', render: rowData => { return rowData.adminTime ? rowData.adminTime : 0 } },
                                    ]}
                                    data={jobData}
                                    title={'Job Details'} />
                                : null}

                            {showChecklists && props.checklistData.length > 0
                                ? <Table
                                    columns={[
                                        { title: 'Customer', render: rowData => props.jobData.filter(job => job.docId === rowData.jobID)[0].customer },
                                        { title: 'Service Type', field: 'serviceType' },
                                        { title: 'Reason', field: 'serviceReason' },
                                        { title: 'Equipment', render: rowData => Object.values(rowData.equipmentType).join("\n") },
                                        { title: 'Weather', render: rowData => rowData.current?.condition?.text },
                                        {
                                            title: 'Date', render: rowData => {
                                                if (rowData.date < 9999999999) rowData.date *= 1000
                                                return moment(rowData.date).format('YYYY-MM-DD h:mm a')
                                            }
                                        },
                                    ]}
                                    data={checklistData}
                                    title={'Checklist Details'} />
                                : null}

                            {showDeliveries && props.deliveryData.length > 0
                                ? <Table
                                    columns={[
                                        { title: 'Ticket #', field: 'ticket' },
                                        {
                                            title: 'Units', render: (rowData) => {
                                                let unitNumbers = []
                                                rowData?.units.forEach(unit => {
                                                    unitNumbers.push(props.unitData.filter(unitData => unitData.docId === unit)[0]?.unitNumber)
                                                })
                                                return unitNumbers;
                                            }
                                        },
                                        { title: 'Customer', field: 'customer' },
                                        { title: 'From', field: 'from' },
                                        { title: 'To', field: 'to' },
                                        { title: 'Material', field: 'material' },
                                        { title: 'Quantity', field: 'quantity' },
                                    ]}
                                    data={props.deliveryData}
                                    title={"Delivery Details"} />
                                : null}

                            {showUnits && props.unitData.length > 0
                                ? <Table
                                    columns={[
                                        { title: 'Unit Number', field: 'unitNumber' },
                                        { title: 'Trailer ID', field: 'trailerID' },
                                        { title: 'Greased', render: rowData => rowData.greased ? "Yes" : "No" },
                                        { title: 'Mileage In', render: rowData => Math.round(rowData.mileageIn) },
                                        { title: 'Mileage Out', render: rowData =>  Math.round(rowData.mileageOut) },
                                        { title: 'Fuel Added', field: 'fuelAdded' },
                                        { title: 'Net Mileage', render: rowData => Math.round(rowData.mileageOut - rowData.mileageIn) },
                                        { title: 'Defects', render: rowData => rowData.defectID !== "" ? "Yes" : "No" },
                                        { title: 'Billed Time', field: 'adminTime' },
                                    ]}
                                    data={props.unitData}
                                    title={"Unit Details"} />
                                : null}

                        </Page>
                        {showVehicleInspection
                            ? props.unitData.map((unit, index) => {
                                return (
                                    <Page size="A4" style={styles.page} wrap key={'page' + index}>
                                        <View style={styles.dTable} wrap={false} key={'vehicleview' + index}>
                                            <Text style={styles.dTableRow}>Vehicle Inspection Report</Text>


                                            <View style={styles.dTableRow}>
                                                <View style={{ ...styles.dTableCol, width: "5%" }}>
                                                    <Text style={styles.dTableCell}>Key</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                    <Text style={styles.dTableCell}>Defect</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                    <Text style={styles.dTableCell}>No Defect</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                    <Text style={{ ...styles.dTableCell, width: '100%' }}>Item</Text>
                                                </View>
                                            </View>

                                            <View style={{ backgroundColor: 'black', height: 12 }} />

                                            {unit.preTripItems.map((item, index) => {
                                                return (
                                                    <React.Fragment key={'fragment' + index}>
                                                        <View style={styles.dTableRow} key={'reportrow' + index}>
                                                            <View style={{ ...styles.dTableCol, width: "5%" }}>
                                                                <Text style={styles.dTableCell}>{item.key}</Text>
                                                            </View>
                                                            <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                                <Text style={styles.dTableCell}>{item.noDefect ? "" : "X"}</Text>
                                                            </View>
                                                            <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                                <Text style={styles.dTableCell}>{item.noDefect ? "X" : ""}</Text>
                                                            </View>
                                                            <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                                <Text style={{ ...styles.dTableCell, width: '100%' }}>{item.item}</Text>
                                                            </View>
                                                        </View>

                                                        {item.key === 'J'
                                                            ? <View style={{ backgroundColor: 'black', height: 12 }} key='blackbar' />
                                                            : null}
                                                    </React.Fragment>
                                                )
                                            })}
                                            <View style={{ ...styles.dTableRow, borderTop: '1 solid black' }}>
                                                <View style={{ ...styles.dTableCol, width: "50%" }}>
                                                    <Text style={{ ...styles.dTableCell }}>Unit#: {unit.unitNumber}</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "50%" }}>
                                                    <Text style={styles.dTableCell}>TrailerID: {unit.trailerID}</Text>
                                                </View>
                                            </View>

                                            <View style={{ ...styles.dTableRow, borderTop: '1 solid black' }}>
                                                <View style={{ ...styles.dTableCol, width: "100%" }}>
                                                    <Text style={styles.dTableCell}>{unit.defectID !== "" ? "Defects Reported" : "Vehicle Condition Satisfactory"}</Text>
                                                </View>
                                            </View>

                                            <View style={{ ...styles.dTableRow, borderTop: '1 solid black' }}>
                                                <View style={{ ...styles.dTableCol, width: "50%" }}>
                                                    <Text style={styles.dTableCell}>Date: {moment(unit.created.seconds * 1000).format('YYYY-MM-DD hh:mm:ss a')}</Text>
                                                </View>
                                                <View style={{ ...styles.dTableCol, width: "50%" }}>
                                                    <Text style={styles.dTableCell}>Driver: {props.userData.displayName}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </Page>
                                )
                            })
                            : null}
                    </Document>
                </PDFViewer>
            </div>
        </div >
    )
};

export default withRouter(TimesheetPDF);