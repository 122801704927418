/* eslint-disable no-unused-vars */
import React, { useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Button, Divider, TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import _ from 'lodash';
import { DateTimePicker } from '../../../UI/DatePicker/DatePicker';
import { useCustomer } from '../../../../../providers/CustomerContext';

const JobModal = (props) => {
    const [open, setOpen] = useState(false)
    const [customer, setCustomer] = useState(props.currentJob.customer)     
    const [newCustomer, setNewCustomer] = useState(false)
    const { customers, getCustomers } = useCustomer();
    const [selectedJob, setSelectedJob] = useState({})
    const [unitData, setUnitData] = useState()

    useEffect(() => {
        getCustomers();
        // Check if props.currentJob is not empty before setting selectedJob
        if (Object.keys(props.currentJob).length !== 0) {
            setSelectedJob(props.currentJob);
        }

        //eslint-disable-next-line
    }, [props.currentJob]); // Add props.currentJob as a dependency

    useEffect(() => {
        setUnitData(props.unitData)
    }, [props.unitData])

    const handleChangeJob = (e) => {
        const { checked, name } = e.target;
        const updatedJob = { ...selectedJob };
        const updatedUnitData = unitData.map((u) => ({ ...u }));

        //console.log(e.target)
      
        // Check if the unit exists before performing any operations
        const unitExists = updatedUnitData.some((unit) => unit.docId === name);
        // if units array doesnt exist make it
        if (!updatedJob.units) {
            updatedJob.units = [];
        }
        if (checked && unitExists) {
          if (!updatedJob.units.includes(name)) {
            updatedJob.units.push(name);
            props.setCurrentJob(updatedJob);
      
            updatedUnitData.forEach((unit) => {
              if (unit.docId === name) {
                if (!unit.jobTimes) {
                    unit.jobTimes = [{ jobId: selectedJob.docId, hours: '0' }];
                } else {
                    const existingJobIndex = unit.jobTimes.findIndex((jobTime) => jobTime.jobId === selectedJob.docId);
                    if (existingJobIndex === -1) {
                        unit.jobTimes.push({ jobId: selectedJob.docId, hours: '0' });
                    }
                }
              }
            });

            setUnitData(updatedUnitData)
            // updatedUnitData.forEach((unit) => {
            //   props.updateUnit(unit);
            // });

          }
        } else if (!checked && unitExists) {
          const jobIndex = updatedJob.units.indexOf(name);
          if (jobIndex !== -1) {
            updatedJob.units.splice(jobIndex, 1);
            props.setCurrentJob(updatedJob);
      
            // console.log('jobModal line 73', updatedUnitData)
            updatedUnitData.forEach((unit) => {
              if (unit.docId === name && unit.jobTimes) {
                unit.jobTimes = unit.jobTimes.filter((jobTime) => jobTime.jobId !== selectedJob.docId);
              }
            //   props.updateUnit(unit);
            });

            setUnitData(updatedUnitData)
          }
        }
    };

    const handleSelectAllJob = (e) => {
        if(e.target.checked){
            let unitDocIds = props.unitData.map((unit) => {return unit.docId})
            props.setCurrentJob({...selectedJob, units: unitDocIds });
        }
        else{
            props.setCurrentJob({...selectedJob, units: [] });
        }
    }
 
    return (
        <Grid container>
            <Grid container style={{ position: 'relative', maxHeight: '600px', overflow: 'auto', width: '100%', padding: '20px' }}>
            {!newCustomer ?
            <Grid container spacing={2} >
                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>Customer: </Typography>
                </Grid>

                <Grid item xs={5}>
                    <Autocomplete
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        id="autocomplete"
                        inputValue={props.jobCustomerAutocomplete}
                        clearOnBlur={false}
                        value={null}
                        onInputChange={(event, newValue) => { props.setJobCustomerAutocomplete(newValue); }}
                        onChange={(event, newValue) => { 
                            newValue != null &&
                            props.setCurrentJob({...selectedJob, customer: newValue.name, address: newValue.streetNumber + ' ' + newValue.streetName, jobNumber: newValue.jobNumber}) 
                        }}
                        options={props.customers}
                        getOptionSelected={(option, value) => option?.name === value?.name}
                        getOptionLabel={(option) => option.name + ' ' + option.streetNumber + ' ' + option.streetName + ' ' + option.jobNumber}
                 
                        renderOption={(option) =>( 
                            customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                return(
                                    <Grid key={option.docId} >
                                        <Typography>{customerObj.name} </Typography>
                                        <Typography><small style={{fontSize:'12px'}}>{customerObj.jobNumber && ''+customerObj.jobNumber+''}</small></Typography>
                                        <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                    </Grid>
                                )
                            })
                        )}
                        //allow me to search by job number, name or streetNumber and streetName

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ maxWidth: '300px', width: '100%' }}
                                size='small'
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <div>
                                            {params.InputProps.endAdornment}
                                        </div>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Button 
                        size="small"
                        variant='contained'
                        onClick={() => 
                            {
                                props.setCurrentJob({...selectedJob, customer: '', address: '', jobNumber: ''})
                                setNewCustomer(true)
                            }}
                    >
                        New
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Customer Name: 
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={selectedJob.customer ? selectedJob.customer : ''} 
                        onChange={(e) => { setSelectedJob({...selectedJob, customer: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Address: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={selectedJob.address ? selectedJob.address : ''} 
                        onChange={(e) => { setSelectedJob({...selectedJob, address: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5} >
                    <Typography style={{marginTop: '8px'}}>
                        Job Number: 
                    </Typography>
                </Grid>
                <Grid item xs={7} >
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={selectedJob.jobNumber ? selectedJob.jobNumber : ''} 
                        onChange={(e) => { setSelectedJob({...selectedJob, jobNumber: e.target.value}) }}
                    />
                </Grid>
            </Grid>
            
            :
            <Grid container spacing={2}  >
                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Customer Name: 
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={customer?.name ? customer?.name : ''} 
                        onChange={(e) => { setCustomer({...customer, name: e.target.value});
                        setSelectedJob({...selectedJob, customer: e.target.value}) }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button 
                        size="small"
                        variant='contained'
                        onClick={() =>{
                            setSelectedJob({...selectedJob, customer: '', address: '', jobNumber: ''})
                            setNewCustomer(false)
                            }}
                    >
                        Existing
                    </Button>
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Street Name: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={customer?.streetName ? customer?.streetName : ''} 
                        onChange={(e) => { setCustomer({...customer, streetName: e.target.value});
                        setSelectedJob({...selectedJob, address: customer.streetNumber? customer.streetNumber + ' ' + e.target.value: ' ' + e.target.value }) }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Street Number: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={customer?.streetNumber ? customer?.streetNumber : ''} 
                        onChange={(e) => { setCustomer({...customer, streetNumber: e.target.value});
                        setSelectedJob({...selectedJob, address: customer.streetName? e.target.value + ' ' + customer.streetName: e.target.value + ' ' })  }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        City: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={customer?.city ? customer?.city : ''} 
                        onChange={(e) => { setCustomer({...customer, city: e.target.value}); }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Postal Code: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={customer?.postalCode ? customer?.postalCode : ''} 
                        onChange={(e) => { setCustomer({...customer, postalCode: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5} >
                    <Typography style={{marginTop: '8px'}}>
                        Province: 
                    </Typography>
                </Grid>
                <Grid item xs={7} >
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={customer?.province ? customer?.province : ''} 
                        onChange={(e) => { setCustomer({...customer, province: e.target.value}) }}
                    />
                </Grid>

                <Grid item xs={5} >
                    <Typography style={{marginTop: '8px'}}>
                        Job Number: 
                    </Typography>
                </Grid>
                <Grid item xs={7} >
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        value={customer?.jobNumber ? customer?.jobNumber : ''} 
                        onChange={(e) => { setCustomer({...customer, jobNumber: e.target.value});
                        setSelectedJob({...selectedJob, jobNumber: e.target.value}) }}
                    />
                </Grid>
                           
            </Grid>
            }
            
            <Grid container spacing={2}>
                <Grid item xs={5}>
                
                    <Typography style={{marginTop: '8px'}}>
                        Time In: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <DateTimePicker 
                        value={selectedJob?.timeIn < 9999999999 ? moment(selectedJob?.timeIn * 1000) : moment(selectedJob?.timeIn)} 
                        onChange={(date) => { 
                            setSelectedJob({...selectedJob, timeIn: date.valueOf()}) 
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Time Out: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                <DateTimePicker 
                        value={selectedJob?.timeOut < 9999999999 ? moment(selectedJob?.timeOut * 1000) : moment(selectedJob?.timeOut)} 
                        onChange={(date) => { 
                            setSelectedJob({...selectedJob, timeOut: date.valueOf()}) 
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <Typography style={{marginTop: '8px'}}>
                        Billed Time: 
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                <TextField 
                variant="outlined" 
                size="small" 
                value={selectedJob?.adminTime ? selectedJob?.adminTime : ''} 
                onChange={(e) => { 
                    // Store the raw input value while the user is typing
                    setSelectedJob({ ...selectedJob, adminTime: e.target.value });
                }} 
                onBlur={() => {
                    // Round up when user finishes typing and leaves the field
                    let inputValue = parseFloat(selectedJob?.adminTime);

                    if (!isNaN(inputValue)) {
                        let roundedValue = Math.ceil(inputValue * 4) / 4;
                        let formattedValue = roundedValue.toFixed(2); // Ensure two decimal places

                        setSelectedJob({ ...selectedJob, adminTime: formattedValue });

                        let container = _.cloneDeep(unitData);
                        container.filter(unit => selectedJob.units.includes(unit?.docId)).forEach((unit) => { 
                            if (unit.jobTimes) {
                                let existingJobTime = unit.jobTimes.find(x => x.jobId === selectedJob.docId);

                                if (!_.isUndefined(existingJobTime)) {
                                    let index = unit.jobTimes.findIndex(x => x.jobId === selectedJob.docId);
                                    unit.jobTimes[index].adminHours = formattedValue;
                                    unit.jobTimes[index].hours = selectedJob.jobTime ? selectedJob.jobTime.toString() : "0";
                                } else {
                                    unit.jobTimes.push({ 
                                        jobId: selectedJob.docId, 
                                        adminHours: formattedValue, 
                                        hours: selectedJob.jobTime ? selectedJob.jobTime.toString() : "0" 
                                    });
                                }
                            } else {
                                unit.jobTimes = [];
                                unit.jobTimes.push({ 
                                    jobId: selectedJob.docId, 
                                    adminHours: formattedValue, 
                                    hours: selectedJob.jobTime ? selectedJob.jobTime.toString() : "0" 
                                });
                            }
                        });

                        console.log('container', container);
                        setUnitData(container);
                    }
                }} 
                />

                </Grid>
            </Grid>
            

            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/>
            {!props.jobModalOpenInEdit ? null :
            <Grid container>
                <Grid item xs={6} style={{marginTop: '10px'}}>
                    <Typography>Add units To Job</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                            label={"Select All Units"}
                            control={
                                <Checkbox 
                                    checked={selectedJob.units ? _.isEqual(props.unitData.map((unit) => {return unit.docId}).sort(), selectedJob.units.sort()) : false} 
                                    onChange={(e) => { handleSelectAllJob(e) }}
                                />
                            }
                        />
                </Grid>
            </Grid>}

            <Divider style={{marginTop: '10px', marginBottom:'10px'}}/> 

            { props.unitData && props.jobModalOpenInEdit &&
                props.unitData.map((unit) => {
                    return (
                        <FormControlLabel
                            key={unit.docId}
                            style={{margin: '5px'}}
                            control={
                                <Checkbox 
                                    checked={selectedJob.units ? selectedJob.units.includes(unit.docId) : false} 
                                    name={unit.docId} />}
                                    label={unit.unitNumber}
                                    onChange={(e) => { handleChangeJob(e) }} 
                                />
                        
                    )
                })
            }

            <Divider style={{marginTop: '10px', marginBottom:'20px'}}/>

            <Grid container spacing={2}>
            { selectedJob.units && props.jobModalOpenInEdit && unitData.filter(unit=> selectedJob.units.includes(unit?.docId)).map((unit, unitIndex) => {
                    return (
                        <Grid container key={unitIndex}>
                            <Grid item xs={3} style={{marginTop:'10px'}}>
                                <Typography>Unit #<b>{unit.unitNumber}</b> Time: </Typography>
                            </Grid>
                            <Grid item xs={5}>
                            {props.jobData.filter(job => selectedJob.units.includes(unit.docId) && selectedJob.docId === job.docId).map((job, jobIndex) => {
                                return (
                                    <Grid item xs={12} key={jobIndex} style={{ marginBottom: '10px', marginLeft:'5px'}}>
                                        
                                        <TextField 
                                            size="small" 
                                            placeholder="0.00" 
                                            variant="outlined"
                                            value={unit?.jobTimes?.filter(time => time.jobId === job.docId)[0]?.hours ? 
                                                unit.jobTimes.filter(time => time.jobId === job.docId)[0]?.hours : job.jobTime ? job.jobTime.toString() : '0'} 

                                                onChange={(e) => {

                                                    let container = _.cloneDeep(unitData)
                                                    let indexContainer = unitData.findIndex(x=> x.docId === unit.docId)
                                                    let foundJobTimes = _.cloneDeep(container[indexContainer]?.jobTimes?.filter(x=> x.jobId === job.docId))

                                                    if(foundJobTimes && foundJobTimes.length > 1){ 
                                                        let data = _.cloneDeep(foundJobTimes[0])
                                                        //let removeContainer = foundJobTimes.slice(1, foundJobTimes.length)
                                                        
                                                        //console.log('removeContainer', removeContainer)

                                                        //remove all that dont belong, should need this in future but for now...
                                                        // removeContainer.forEach((jobTime) => {
                                                        //     container[indexContainer].jobTimes.splice(container[indexContainer].jobTimes.findIndex(x=> x.jobId === jobTime.jobId), 1)
                                                        // })
                                
                                                        data.hours = e.target.value
                                                        container[indexContainer].jobTimes.splice( container[indexContainer].jobTimes.findIndex(x=> x.jobId === data.jobId), 1, data)

                                                        setUnitData([...container])
                                                    }else if(foundJobTimes && foundJobTimes.length === 1){
                                                        let data = _.cloneDeep(foundJobTimes[0])

                                                        data.hours = e.target.value
                                                        container[indexContainer].jobTimes.splice( container[indexContainer].jobTimes.findIndex(x=> x.jobId === data.jobId), 1, data)

                                                        setUnitData([...container])
                                                    }else{  //jobTimes doesnt exist
                                                        let data = {
                                                            hours: e.target.value,
                                                            jobId: selectedJob.docId,
                                                            adminHours: 0,
                                                        }
                                                        
                                                        let jobTimes = container[indexContainer]?.jobTimes ? [...container[indexContainer]?.jobTimes ] : []

                                                        container[indexContainer].jobTimes = [...jobTimes ,data]

                                                        //container[indexContainer].jobTimes.splice( container[indexContainer].jobTimes.findIndex(x=> x.jobId === data.jobId), 1, data)
                                                        setUnitData([...container])
                                                    }


                                                    // if (!container.jobTimes) {
                                                    //     container.jobTimes = [];
                                                    //     //find any jobTimes with the same docId and delete them
                                                    //     container.jobTimes = unit.jobTimes.filter(time => time.jobId !== selectedJob.docId);
                                                    //     container.jobTimes.push({ jobId: selectedJob.docId, hours: e.target.value });
                                                    //     console.log('container', container)

                                                    //     setUnitData(props.unitData.map((u) => ({ ...u })))    
                                                        
                                                    // } else if (!container.jobTimes.filter(time => time.jobId === selectedJob.docId)[0]) {
                                                    //     container.jobTimes = unit.jobTimes.filter(time => time.jobId !== selectedJob.docId);
                                                    //     container.jobTimes.push({ jobId: selectedJob.docId, hours: e.target.value });
                                                    //     console.log('container', container)

                                                    //     setUnitData(props.unitData.map((u) => ({ ...u })))    

                                                    // } else {
                                                    //     container.jobTimes.find(time => time.jobId === selectedJob.docId).hours = e.target.value;
                                                    //     console.log('container', container)
                                                        
                                                    //     setUnitData(props.unitData.map((u) => ({ ...u })))    
                                                    //     //props.updateUnit(unit);
                                                    // }
                                                }}
                                        />
                                    </Grid>
                                )
                            })
                            }
                            </Grid>
                            
                            <Grid item xs={4}>
                            {props.jobData.filter(job2 => selectedJob.units.includes(unit.docId) && selectedJob.docId === job2.docId).map((job2, jobIndex2) => {
                                    return (
                                        <Grid item xs={12} key={jobIndex2} style={{ marginBottom: '10px', marginLeft:'5px'}}>
                                            
                                            <TextField 
                                                size="small" 
                                                label="Billed Time"
                                                placeholder="0.00" 
                                                variant="outlined"
                                                value={unit?.jobTimes?.filter(time => time.jobId === job2.docId)[0]?.adminHours ? 
                                                    unit.jobTimes.filter(time => time.jobId === job2.docId)[0]?.adminHours : ''} 

                                                    onChange={(e) => {

                                                        let container = _.cloneDeep(unitData)
                                                        let indexContainer = unitData.findIndex(x=> x.docId === unit.docId)
                                                        let foundJobTimes = container[indexContainer]?.jobTimes?.filter(x=> x.jobId === job2.docId)

                                                        if(foundJobTimes && foundJobTimes.length > 1){ 
                                                            let data = _.cloneDeep(foundJobTimes[0])
                                                            //let removeContainer = foundJobTimes.slice(1, foundJobTimes.length)
                                                        

                                                            //remove all that dont belong, should need this in future but for now...
                                                            // removeContainer.forEach((jobTime) => {
                                                            //     container[indexContainer].jobTimes.splice(container[indexContainer].jobTimes.findIndex(x=> x.jobId === jobTime.jobId), 1)
                                                            // })
                                    
                                                            data.adminHours = e.target.value
                                                            container[indexContainer].jobTimes.splice( container[indexContainer].jobTimes.findIndex(x=> x.jobId === data.jobId), 1, data)

                                                            setUnitData([...container])
                                                        }else if(foundJobTimes && foundJobTimes.length === 1){

                                                            let data = _.cloneDeep(foundJobTimes[0])
                                                            data.adminHours = e.target.value
                                                            container[indexContainer].jobTimes.splice( container[indexContainer].jobTimes.findIndex(x=> x.jobId === data.jobId), 1, data)

                                                            setUnitData([...container])
                                                        }else{  //jobTimes doesnt exist
                                                            let data = {
                                                                adminHours: e.target.value,
                                                                jobId: selectedJob.docId,
                                                                hours: job2.jobTime ? job2.jobTime.toString() : 0
                                                            }

                                                            let jobTimes = container[indexContainer]?.jobTimes ? [...container[indexContainer]?.jobTimes ] : []

                                                            container[indexContainer].jobTimes = [...jobTimes ,data]
                                                            //container[indexContainer].jobTimes.splice( container[indexContainer].jobTimes.findIndex(x=> x.jobId === data.jobId), 1, data)
                                                            setUnitData([...container])
                                                        }


                                                        // if (!unit.jobTimes) {
                                                        //     unit.jobTimes = [];
                                                        //     //find any jobTimes with the same docId and delete them
                                                        //     unit.jobTimes = unit.jobTimes.filter(time => time.jobId !== selectedJob.docId);
                                                        //     unit.jobTimes.push({ jobId: selectedJob.docId, adminHours: e.target.value, hours: 
                                                        //         //if there is a jobTime for this job, use that, otherwise use 0
                                                        //         job2.jobTime ? job2.jobTime.toString() : 0  });
                                                        //     setUnitData(props.unitData.map((u) => ({ ...u })))    
                                                            
                                                        // } else if (!unit.jobTimes.filter(time => time.jobId === selectedJob.docId)[0]) {
                                                        //     unit.jobTimes = unit.jobTimes.filter(time => time.jobId !== selectedJob.docId);
                                                        //     unit.jobTimes.push({ jobId: selectedJob.docId, adminHours: e.target.value, hours: 
                                                        //         //if there is a jobTime for this job, use that, otherwise use 0
                                                        //         job2.jobTime ? job2.jobTime.toString() :
                                                        //         0 });
                                                        //     setUnitData(props.unitData.map((u) => ({ ...u })))    
                                                        // } else {
                                                        //     unit.jobTimes.find(time => time.jobId === selectedJob.docId).adminHours = e.target.value;
                                                        //     setUnitData(props.unitData.map((u) => ({ ...u })))    
                                                        // }
                                                    }}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            </Grid>
                        </Grid>
                            
                        
                    )
                })
            }


            </Grid>
        </Grid>

        <Grid container justifyContent='flex-end'>
            <Button 
                style={{marginRight: '5px'}}
                variant="contained" 
                onClick={() => { 
                    let jobContainer = {};
                    if(!props.jobModalOpenInEdit){
                        if(props.newCustomer){
                            jobContainer = {...selectedJob, customer: props.currentCustomer.name, 
                                            address: (props.currentCustomer.streetNumber && props.currentCustomer.streetName) ? 
                                            props.currentCustomer.streetNumber + ' ' + props.currentCustomer.streetName : ''
                            };
                            props.createCustomer(props.currentCustomer); 
                        }
                        else{
                            jobContainer = selectedJob;     
                        }
                        props.createJob({...jobContainer}); 
                    }
                    else if(props.jobModalOpenInEdit){
                        props.updateJob(selectedJob)
                    }

                    //props.updateUnit(unit) every unit in unitData
                    props.batchUpdateUnits(unitData)

                    // unitData.forEach((unit) => {
                    //     console.log('unit to be updated', unit)
                    //     props.updateUnit(unit);
                    // });

                    props.closeJobModal();
                }}
            >
                Save
            </Button> 
            <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => {
                    props.closeJobModal()
                    setUnitData(props.unitData)
                }}
            >
                Cancel
            </Button>
        </Grid>

        </Grid>
    )
}
export default withRouter(JobModal)