import React from "react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { firestore } from "../../../../firebase/firebase";
import LoadingSpinner from "../../../../Public/components/LoadingSpinner/LoadingSpinner";
import ServiceSettingsCard from "../../../components/Shop/ServiceSettings/ServiceSettingsCard";
import AreYouSure from "../../../components/UI/AreYouSure.js/AreYouSure";
import { removeFile } from "../../../../firebase/firebase";

const ServiceSettings = (props) => {

    const [serviceSettings, setServiceSettings] = useState({});
    const [saveState, setSaveState] = useState({});
    const [saveError, setSaveError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true)
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedService, setSelectedService] = useState({});
    const [services, setServices] = useState([]);

    useEffect(() => {
        firestore.collection('services').orderBy('id').get()
        .then(querySnapshot => {
            const response = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setServiceSettings({...response});
            setSaveState({...response});
            const newservs = Object.keys({...response}).map((key) => {
                return {...response}[key];
            });
            setServices(newservs)
        })

        setLoading(false);

        
        //eslint-disable-next-line
    }, [saving])


    useEffect(() =>{

    }, [serviceSettings, saveState, services])
    

    const handleSave = (data) => {
        setSaving(true);
        firestore.collection('services').doc(data.docId).update(data)
        .then(() => {
            setSaving(false);
            setSaveError(false);
            setServiceSettings({ ...saveState })
        }).catch((e) => {
            setSaving(false);
            setSaveError(true);
        })
    }

    const cancelSave = () => {
        setSaveState({ ...serviceSettings })
    }


    const createService = () => {
        const services = Object.keys(serviceSettings).map((key) => {
            return serviceSettings[key];
        });
        setSaving(true)
        firestore.collection('services').add({
            title: 'New Service',
            short: 'Short Description',
            html: 'Description',
            photoURL: '',
            id: services.length,
            row: 0
        })
        .then(() => {
            firestore.collection('services').orderBy('id').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setServiceSettings({...response});
                setSaveState({...response});
                setSaving(false)
            })
        })
    }

    const removePhoto = async (photoURL) => {
        let query = await removeFile(photoURL);
        return query.fileDeleted;
    }

    const deleteService = (data) => {
        setSaving(true)
        firestore.collection('services').doc(data.docId).delete()
        .then(async () => {
            await removePhoto(data.photoURL);
            setSelectedService({})
            setDeleteOpen(false);
            setSaving(false);
        })
    }

    return !loading && !_.isEmpty(serviceSettings) && !_.isEmpty(saveState)
        ? <React.Fragment>
            <AreYouSure timer={2} customMessage={`Delete this Service?`} open={deleteOpen} setOpen={setDeleteOpen} passedFunction={() => {deleteService(selectedService)}}/> 
            <ServiceSettingsCard
            serviceSettings={serviceSettings}
            saveState={saveState}
            cancelSave={cancelSave}
            setSaveState={setSaveState}
            handleSave={handleSave}
            saving={saving}
            saveError={saveError} services={services}
            createService={createService}
            setDeleteOpen={setDeleteOpen}
            setSelectedService={setSelectedService} selectedService={selectedService}
            />

        </React.Fragment>
        : <LoadingSpinner />
}

export default ServiceSettings;