import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore, removeFile } from '../../../firebase/firebase'
import UserCard from '../../components/Users/UserCard'
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        padding: '32px', 
        marginBottom:'30px',
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const User = (props) =>  {

    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});
    const [deleting, setDeleting] = useState(false);

    const classes = useStyles();

    const updateUserData = useCallback((e) => {
            setUserData(e)
        },
        [],
    );

    let path = new URLSearchParams(props.location.search).get("docId");

    const confirmSave = () => {
        
        setSaving(true);
        firestore.collection('users').doc(path)
            .update(userData)
            .then(() => {
                setSaveState(userData);
                setSaving(false)
            })
            .catch((e) => setError(e));
    };

    const cancelSave = () => {
        setUserData(saveState);
    };

    const handleDeleteUser  = async () => {
        setDeleting(true)
        if(userData.driversFrontURL !== "" || userData.driversFrontURL !== null)
        {
            await removeFile(userData.driversFrontURL);
        }
        if(userData.driversBackURL !== "" || userData.driversBackURL !== null)
        {
            await removeFile(userData.driversBackURL);
        }
        firestore.collection('users').doc(userData.docId).delete().then(() => {
            //* need delete photos
            firestore.collection('timesheets').where('uid', '==', userData.uuid).get().then((docs) => docs.forEach((doc) => firestore.collection('timesheets').doc(doc.id).delete()))
            firestore.collection('jobs').where('uid', '==', userData.uuid).get().then((docs) => docs.forEach((doc) => firestore.collection('jobs').doc(doc.id).delete().then(() => {
                if(doc.data().photoURLs.length > 0)
                {
                    doc.data().photoURLs.map((photo) => removeFile(photo));
                }
            })))
            firestore.collection('units').where('uid', '==', userData.uuid).get().then((docs) => docs.forEach((doc) => firestore.collection('units').doc(doc.id).delete().then(() => {
                if(doc.data().photoURLs.length > 0)
                {
                    doc.data().photoURLs.map((photo) => removeFile(photo));
                }
            })))
            firestore.collection('deliveries').where('uid', '==', userData.uuid).get().then((docs) => docs.forEach((doc) => firestore.collection('deliveries').doc(doc.id).delete().then(() => {
                if(doc.data().photoURLs.length > 0)
                {
                    doc.data().photoURLs.map((photo) => removeFile(photo));
                }
            })))
            firestore.collection('defectReports').where('uid', '==', userData.uuid).get().then((docs) => docs.forEach((doc) => firestore.collection('defectReports').doc(doc.id).delete().then(() => {
                if(doc.data().photoURLs.length > 0)
                {
                    doc.data().photoURLs.map((photo) => removeFile(photo));
                }
            })))
            firestore.collection('checklists').where('uid', '==', userData.uuid).get().then((docs) => docs.forEach((doc) => firestore.collection('checklists').doc(doc.id).delete().then(() => {
                if(doc.data().photoURLs.length > 0)
                {
                    if(doc.data().photoURLs?.damage)
                        removeFile(doc.data().photoURLs.damage);
                    else if(doc.data().photoURLs?.inaccessible)
                        removeFile(doc.data().photoURLs.inaccessible);
                }
            })))
            firestore.collection('covid').where('uid', '==', userData.uuid).get().then((docs) => docs.forEach((doc) => firestore.collection('covid').doc(doc.id).delete()))
            setDeleting(false)
            console.log("User successfully deleted!");
            props.history.replace({pathname: '/dashboard/users'})
        }).catch((error) => {
            setDeleting(false)
            console.error("Error removing user: ", error);
        });
        
    }

    useEffect(() => {

        firestore.collection('users').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const userResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': path,
                    'department': doc.data().department ? doc.data().department : 'None',
                    'role': doc.data().role ? doc.data().role : doc.data().admin ? 'Admin' : '',
                    'address': doc.data().address ?  doc.data().address : {
                        'street': doc.data().address?.street ? doc.data().address.street : "123 Street Way", 
                        'city': doc.data().address?.city ? doc.data().address.city : "Winnipeg",
                        'province': doc.data().address?.province ? doc.data().address.province : 'Manitoba',
                        'postalCode': doc.data().address?.postalCode ? doc.data().address.postalCode : 'R2C 1B2',
                    },
                    'birthday': doc.data().birthday  ?  doc.data().birthday : '1990/01/01',
                    'phone': doc.data().phone ? doc.data().phone : '',
                    'employment': doc.data().employment ?  doc.data().employment : {
                        'startDate': doc.data().employment?.startDate ? doc.data().employment.startDate : '1990/01/01',
                        'active': true,
                        'endDate': doc.data().employment?.endDate ? doc.data().employment.endDate : 'Still Employed',
                        'type': 'Employee',
                        'wcbNum': '',
                        'gstNum': '',
                    },
                    'firstName': doc.data().firstName ? doc.data().firstName : doc.data().displayName.substring(0, doc.data().displayName.indexOf(" ")),
                    'lastName': doc.data().lastName ? doc.data().lastName : doc.data().displayName.substring(doc.data().displayName.indexOf(" ")+1),
                    'payroll': doc.data().payroll  ? doc.data().payroll : {
                        'class': "Employee",
                        'payRate': '0.00',
                        'getsPremiumRate': false,
                    },
                    'contacts': doc.data().contacts ? doc.data().contacts : [
                        { 'id': 0, 'name': 'Sample Contact', 'phone': '000-000-0000', 'description': 'Home', 'email': 'emailaddress@email.ca'  },
                    ],
                    'notes': doc.data().notes ? doc.data().notes : '',
                    'commonEquipment': doc.data().commonEquipment ? doc.data().commonEquipment : {},
                    // 'rates': doc.data().rates ? doc.data().rates : [
                    //     { 'id': 0, 'name': 'Weekday Rate', 'rate': 0.00, 'per': 'Hourly', 'typeofrate': 'Payroll Rate' },
                    // ],
                }
            })
            setUserData(userResponse[0]);
            setSaveState(userResponse[0]);
            firestore.collection('users').doc(path)
                .update(userResponse[0])
            setLoading(false);
        })
        .catch(e => {
            setError("User not found.");
            setLoading(false);
            console.log(e.message);
        })
    }, [path]);
    
    return (
        <Grid>
            {loading || deleting
            ? 
            <Grid className={classes.content}>
                <Skeleton variant='rect' width={'50vw'} height={'85vh'}/>
            </Grid>
            : 
            <Grid>
                <UserCard 
                error={error} setUserData={setUserData} 
                userData={userData} saveState={saveState}   setSaveState={setSaveState}
                cancelSave={cancelSave} confirmSave={confirmSave} 
                updateUserData={updateUserData} saving={saving}
                handleDeleteUser={handleDeleteUser}
                /> </Grid> }
              
        </Grid>
    )
}

export default withRouter(User);