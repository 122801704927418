import React, { useState, useEffect }  from 'react';
import moment from 'moment'
import ReportTable from '../../../components/Reports/ReportTable/ReportTable';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import JobOptions from '../../../components/Reports/Jobs/JobOptions'
import { Paper, IconButton, Typography } from '@material-ui/core';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _  from 'lodash'
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MediaViewer from '../../../components/UI/MediaViewer/MediaViewer';
import { Skeleton } from '@material-ui/lab';
import { ExportCsv, ExportPdf } from "@material-table/exporters";

const useStyles = makeStyles((theme) => ({

    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
}));


const JobReport = (props) => {
    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [loadingText, setLoadingText] = useState("");
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [checkboxState, setChecked] = useState({
        photos: ("jobCheckbox" in sessionStorage ? JSON.parse(sessionStorage.getItem("jobCheckbox")) : false),
        nophotos: true,
        checklists: true,
        notes: true,
    });
    const checkboxHandler = (event) => {
        setChecked({ ...checkboxState, [event.target.name]: event.target.checked });
        sessionStorage.setItem("jobCheckbox", JSON.stringify(event.target.checked))
    };
    useEffect(() => {

    }, [smallScreen]);
    const [jobData, setJobData] = useState(JSON.parse(sessionStorage.getItem("jobData")) || []);
    const [timesheetData, setTimesheetData] = useState(JSON.parse(sessionStorage.getItem("timesheetJobData")) || []);
    const [checklistData, setChecklistData] = useState(JSON.parse(sessionStorage.getItem("checklistJobData")) || []);
    const [unitData, setUnitData] = useState(JSON.parse(sessionStorage.getItem("unitJobData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    //const [searchBarValue] = useState("jobsSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("jobsSearchBarValue")) : null)

    const handlePrint = () => {
        props.history.push({
            pathname: '/dashboard/reporting/jobs/printing/table/',
            state: { data: mergeData(), jobData: jobData, hours: hours }
        })
    }
    const [searchState, setSearch] = useState({
        type: JSON.parse(sessionStorage.getItem("jobOptionsSearchType")) || 'customer',
        value: JSON.parse(sessionStorage.getItem("jobOptionsSearch")) || '',
    });

    const [jobTypeState, setJobType] = useState({
        //type: JSON.parse(sessionStorage.getItem("jobOptionsType")) || 'snow',
        value: '',
        jobType: JSON.parse(sessionStorage.getItem("jobOptionsType")) || 'snow',
    });

    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const searchHandler = (event) => {
        setSearch({ ...searchState, [event.target.name]: event.target.value })
        if(event.target.name === "type") sessionStorage.setItem("jobOptionsSearchType", JSON.stringify(event.target.value))
        if(event.target.name === "value") sessionStorage.setItem("jobOptionsSearch", JSON.stringify(event.target.value))
    }

    const jobTypeHandler = (event) => {
        setJobType({ ...jobTypeState, [event.target.name]: event.target.value })
        sessionStorage.setItem("jobOptionsType", JSON.stringify(event.target.value))
    }

    const clearSearch = () => {
        setSearch({ ...searchState, value: '' });
        sessionStorage.removeItem("jobOptionsSearch");
    }

    const jobClickHandler = path => {
        props.jobClickHandler(path);
    }

    async function dateCompat() {
        let query = firestore.collection('jobs')

        if (searchState.type === 'type') {
            query = query.where("jobType", "==", jobTypeState.jobType);
        }
        query.orderBy('date')

        const oldDate = query.where("date", ">=", startDate.startOf('day').unix()).where("date", "<=", endDate.endOf('day').unix()).get()
        const newDate = query.where("date", ">=", startDate.startOf('day').utc().valueOf()).where("date", "<=", endDate.endOf('day').utc().valueOf()).get()

        const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]).catch((err) => console.log(err))

        const oldDateArray = oldDateSnapshot.docs
        const newDateArray = newDateSnapshot.docs
        return _.concat(oldDateArray, newDateArray)
    }


    const generateHandler = () => {
        sessionStorage.removeItem("timesheetJobData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
        sessionStorage.removeItem("defectData");
	    sessionStorage.removeItem("unitJobData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistJobData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");
        
        setProgress(1)
        setLoadingText("Preparing Cache")
        setErrorMessage(null);
        if (endDate.isBefore(startDate)) {
            setErrorMessage('Start Date later than End Date!')
            setProgress(0)
            setLoadingText("")
            return
        }

        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            setProgress(0)
            setLoadingText("")
            return
        }

        setLoading(true);
        setProgress(10)
        setLoadingText("Grabbing Job data...")
        dateCompat().then(querySnapshot => {
            let jobResponse = querySnapshot.map((doc) => {

                return {
                    ...doc.data(),
                    'docId': doc.id,

                }
            })
            setProgress(15)

            setLoadingText("Applying search filter..")
            if (checkboxState.photos) {
                jobResponse = jobResponse.filter(job => job.photoURLs.length > 0)
            }
            if (searchState.value !== "") {
                switch (searchState.type) {
                    case 'customer':
                        jobResponse = jobResponse.filter(job => job?.customer.toLowerCase().includes(searchState.value.toLowerCase()))
                        break;
                    case 'address':
                        jobResponse = jobResponse.filter(job => job?.address.toLowerCase().includes(searchState.value.toLowerCase()))
                        break;
                    case 'photo':
                        jobResponse = jobResponse.filter(job => job?.photoURLs.amount > 0)
                        break;
                    default:
                        break;
                }
            }
            setProgress(25)
            setLoadingText("Grabbing Timesheet data...")
            setJobData(jobResponse)
            //setLoading(false);
            //sessionStorage.setItem("jobData", JSON.stringify(jobResponse))
            return firestore.collection('timesheets').orderBy('date').startAt(startDate.startOf('day').valueOf()).endAt(endDate.endOf('day').valueOf()).where("date", ">=", startDate.startOf('day').valueOf()).where("date", "<=", endDate.endOf('day').valueOf()).get();
        }).then((querySnapshot) => {
            setProgress(35)
            const timesheetResponse = querySnapshot.docs.map((doc) => {
                return { 
                    'docId': doc.id,
                    'date': doc.data().date,
                    'inTime': doc.data().usingGateInTime ? doc.data().gateIn15 : doc.data().inTime,
                    'offHours': doc.data().offHours,
                    'operatorHours': doc.data().operatorHours,
                    'outTime': doc.data().usingGateOutTime ? doc.data().gateOut15 : doc.data().outTime,
                    'timesheetID': doc.data().timesheetID,

                 }

            });
            setProgress(50)
            setLoadingText("Grabbing Checklist data..")
            setTimesheetData(timesheetResponse);
            sessionStorage.setItem("timesheetJobData", JSON.stringify(timesheetResponse))

            //TODO: work out why date filters for checklists dont work
            return firestore.collection('checklists').where("date", ">=", startDate.startOf('day').valueOf()/1000).where("date", "<=", endDate.endOf('day').valueOf()/1000).get();
        }).then((querySnapshot) => {
            setProgress(60)
            const checklistResponse = querySnapshot.docs.map((doc) => {
                return { ...doc.data(),
                    'docId': doc.id,
                    'serviceType': doc.data().serviceType,
                    'serviceReason': doc.data().serviceReason,
                    'date': doc.data().date,
                    'equipmentType': doc.data().equipmentType,
                      
                }
            });
            setProgress(75)
            sessionStorage.setItem("checklistJobData", JSON.stringify(checklistResponse))
            setLoadingText("Grabbing Unit data...")
            setChecklistData(checklistResponse);
            return firestore.collection('units').where("date", ">=", startDate.startOf('day').valueOf()).where("date", "<=", endDate.endOf('day').valueOf()).get();
        }).then((querySnapshot) => {
            setProgress(80)
            const unitResponse = querySnapshot.docs.map((doc) => {
                return { 
                    'docId': doc.id,
                    'unitNumber': doc.data().unitNumber
                }
            });
            setProgress(90)
            sessionStorage.setItem("unitJobData", JSON.stringify(unitResponse))
            setLoadingText("Checking Cache..")
            setUnitData(unitResponse);
        }).then(() => {

            //sessionStorage.setItem("jobData", JSON.stringify(mergeData()))
            setProgress(99)
            //should have required data
            setLoading(false);
            setLoadingText("Complete.")
            setTimeout(() => {
                setProgress(100)
            }, 3000);
        }).catch(e => {

            setJobData([]);
            sessionStorage.removeItem("jobData");
            setChecklistData([]);
            sessionStorage.removeItem("checklistData");
            setTimesheetData([]);
            sessionStorage.removeItem("timesheetData");
            setUnitData([]);
            sessionStorage.removeItem("unitData");
            setLoading(false);
            setProgress(0);

            switch(e.name){
                case 'QuotaExceededError':
                    setErrorMessage("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                  default:
                    setErrorMessage("Unable to process.");
                    console.log(e.message);
            }
        });
    }

    //updates job session storage when the last hook in generateHandler() finishes updating
    useEffect(() => {
        //find a way to make this not store on inital render
        try{
        sessionStorage.setItem("jobData", JSON.stringify(mergeData()));
        }
        catch(e){
            console.log(e.message)
        }
    }, [checklistData]); // eslint-disable-line react-hooks/exhaustive-deps

    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };
    const error = [checkboxState.unseen, checkboxState.viewed, checkboxState.printed].filter((v) => v).length < 1;

    const [open, setOpen] = React.useState(false);
    const [photos] = React.useState([]);

    function mergeData() {
        return jobData.map(x => {
            if (x.outTime < 9999999999) x.outTime *= 1000
            if (x.inTime < 9999999999) x.inTime *= 1000
            x.fullTime = x.timeOut === 0 ? "In Progress" : Number(((x.timeOut - x.timeIn) / 3600000).toFixed(2))
            if(x.expandedTime !== undefined){
                x.fullTime += Number(x.expandedTime)
                x.fullTime = x.fullTime.toFixed(2)
            }
            const checklists = checklistData.filter(checklist => checklist.jobID === x.docId);
            const timesheets = timesheetData.filter(timesheet => timesheet.docId === x.timesheetID);
            const units = unitData.filter(unit => x.units.includes(unit.docId));
            x.opHours = ((x.outTime - x.inTime - (((x.skipLunch === 'na' || x.skipLunch === 'declined') ? 1800000 : 0))) / 3600000) - x.offHours
            x.opHours = Math.max(x.opHours.toFixed(2), 0);
            x.timesheet = timesheets.filter(timesheet => timesheet.docId === x.timesheetID)
            x.checklist = checklists.filter(checklist => checklist.jobID === x.docId)
            x.unitList = []
            units.forEach(unit => {
                x.unitList.push(unit.unitNumber)
            })
            return {
                ...x,
                //timesheet: timesheets.map(timesheet => timesheet.timesheetID),
            }
        });

    }
    function calcHours() {
        return jobData.map(x => {
            if (x.timeOut < 9999999999) x.timeOut *= 1000
            if (x.timeIn < 9999999999) x.timeIn *= 1000
            x.opHours = ((x.timeOut - x.timeIn ) / 3600000)
            x.opHours = Math.max(x.opHours.toFixed(2), 0);
            return { time: x.opHours }
        }).reduce((total, timesheet) => total + timesheet.time, 0).toFixed(2)
    }
    const hours = calcHours();

    return (
        <React.Fragment>
            <div className={classes.content}
            style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {photos.length > 0 ? <MediaViewer open={open} handleClose={() => setOpen(false)} photoURLs={photos} /> : null}
                {progress === 0 || progress === 100 || progress === 99 ?
                <ReportTable
                    columns={ [
                        { title: 'Customer', width:'20%', field: 'customer' },
                        { title: 'Address', width:'20%', field: 'address' },
                        {
                            title: 'Time In', field: 'timeIn', render: rowData => {
                                if (rowData.timeIn < 9999999999) rowData.timeIn *= 1000
                             
                                return moment(rowData.timeIn).format('YYYY-MM-DD h:mm a')
                            }
                        },
                        {
                            title: 'Time Out', field: 'timeOut', render: rowData => {
                                if (rowData.timeOut < 9999999999) rowData.timeOut *= 1000
                                return moment(rowData.timeOut).format('YYYY-MM-DD h:mm a')
                            }
                        },
                        {
                            title: 'Job Time', width: '1%', field:'fullTime'
                        },
                        {
                            title: 'Timesheet', width: '1%', customSort: (a, b) => { return a?.timesheet?.[0]?.timesheetID < b?.timesheet?.[0]?.timesheetID ? 1 : -1 },  render: rowData => {
                                if(rowData.timeOut === 0) return "N/A"
                                return rowData?.timesheet?.[0]?.timesheetID
                            }
                        },
                        {  
                            title: 'Units', field: 'unitList' , render: rowData => {
                                if(rowData.timeOut === 0) {return "N/A"}
                                else {
                                    return rowData?.unitList?.map((unit, index) => {
                                        return <Typography key={index}>{unit}</Typography>
                                    })
                                }
                                
                            }

                        },
                      /*  {
                            title: 'Photos?', field: 'photoURLs', width: '1%', render: rowData => {
                                if(rowData.photoURLs !== undefined && rowData.photoURLs !== null && Object.values(rowData.photoURLs)?.length !== 0) 
                                    return <CheckCircleIcon/>
                            }
                        },*/
                    ]}
                    data={mergeData()}
                    title={`Jobs - Total Hours: ${hours}`}
                    icons={TableIcons}
                    isLoading={loading}
                    pathname='/dashboard/reporting/timesheets/job'
                    actions={[
                        {
                            icon: TableIcons.OpenInNew,
                            tooltip: 'View Job',
                            onClick: (event, rowData) => jobClickHandler(rowData['docId']),
                        },
                    ]}
                    onSearchChange={ (value) => { sessionStorage.setItem("jobReportSearchBarValue", JSON.stringify(value)) }}
                    onRowsPerPageChange={(num) => { sessionStorage.setItem("jobReportRowsPerPage", JSON.parse(num)) }}
                    onPageChange={ (pageNum) => { sessionStorage.setItem("jobReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        pageSize: "jobReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("jobReportRowsPerPage")) : 40,
                        initialPage: "jobReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("jobReportPageValue")) : 0,
                        pageSizeOptions: [5, 10, 20, 40, 50, 100],
                        toolbar: true,
                        paging: true,
                        columnsButton: true,
                        searchText: "jobReportSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("jobReportSearchBarValue")) : '',
                        //actionsColumnIndex: -1,
                        thirdSortClick: false,
                        exportMenu: [
                            {
                                label: "Export CSV",
                                exportFunc: (cols, datas) => ExportCsv(cols, mergeData(), `JobReportData`),
                            },
                            {
                                label: "Export PDF",
                                exportFunc: (cols, datas) => ExportPdf(cols, mergeData(), `JobReportData`),
                                },
                        ],
                    }}
                /> 
                : <Skeleton variant='rect' width={'80vw'} height={'250vh'}/>}
                
                {smallScreen ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" >
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <JobOptions
                                checkboxState={checkboxState} handler={checkboxHandler}
                                searchState={searchState} searchHandler={searchHandler} 
                                jobTypeState={jobTypeState} jobTypeHandler={jobTypeHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                error={error}
                                handlePrint={handlePrint}
                            />
                        </Paper>
                    </Collapse>
                </div>
                :
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" >
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <JobOptions
                                checkboxState={checkboxState} handler={checkboxHandler}
                                searchState={searchState} searchHandler={searchHandler}
                                jobTypeState={jobTypeState} jobTypeHandler={jobTypeHandler}
                                clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                error={error}
                                handlePrint={handlePrint}
                                progress={progress} loadingText={loadingText} checkboxHandler={checkboxHandler}
                            />
                        </Paper>
                    </Collapse>
                </div>
}
            </div>
        </React.Fragment>
    )
}

export default withRouter(JobReport)