import React, { memo } from 'react';
import { makeStyles,  } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    columnTitles: {
        fontSize: '14px',
    },
    rowContent: {
        width: '90%',
        fontSize: '14px',
        fontWeight: 600,
        // padding: '5px',
        // margin: '5px'
    },
}));

const DataGridTableDatePicker = ({data, id, field, docId, handleCellUpdateCallback, cellClickCallback, style}) => {
    const classes = useStyles();

    let valueContainer = data;

    //console.log('DataGridTableAutocomplete rendered', docId)

    return(
        <>
            <DatePicker
                inputProps={{ className: classes.rowContent }}
                className={classes.rowContent}
                id={id}
                value={valueContainer}
                format="YYYY-MM-DD"
                autoOk
                clearable
                // onClick={() => {
                //     cellClickCallback(`${docId} ${field}`) 
                // }}
                placeholder={''}
                onChange={(value) => { 
                    //cellClickCallback(`${docId} ${field}`) 
                    valueContainer = value;
                    let newValue = value === null ? value : value.valueOf();
                    handleCellUpdateCallback(newValue, field, docId); 
                }}
                // renderInput={(params) => (
                //     <TextField
                //         {...params}
                //         inputProps={{...params.inputProps, readOnly: true}}
                //     />
                // )}
            />
        </>
    )
}

export default memo(DataGridTableDatePicker);