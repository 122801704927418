import React from 'react';
import { Font, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import _ from 'lodash';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         justifyContent: 'center',
//         width: '100%',
//     },
//     content: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         maxWidth: '1200px',
//         margin: 'auto',
//     },
//     buttonRow: {
//         marginBottom: '20px',
//         marginTop: '20px',
//     },
//     checkRow: {
//         margin: theme.spacing(1),
//         display: 'flex',
//         justifyContent: 'space-evenly',
//     },
// }));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subTable: {
        marginBottom: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    tableTextBold: {
        fontSize: 10,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginTop: '5px'
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    checkbox: {
        width: '10px',
        height: '10px',
        background: 'white',
        border: '1 solid black',
    },
    radio: {
        width: '10px',
        height: '10px',
        background: 'white',
        borderRadius: '50%',
        border: '1 solid black',
    },
    userEntered: {
        width: '200px',
        height: '20px',
        background: 'white',
        border: '1 solid black',
    },
    
    
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '4px',
        borderBottom: '1px solid black',
        marginBottom: '30px',
    },
    portraitTableCell: {
        width: '14.25%',
        minWidth: '14.25%',
        alignItems: 'center',
    },
    landscapeTableCell: {
        width: '9%',
        minWidth: '9%',
        alignItems: 'center',
    },
    portraitTableCellNth2: {
        width: '14.25%',
        minWidth: '14.25%',
        alignItems: 'center',
        backgroundColor: 'grey',
    },
    landscapeTableCellNth2: {
        width: '9%',
        minWidth: '9%',
        alignItems: 'center',
        backgroundColor: 'grey',
    },
    instructionHeader: {
        paddingBottom: '5px',
        fontSize: 12,
        color: 'blue',
    },

    summaryCombinedPortraitTableCell:{
        width: '71.5%', 
        minWidth: '71.5%', 
        alignItems: 'center',
    },
    summaryNotCombinedLandscapeTableCell: {
        width: '20.5%',
        minWidth: '20.5%', 
        alignItems: 'center',
    },

});

const PDFInvoiceInfo = (props) => {
    //const classes = useStyles();
    
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */

    return (
        <>            
             {/* customer info, date and invoice title */}
             <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '15px', marginTop: '75px'}} > 
                <View style={{flexDirection: 'column'}}>
                    <Text style={{...styles.tableText}}>{props.customer?.name}</Text>
                    <Text style={{...styles.tableText}}>{`${props.customer?.streetNumber || ''} ${props.customer?.streetName || ''}`}</Text>
                    <Text style={{...styles.tableText}}>{`${props.customer?.city ? props.customer?.city + "," : ''} ${props.customer?.province || ''} ${props.customer?.postalCode || ''}`}</Text>
                    <Text style={{...styles.tableText}}>{props.customer?.contacts.find(x=> x.invoiceContact)?.email}</Text>
                    <Text style={{...styles.tableText}}>{props.customer?.contacts.find(x=> x.invoiceContact)?.phone}</Text>
                </View>

                <View style={{flexDirection: 'column',}}>
                    <Text style={{...styles.tableText}}>{`Invoice # ${props.invoiceNumber || ''}`}</Text>
                    {(!_.isEmpty(props.invoiceDetails.secondaryNumberType) || !_.isEmpty(props.invoiceDetails.secndaryNumberValue)) && 
                        <Text style={{...styles.tableText}}>{`${props.invoiceDetails.secondaryNumberType} ${props.invoiceDetails.secndaryNumberValue}`}</Text>
                    }

                    <View style={{flexDirection: 'column',}}>
                        <Text style={{...styles.tableText}}>{`Date: ${moment(props.invoiceDetails.invoiceDate).format('MMMM D, YYYY')}`}</Text>
                    </View>
                </View>
            </View>

            {/* attention note*/}
            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '15px'}} > 
                <View style={{flexDirection: 'column'}}>
                    {!_.isEmpty(props.invoiceDetails.attention) && <Text style={{...styles.tableText}}>{`Attention ${props.invoiceDetails.attention}`}</Text>}
                </View>
            </View>

            {/* invoice period */}
            <View style={{alignItems: 'center', marginBottom: '10px'}} > 
                <View style={{flexDirection: 'column'}}>
                    <View style={{alignItems: 'center'}} >
                        <Text style={{...styles.tableText}}>Invoice Period</Text>
                    </View>
                    {/* date of earliest ticket to invoice date */}
                    <Text style={{...styles.tableText}}>
                        {`${moment(props.invoiceFilter.startDate).format('MMMM D, YYYY')} to ${moment(props.invoiceFilter.endDate).format('MMMM D, YYYY')}`}
                    </Text>
                </View>
            </View>
         </>

    )
};

export default PDFInvoiceInfo;