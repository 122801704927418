import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Img from 'react-cool-img';
import truck from '../../../../img/geeteezoomzoom.gif';
import trucksad from '../../../../img/geeteesad.png';
import { ExportCsv } from "@material-table/exporters";
import PhotoViewModal from './PhotoViewModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PhotosTable = (props) => {

    const [searchBarValue, setSearchBarValue] = useState("photoSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoSearchBarValue")) : null)
    const [open, setOpen] = useState(false);
    const [rowSelected, setRowSelected] = useState({});
    const [checklist, setChecklist] = useState([]);

    const handleOpen = (rowdata) => {
        setRowSelected(rowdata)
        setOpen(true);

        if (rowdata.origin === 'checklist'){
        
            switch (rowdata.serviceType) {
                case 'Cleaning':
                    setChecklist (
                        [
                            ['pavement' in rowdata.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                            ['curb' in rowdata.checkedItems, 'All Curbs are exposed'],
                            ['driveway' in rowdata.checkedItems, 'All Driveway approaches are clear'],
                            ['loadingdock' in rowdata.checkedItems, 'All loading docks are clear, if applicable'],
                            ['piles' in rowdata.checkedItems, 'Snow is piled in a designated area, which doesn’t obstruct a roadway, sidewalk, fire hydrant or entrance or exit from building'],
                            ['damage' in rowdata.checkedItems, 'Check this box if damaged occurred during cleaning and take picture',],
                            ['inaccessible' in rowdata.checkedItems, 'Check box if there was somewhere that couldn’t be cleared and take picture of reason'],
                        ]
                    )
                    
                    break;
                case 'Sanding':
                    setChecklist(
                        [
                            ['approaches' in rowdata.checkedItems, 'Approaches sanded'],
                            ['aisles' in rowdata.checkedItems, 'Main aisles and walking paths sanded'],
                            ['docks' in rowdata.checkedItems, 'Loading docks sanded if applicable'],
                            ['damage' in rowdata.checkedItems, 'Check box if any damaged occurred during sanding and take pictures'],
                            ['inaccessible' in rowdata.checkedItems, 'Check box if there was somewhere that couldn’t be sanded and take picture of reason'],
                        ]
                    )
                    break;
                case 'Shovelling':
                    setChecklist (
                        [
                            ['pavement' in rowdata.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                            ['leeway' in rowdata.checkedItems, 'Snow is cleaned at least 1-2 feet from edge of sidewalk'],
                            ['salted' in rowdata.checkedItems, 'If needed sand or other ice melting products applied on icy areas'],
                            ['emergency' in rowdata.checkedItems, 'All emergency exits are clear of snow'],
                            ['inaccessible' in rowdata.checkedItems, 'Check this box If any area was unable to be  cleaned, take picture of area'],
                        ]
                    )
                    break;
                default:
                    break;
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
        setRowSelected({})
    }

    const customExportPdf = async (columns, data, fileName) => {
        const doc = new jsPDF();
    
        // Function to convert image URLs to Base64
        const toDataURL = (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.crossOrigin = "Anonymous"; // Avoid cross-origin issues
                img.src = url;
                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);
                    resolve(canvas.toDataURL("image/jpeg")); // Convert to Base64
                };
                img.onerror = () => resolve(null); // Handle errors gracefully
            });
        };
    
        // Convert all image URLs to Base64 first
        const formattedData = await Promise.all(
            data.map(async (row) => ({
                ...row,
                photoBase64: Array.isArray(row.photoURLs) && row.photoURLs.length > 0
                    ? await toDataURL(row.photoURLs[0]) // Convert first image to Base64
                    : await toDataURL(row.photoURLs), // No image available
                date: row.origin === "unit" || row.origin === "defect" || row.origin === "job"
                    ? moment.utc(row.date).format('YYYY-MM-DD')
                    : row.origin === "checklist"
                    ? moment.unix(row.date).format('YYYY-MM-DD')
                    : row.date, // Use exportTransformer logic
    
                origin: row.origin.charAt(0).toUpperCase() + row.origin.slice(1), // Apply transformation
    
                employee: row.employee // No transformation needed
            }))
        );
    
        doc.autoTable({
            head: [columns.map(col => col.title)], // Keep all columns
            body: formattedData.map(row =>
                columns.map(col => (col.field === 'photoURLs' ? '' : row[col.field])) // Ensure empty string for image column
            ),
            didDrawCell: (data) => {
                const columnField = columns[data.column.index]?.field;
    
                // Ensure we only process the image column
                if (data.section !== 'head' && columnField === 'photoURLs' && formattedData[data.row.index]?.photoBase64) {
                    const imgBase64 = formattedData[data.row.index].photoBase64;
                    doc.addImage(imgBase64, 'JPEG', data.cell.x + 2, data.cell.y + 2, 20, 20) // Draw image in correct position
                }
            },
            columnStyles: {
                0: { cellWidth: 26 }, // Narrow image column
            },
            styles: {
                cellPadding: 8, // Add slight padding
                valign: 'middle', // Align content in the middle
                halign: 'center', // Center text horizontally
            },
        });
    
        doc.save(`${fileName}.pdf`);
    };

    return(
        <React.Fragment>
            <PhotoViewModal 
                rowSelected={rowSelected}
                checklist={checklist}
                open={open}
                handleClose={handleClose}
                handleDownloadSingle={props.handleDownloadSingle}
                photosClickHandler={props.photosClickHandler}
            />

            <MaterialTable
                tableRef={props.tableRef}
                columns={[
                    { title: 'Photo', field: 'photoURLs', 
                    render: rowdata => <Img
                        style={{ width: '100px', height: '100px' }}
                        error={trucksad}
                        placeholder={truck}
                        src={Array.isArray(rowdata.photoURLs) ? rowdata.photoURLs[0] : rowdata.photoURLs}
                        //error={errorImage}
                        alt="Photo"
                        //onClick={() => {handleOpen(rowdata);}}
                        />, exportTransformer: rowdata => {
                        
                            if (Array.isArray(rowdata.photoURLs) && rowdata.photoURLs.length > 0) {
                                // Check if each entry is an object with a URL key
                                const firstImage = typeof rowdata.photoURLs[0] === "object" && rowdata.photoURLs[0].url 
                                    ? rowdata.photoURLs[0].url 
                                    : rowdata.photoURLs[0];
                        
                                return firstImage; // Return URL as a string, not an object
                            } else if (typeof rowdata.photoURLs === "string") {
                                return rowdata.photoURLs;
                            }
                        
                            return "No Image"; // Fallback if no images are found
                        }

                    
                    },
                    { title: 'Date Created', field: 'date', render: rowData => {
                        //utc
                        if(rowData.origin === "unit" || rowData.origin === "defect" || rowData.origin === "job")
                        return moment.utc(rowData.date).format('YYYY-MM-DD')

                        //unix
                        if(rowData.origin === "checklist")
                        return moment.unix(rowData.date).format('YYYY-MM-DD')

                        //string 
                        if(rowData.origin === "delivery")
                        return (rowData.date)
                    } , exportTransformer: rowData => {
                    //utc
                    if(rowData.origin === "unit" || rowData.origin === "defect" || rowData.origin === "job")
                        return moment.utc(rowData.date).format('YYYY-MM-DD')

                        //unix
                        if(rowData.origin === "checklist")
                        return moment.unix(rowData.date).format('YYYY-MM-DD')

                        //string 
                        if(rowData.origin === "delivery")
                        return (rowData.date)
                    }, },
                    { title: 'Type', field: 'origin', render: rowData => {
                        return (rowData.origin.charAt(0).toUpperCase() + rowData.origin.slice(1));
                    }, exportTransformer: rowData => {
                        return (rowData.origin.charAt(0).toUpperCase() + rowData.origin.slice(1));
                    }, },
                    { title: 'Employee', field: 'employee' },
                    { title: 'Customer', field: 'customerDisplay' },
                ]}
                title='Photos'
                data={props.filteredPhotoData}
                icons={TableIcons}
                isLoading={props.loading}
                onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("photoSearchBarValue", JSON.stringify(value));}}
                actions={[
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'View Photo Origin',
                        onClick: (event, rowData) => props.photosClickHandler(rowData.docId, rowData.origin),
                    },
                ]} 
                onPageChange={ (pageNum) => { sessionStorage.setItem("photoReportPageValue", JSON.stringify(pageNum)) }}
                options={{
                    pageSizeOptions: [5, 10, 25],
                    pageSize: "photoReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoReportRowsPerPage")) : 10,
                    initialPage: "photoReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("photoReportPageValue")) : 0,
                    toolbar: true,
                    columnsButton: true,
                    paging: true,
                    sorting: true,
                    thirdSortClick: false,
                    searchText: searchBarValue,
                    exportMenu: [
                        {
                            label: "Export CSV",
                            exportFunc: (cols, datas) => ExportCsv(cols, props.filteredPhotoData, `PhotoReportData`),
                        },
                        {
                            label: "Export PDF",
                            exportFunc: (cols, datas) => customExportPdf(cols, props.filteredPhotoData, `PhotoReportData`),
                        },
                    ],
                }}
                onRowsPerPageChange={(num) => {
                    sessionStorage.setItem("photoReportRowsPerPage", JSON.parse(num))
                }}
                onRowClick={(event, rowData) => {
                    handleOpen(rowData);
                }}
            />

        </React.Fragment>
    )

}

export default withRouter(PhotosTable)