import React, {useState} from 'react';
import { CircularProgress, Typography, Grid } from '@material-ui/core';

const OptionsList = (props) => {
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    return (
        <Grid item xs={12} style={{border:'1px solid #ccc', borderRadius:'4px', padding:'3px',
            minHeight:'150px', maxHeight:'150px', overflowY:'auto', display:'flex', flexDirection:'column'
        }}>
                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {props.list
                ?.map((item, index) => {
                    return (
                        <Grid item key={index} xs={12} 
                            style={{ backgroundColor: props.searchItems.includes(item) ? '#ccc' : 'transparent' }}>
                            <Typography 
                                variant="p" 
                                style={{ 
                                    cursor: 'pointer', 
                                    userSelect: 'none', // Prevents text selection because it feels awful with shift click
                                    WebkitUserSelect: 'none', 
                                    MozUserSelect: 'none', 
                                    msUserSelect: 'none' 
                                }} 
                                onClick={(event) => {
                                    let array = [...props.searchItems];
                                    let currentIndex = props.list.indexOf(item);
                                
                                    if (event.shiftKey && lastSelectedIndex !== null) {
                                        // Shift-click: Select range between lastSelectedIndex and currentIndex to handle selection like in excel or whatev
                                        let minIndex = Math.min(lastSelectedIndex, currentIndex);
                                        let maxIndex = Math.max(lastSelectedIndex, currentIndex);
                                        array = props.list.slice(minIndex, maxIndex + 1);
                                    } else if (event.ctrlKey) {
                                        // Ctrl-click: Toggle item in list a classic
                                        if (array.includes(item)) {
                                            array = array.filter(i => i !== item);
                                        } else {
                                            array.push(item);
                                        }
                                    } else {
                                        // Single click: If already selected, clear list; otherwise, select only this item 
                                        array = array.includes(item) ? [] : [item];
                                    }
                                
                                    props.setSearchItems(array);
                                    setLastSelectedIndex(currentIndex); // Store last selected index for shift-clicking
                                    //god i hate this but its cool i guess lol
                                }}>
                                {item}
                            </Typography>
                        </Grid>
                    )
                })}
        </Grid>     
    )
}

export default OptionsList;