import _ from "lodash";
import { useEffect, useState } from "react";
import { firestore } from "../../../../firebase/firebase";
import LoadingSpinner from "../../../../Public/components/LoadingSpinner/LoadingSpinner";
import ZoneSettingsCard from "../../../components/Shop/ZoneSettings/ZoneSettingsCard";

const ZoneSettings = (props) => {

    const [shopSettings, setShopSettings] = useState({});
    const [saveState, setSaveState] = useState({});
    const [saveError, setSaveError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true)
    

    useEffect(() => {
        firestore.collection('shopSettings').doc('zoneSettings').get()
        .then(doc => {
            const response = {
                ...doc.data(),
                'docId': doc.id,
            }
            setShopSettings(response);
            setSaveState(response);
            setLoading(false);
        }).catch((e) => console.log(e));
        
        //eslint-disable-next-line
    }, [,saving])


    useEffect(() =>{

    }, [shopSettings, saveState])
    

    const handleSave = () => {
        setSaving(true);

        firestore.collection('shopSettings').doc('zoneSettings').set(saveState)
        .then(() => {
            setShopSettings(_.cloneDeep(saveState));
            setSaveError(false);
        }).catch((e) => {
            setSaveError(true);
            console.log(e);
        })
        setSaving(false);
    }

    const cancelSave = () => {
        setSaving(true);
        setSaveState(_.cloneDeep(shopSettings))
        
        setSaving(false);
    }

    return !loading && !_.isEmpty(shopSettings) && !_.isEmpty(saveState)
        ? <ZoneSettingsCard
            shopSettings={shopSettings}
            saveState={saveState}
            cancelSave={cancelSave}
            setSaveState={setSaveState}
            handleSave={handleSave}
            saving={saving}
            saveError={saveError} 
            />
        : <LoadingSpinner />
}

export default ZoneSettings;