import React, { useState, useEffect, useRef, } from 'react';
import moment from 'moment'
import MaterialTable from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import UnitOptions from '../../../components/Reports/Units/UnitOptions'
import { Paper, IconButton, Button, Grid, Typography, Modal, Checkbox } from '@material-ui/core';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import {Font, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import _ from 'lodash'
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useUser } from '../../../../providers/UserContext'
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { ExportCsv, ExportPdf } from "@material-table/exporters";

const useStyles = makeStyles((theme) => ({

    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
    downloadModal: {
        marginRight: '60px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingBottom: '100px',
        marginTop: '50px',
    },
    dlpaper: {
        width: '390px',
        margin: '5px 5px 5px 5px',
    },
    scroll: {
        maxHeight: '800px',
        overflowY: "scroll",
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',
        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

const UnitReport = (props) => {
    const { users, getUsers } = useUser();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const tableRef = useRef(MaterialTable);

    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [checkboxState, setChecked] = React.useState({
        unsubmitted: false,
        unseen: true,
        viewed: true,
        printed: true,
    });
    const [unitData, setUnitData] = useState(JSON.parse(sessionStorage.getItem("unitData")) || []);
    const [unitModalOpen, setUnitModalOpen] = useState(false)
    const [dlData, setdlData] = useState([])
    const [FilteredDlData, setFilteredDlData] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [autoCompleteValue, setAutoCompleteValue] = useState("unitAutoComplete" in sessionStorage ? JSON.parse(sessionStorage.getItem("unitAutoComplete")) : "")
    const [searchState, setSearch] = useState({
        type: JSON.parse(sessionStorage.getItem("unitOptionsSearchType")) || 'unitNumber',
        value: JSON.parse(sessionStorage.getItem("unitOptionsSearchValue")) || '',
    });
    const [searchBarValue, setSearchBarValue] = useState("unitSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("unitSearchBarValue")) : null)

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [smallScreen]);

    const checkboxHandler = (event) => {
        setChecked({ ...checkboxState, [event.target.name]: event.target.checked });
    };

    const handleDownload =  () => {
        let pdfContainer = generatePDFs()
        var zip = new JSZip();
        var zipFilename = "Units-" + new Date().toDateString() + ".zip";

        //generating content
        pdfContainer.map((pdf, index) =>{
            let name = FilteredDlData[index].unitNumber + '_' + FilteredDlData[index].uid + '_' + moment.utc(FilteredDlData[index].date).format('YYYY-MM-DD') + '_(' + index + ')'
            return zip.file(name + ".pdf", pdf, { blob: true });
        });

        //zip folder and save as
        zip.generateAsync({type:'blob'}).then(function(content) {
        saveAs(content, zipFilename);
        });
    }
    
    const handleOpenDownloadModal = () => {
        setdlData([...tableRef.current.state.data.map(unit => unit)])
        setFilteredDlData([...tableRef.current.state.data.map(unit => unit)])
        setUnitModalOpen(true);
    }

    const handleCloseDownloadModal = () => {
        setdlData([])
        setFilteredDlData([])
        setUnitModalOpen(false);
    }

    const handleCheckboxChange = (event, unit) => {
        if(event.target.checked){
            let dlFilteredDataContainer = FilteredDlData;
            dlFilteredDataContainer.push(unit)
            setFilteredDlData([...dlFilteredDataContainer])
        }
        else{
            let dlFilteredDataContainer = FilteredDlData;
            dlFilteredDataContainer.splice(FilteredDlData.map(x=> x.docId).indexOf(unit.docId) , 1)
            setFilteredDlData([...dlFilteredDataContainer])
        }
    }

    function mergeData() {
        return unitData.map(x => {
            const user = users.find((user) => user.uuid === x.uid)
            x.uid = _.isUndefined(user) ? x.uid : user.displayName;
            
            return {
                ...x,
            }
        });
    }

    const generatePDFs = () => {
        let localContainer= [];
        for(let i = 0; i < FilteredDlData.length; i++){
            let container = 
                <Document>
                    <Page size="A4" style={styles.page} wrap>

                        <View style={styles.section}>
                            <Text>Unit # {FilteredDlData[i].unitNumber}</Text>
                            <Text>{FilteredDlData[i].uid }</Text>
                            <Text>{moment.utc(FilteredDlData[i].date).format('YYYY-MM-DD')}</Text>
                        </View>

                        <View style={styles.summary} wrap={false}>

                            <View style={{ ...styles.table, marginRight: 5 }}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Mileage In</Text>
                                    <Text style={styles.tableText}>{FilteredDlData[i].mileageIn}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Mileage Out</Text>
                                    <Text style={styles.tableText}>{FilteredDlData[i].mileageOut}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Fuel Added</Text>
                                    <Text style={styles.tableText}>{FilteredDlData[i].fuelAdded ? FilteredDlData[i].fuelAdded : 0} {FilteredDlData[i].fuelUnitDisplay}</Text>
                                </View>
                            </View>
                            
                            <View style={{ ...styles.table, marginRight: 5 }}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Trailer ID</Text>
                                    <Text style={styles.tableText}>{FilteredDlData[i].trailerID ? FilteredDlData[i].trailerID : "N/A"}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Greased </Text>
                                    <Text style={styles.tableText}>{FilteredDlData[i].greased ? "Yes" : "No"}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableText}>Odometer Units</Text>
                                    <Text style={styles.tableText}>{FilteredDlData[i].odoUnits ? FilteredDlData[i].odoUnits : "N/A"}</Text>
                                </View>
                            </View>

                        </View>

                        <View style={styles.dTable} wrap={false} key={'vehicleview'}>

                            <View style={styles.section}>
                                <Text>Pre Trip Items</Text>
                            </View>
                            
                            <View style={styles.dTableRow}>
                                <View style={{ ...styles.dTableCol, width: "5%" }}>
                                    <Text style={styles.dTableCell}>Key</Text>
                                </View>
                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                    <Text style={styles.dTableCell}>Defect</Text>
                                </View>
                                <View style={{ ...styles.dTableCol, width: "10%" }}>
                                    <Text style={styles.dTableCell}>No Defect</Text>
                                </View>
                                <View style={{ ...styles.dTableCol, width: "75%" }}>
                                    <Text style={{ ...styles.dTableCell, width: '100%' }}>Item</Text>
                                </View>
                            </View>

                            <View style={{ backgroundColor: 'black', height: 12 }} />
                            {dlData[i].preTripItems.map((item, index) => {
                                return (
                                    <React.Fragment key={'fragment' + index}>
                                        <View style={styles.dTableRow} key={'reportrow' + index}>
                                            <View style={{ ...styles.dTableCol, width: "5%" }}>
                                                <Text style={styles.dTableCell}>{item.key}</Text>
                                            </View>
                                            <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                <Text style={styles.dTableCell}>{item.noDefect ? "" : "X"}</Text>
                                            </View>
                                            <View style={{ ...styles.dTableCol, width: "10%" }}>
                                                <Text style={styles.dTableCell}>{item.noDefect ? "X" : ""}</Text>
                                            </View>
                                            <View style={{ ...styles.dTableCol, width: "75%" }}>
                                                <Text style={{ ...styles.dTableCell, width: '100%' }}>{item.item}</Text>
                                            </View>
                                        </View>

                                        {item.key === 'J'
                                            ? <View style={{ backgroundColor: 'black', height: 12 }} key='blackbar' />
                                            : null}
                                    </React.Fragment>
                                )
                            })}
                        </View>
                    </Page>
                </Document>

            //convert pdfs to blobs and push to localContainer
            const blob = pdf(container).toBlob();
            localContainer.push(blob)
        }
        return localContainer
    }

    const startDateHandler = date => {
        setStartDate(date)
     //   setErrorMessage(null)
      //  if(endDate.diff(startDate, 'd') > 60){
      //      setErrorMessage('Warning: Queries over 2 months may not save to your browser')  
      //  }
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
      //  setErrorMessage(null)
       // if(endDate.diff(startDate, 'd') > 60){
      //      setErrorMessage('Warning: Queries over 2 months may not save to your browser')  
      //  }
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const searchHandler = (event) => {
        setSearch({ ...searchState, [event.target.name]: event.target.value })
        if(event.target.name === "type") sessionStorage.setItem("unitOptionsSearchType", JSON.stringify(event.target.value));
        if(event.target.name === "value") sessionStorage.setItem("unitOptionsSearchValue", JSON.stringify(event.target.value))
        if(event.target.value === "employee") sessionStorage.removeItem("unitOptionsSearchValue", setSearch({value: ''}))
        if(event.target.value === "unitNumber") sessionStorage.removeItem("unitAutoComplete", setAutoCompleteValue(''))
    }

    const clearSearch = () => {
        setSearch({ ...searchState, value: '' });
        sessionStorage.removeItem("unitOptionsSearchValue");
    }

    const unitClickHandler = path => {
        props.unitClickHandler(path);
    }
    
    async function dateCompat() {
        let query = firestore.collection('units')

        let uid = users.find(user => user.displayName === autoCompleteValue)?.uuid
        if (uid) query = query.where('uid', '==', uid)

        query.orderBy('date')

        const oldDate = query.where("date", ">=", startDate.startOf('day').unix()).where("date", "<=", endDate.endOf('day').unix()).get()
        const newDate = query.where("date", ">=", startDate.startOf('day').utc().valueOf()).where("date", "<=", endDate.endOf('day').utc().valueOf()).get()

        const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]).catch((err) => console.log(err))

        const oldDateArray = oldDateSnapshot.docs
        const newDateArray = newDateSnapshot.docs
        return _.concat(oldDateArray, newDateArray)
    }

    const generateHandler = () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
        sessionStorage.removeItem("defectData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");

       
        
        setErrorMessage(null);
        if (endDate.isBefore(startDate)) {
            setErrorMessage('Start Date later than End Date!')
            return
        }

        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            return
        }

        setLoading(true);

        dateCompat().then(querySnapshot => {
            let unitResponse = querySnapshot.map((doc) => {
                
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'unitNumber': doc.data().unitNumber ? doc.data().unitNumber : 'N/A',

                }
            })
            
            if (searchState.value !== "") {
                switch (searchState.type) {
                    case 'unitNumber':
                        unitResponse = unitResponse.filter(unit => unit?.unitNumber.includes(searchState.value))
                        break;
                    case 'address':
                        unitResponse = unitResponse.filter(unit => unit?.address.includes(searchState.value))
                        break;
                    default:
                        break;
                }
            }

            setUnitData(unitResponse)
            setLoading(false);
            sessionStorage.setItem("unitData", JSON.stringify(unitResponse))
        }).catch(e => {
            setUnitData([])
            sessionStorage.removeItem("unitData");
            setLoading(false);

            switch(e.name){
                case 'QuotaExceededError':
                    setErrorMessage("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                default:
                    setErrorMessage("Unable to process.");
                    console.log(e.message)
            }
        });

    }
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };

    const error = [checkboxState.unseen, checkboxState.viewed, checkboxState.printed].filter((v) => v).length < 1;
    
    return (
        <React.Fragment>
            <div className={classes.content}
            style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {loading === false ?

                <MaterialTable
                    tableRef={tableRef}

                    columns={[
                        { title: 'Unit #', field: 'unitNumber' },
                        { title: 'Trailer ID', field: 'trailerID', render: rowData => rowData.trailerID !== "" ? rowData.trailerID : "N/A", exportTransformer: rowData => rowData.trailerID !== "" ? rowData.trailerID : "N/A" },
                        { title: 'Employee', field: 'uid' },
                        { title: 'Date', field: 'date', render: rowData => {
                            return moment.utc(rowData.date).format('YYYY-MM-DD')
                            } , exportTransformer: rowData => moment.utc(rowData.date).format('YYYY-MM-DD')
                        },
                        { title: 'Mileage In', field: 'mileageIn' },
                        { title: 'Mileage Out', field: 'mileageOut' },
                        { title: 'Odometer Units', field: 'odoUnits', render: rowData => rowData.odoUnits !== "" ? rowData.odoUnits : "N/A", exportTransformer: rowData => rowData.odoUnits !== "" ? rowData.odoUnits : "N/A" },
                        { title: 'Fuel Added', field: 'fuelAdded', render: rowData => rowData.fuelAdded ? rowData.fuelAdded + " "+rowData.fuelUnitDisplay : 0 + " "+rowData.fuelUnitDisplay, exportTransformer: rowData => rowData.fuelAdded ? rowData.fuelAdded + " "+rowData.fuelUnitDisplay : 0 + " "+rowData.fuelUnitDisplay },
                        { title: 'Greased?', field: 'greased', render: rowData => rowData.greased === true ? <CheckCircleOutlineIcon /> : "", exportTransformer: rowData => rowData.greased === true ? "Yes" : "No" },
                        
                    ]}
                    data={mergeData()}
                    title='Units'
                    icons={TableIcons}
                    isLoading={loading}
                    onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("unitSearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("unitReportRowsPerPage", JSON.parse(num))}}
                    actions={[
                        {
                            icon: TableIcons.OpenInNew,
                            tooltip: 'View Unit',
                            onClick: (event, rowData) => unitClickHandler(rowData['docId']),
                        },
                    ]}
                    onPageChange={ (pageNum) => { sessionStorage.setItem("unitReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        pageSize: "unitReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("unitReportRowsPerPage")) : 20,
                        initialPage: "unitReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("unitReportPageValue")) : 0,
                        toolbar: true,
                        columnsButton: true,
                        paging: true,
                        searchText: searchBarValue,
                        exportMenu: [
                            {
                                label: "Export CSV",
                                exportFunc: (cols, datas) => ExportCsv(cols, mergeData(), `UnitReportData`),
                            },
                            {
                                label: "Export PDF",
                                exportFunc: (cols, datas) => ExportPdf(cols, mergeData(), `UnitReportData`),
                                },
                        ],
                    }}
                />
                : <Skeleton variant='rect' width={'80vw'} height={'135vh'}/>}
                {smallScreen ?
                <div>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon style={{fontSize: '50px'}} />
                </IconButton>
                <Collapse orientation="horizontal" in={expanded} timeout="auto" unmountOnExit>
                    <Paper className={classes.sidebar}>
                        <ReportSelect
                            reportType={props.reportType}
                            onchange={props.onchange}
                        />
                        <UnitOptions
                            checkboxState={checkboxState} handler={checkboxHandler}
                            searchState={searchState} searchHandler={searchHandler}
                            clearSearch={clearSearch}
                            startDate={startDate} startDateHandler={startDateHandler}
                            endDate={endDate} endDateHandler={endDateHandler}
                            searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                            generateHandler={generateHandler}
                            errorMsg={errorMessage}
                            loading={loading}
                            error={error}
                            handleOpenDownloadModal={handleOpenDownloadModal}
                        />
                    </Paper>
                </Collapse>
                </div>

                :
                <React.Fragment><IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon style={{fontSize: '50px'}} />
                </IconButton>
                <Collapse orientation="horizontal" in={expanded} timeout="auto" unmountOnExit>
                    <Paper className={classes.sidebar}>
                        <ReportSelect
                            reportType={props.reportType}
                            onchange={props.onchange}
                        />
                        <UnitOptions
                            checkboxState={checkboxState} handler={checkboxHandler}
                            searchState={searchState} searchHandler={searchHandler}
                            clearSearch={clearSearch}
                            startDate={startDate} startDateHandler={startDateHandler}
                            endDate={endDate} endDateHandler={endDateHandler}
                            searchValue={autoCompleteValue} searchValueChanged={setAutoCompleteValue}
                            generateHandler={generateHandler}
                            errorMsg={errorMessage}
                            loading={loading}
                            error={error}
                            handleOpenDownloadModal={handleOpenDownloadModal}
                        />
                    </Paper>
                </Collapse> </React.Fragment>}
            </div>
            
            {/* download button modal *************************************/}
            <Modal
            open={unitModalOpen}
            onClose={ () => {handleCloseDownloadModal()}}
            className={classes.downloadModal}
            >
                <Grid className={classes.scroll}>
                    <Grid container style={{backgroundColor: 'white', position: 'fixed', height: '75px', width: '800px', borderBottom: '2px solid', zIndex: '1000'}}>
                        <Grid item xs={6}>
                            <Typography style={{margin: '23px 15px 15px 15px', fontWeight: 500}}>
                                {FilteredDlData.length !== dlData.length ? FilteredDlData.length + "/" + dlData.length +  " PDF'S Selected" : FilteredDlData.length +  " PDF'S Selected"}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button style={{float: 'right', margin: '15px 15px 15px 15px',}} variant='contained' startIcon={<GetAppIcon />} onClick={ () => {handleDownload() }}>Download Selected</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button style={{float: 'right', margin: '15px 30px 15px 15px',}} variant='contained' startIcon={<CloseIcon />} onClick={() => {handleCloseDownloadModal()}}>Close</Button>
                        </Grid>
                    </Grid>
                
                    <Grid>  
                        <Paper style={{width: '800px', paddingTop: '75px', display: 'flex', flexWrap: 'wrap'}}> 
                        { dlData.map((unit, index) => {
                             return (
                                <Grid key={index} >
                                    <Paper className={classes.dlpaper} >
                                        <Grid container>
                                            <Grid item xs={10} style={{display: 'flex', justifyContent: 'center', margin: '5px 0px 5px 0px', position: 'relative', left: '25px' }}>
                                                <Typography>{unit.unitNumber + ' ' + unit.uid + ' ' + moment.utc(unit.date).format('YYYY/MM/DD')}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>     
                                                <Checkbox 
                                                style={{position: 'relative', left: '10px'}}
                                                onChange={(event) => { handleCheckboxChange(event, unit) }}
                                                checked={(FilteredDlData.map(x => (x.docId)).includes(unit.docId) ) ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                             )
                         })
                        }
                        </Paper>
                    </Grid> 
                </Grid>
            </Modal>

        </React.Fragment>
    )
}

export default UnitReport