import React, { useEffect, useState } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Grid, Paper, Typography, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { storage } from '../../../../firebase/firebase';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import InlineMediaViewer from '../../UI/MediaViewer/InlineMediaViewer';
import Dropzone from 'react-dropzone'
import { firestore, removeFile } from '../../../../firebase/firebase'


const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    weatherTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    },
    end: {
        justifyContent: 'flex-end',
    },
    temp: {
        justifyContent: 'center',
        border: '4px solid white',
        borderRadius: '64px',
    },
    weatherCard: {
        backgroundColor: 'rgb(59, 98, 177)',
        boxShadow: 'inset 0px 0px 2px 2px rgba(0,0,0,0.3)',
    },
}));

const DocumentCard = (props) => {

    const classes = useStyles();
    
    const [delModal, setDelModal] = useState(false);
    //const [newPhotos, setNewPhotos] = useState([]);
    const [loadingPhoto, setLoadingPhoto] = useState('')

    const today = moment().format('YYYY-MM-DD hh:mm:ss.SSS');
    let documentCard = null;

    const handleAddPhotos = async (acceptedFiles) => {
        setLoadingPhoto(true)
        //check file type
        //if file type is not png, jpeg, jpeg, bmp, gif or tiff, return
        const fileTypes = ["image/png", "image/jpeg", "image/jpg", "image/bmp", "image/gif", "image/tiff"];
        acceptedFiles.forEach((file) => {
            if (!fileTypes.includes(file.type)) {
                return;
            }
        });
        //setNewPhotos((prevPhotos) => [...prevPhotos, ...acceptedFiles]);
        const storageRef = storage.ref();
        acceptedFiles.forEach(async (file, index) => {
          const path = storageRef.child(
            `FormPhotos/${props.documentData.name}/${props.documentData.uid}-${today}/${file.name}`
          );
          await path.put(file)
          //await uploadFile(path, file);
          const url = await path.getDownloadURL();
          let urls = [];
            if (props.documentData.photoUrls) {
                //for each photoUrl in photoUrls, push it to urls
                props.documentData.photoUrls.forEach((photoUrl) => {
                    urls.push(photoUrl);
                });
            }
          urls.push(url)
          
          firestore
            .collection("documents")
            .doc(props.documentData.docId)
            .update({ photoUrls: urls })
            .then(() => {
                setLoadingPhoto(false)
                console.log("photoUrls updated");
            });
        });
      };


      ///PDF == PAINFUL DOCUMENT FORM
      /*

      AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA


      */

    const openDelModal = () => {
        setDelModal(true)
    }
    const closeDelModal = () => {
        setDelModal(false)
    }

    const handleBackClick = () => {
        props.history.goBack()
    }

    const handleDeletePhoto = async (url) => {
        await removeFile(url);
        const urls = props.documentData.photoUrls.filter((photoUrl) => photoUrl !== url);
        firestore
            .collection("documents")
            .doc(props.documentData.docId)
            .update({ photoUrls: urls })
            .then(() => {
                console.log("photoUrls updated");
            });
    };

    if (!_.isUndefined(props.documentData)) {
        documentCard = (
            <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                       onClick={() => handleBackClick()} >Back</Button>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: '32px' }}>
                            <Grid container>
                                <Grid item xs={12} className={classes.row}>
                                    <Typography variant='h4'>{props.documentData.name}</Typography>
                                </Grid>
                            </Grid>

                            <Typography variant='h6'>{props.userData[0]?.displayName}</Typography>
                            <Typography variant='h6'>{moment(props.documentData?.dateSubmit).format('MMM Do YYYY')}</Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                            {props.documentData?.jobID?.length >=1 && props.documentData?.jobID !== undefined ? <Button variant='contained' onClick={() =>
                            props.history.push({
                                pathname: '/dashboard/reporting/timesheets/job',
                                search: "?" + new URLSearchParams({ docId: props.documentData.jobID }).toString(),
                    
                            })}>View Related Job</Button> : null}
                            {props.documentData.timesheetID.length >=1 && props.documentData.timesheetID !== undefined ? <Button variant='contained' onClick={() => 
                            props.history.push({
                                pathname: '/dashboard/reporting/timesheets',
                                search: "?" + new URLSearchParams({ docId: props.documentData.timesheetID }).toString(),
                    
                            })}>View Related Timesheet</Button> : null}
                        </Grid>

                        {(props.documentData.current && props.documentData.gathersWeatherData) ?
                            <Grid item xs={12}>
                                <Paper elevation={1} className={[classes.card, classes.weatherCard].join(' ')}>
                                    <Grid container spacing={3} >
                                        <Grid item xs={4} className={classes.row}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Cloud Coverage: {props.documentData?.current.cloud}%</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Humidity: {props.documentData?.current.humidity}%</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Precipitation: {props.documentData?.current.precip_mm}mm</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4} className={[classes.row, classes.temp].join(' ')}>
                                            <Grid container>
                                                <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                                    <Typography variant='body1' style={{ color: 'white' }}>{props.documentData?.current.condition.text}</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                                    <img alt="" src={props.documentData.current ? props.documentData.current.condition.icon : ""} />
                                                    <Typography variant='h3' style={{ color: 'white' }}> &nbsp;{props.documentData?.current.temp_c}°C</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4} className={classes.row}>
                                            <Grid container>
                                                <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Wind: {props.documentData?.current.wind_kph}km/h {props.documentData?.current.wind_dir}</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Visibility: {props.documentData?.current.vis_km}km</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Pressure: {props.documentData?.current.pressure_in}Nm</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                            <Typography variant='h6' style={{ color: 'white' }}>Weather Data Created @{props.documentData?.current.last_updated}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            : null}

                        <Grid item xs={9}>
                            <Button style={{marginRight:'5px'}} variant='contained' 
                                    onClick={() => { window.open(props.documentData.pdfURL, "_blank")}} startIcon={<FindInPageIcon/>}>View Form</Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button style={{marginLeft:'30px', color:'white', backgroundColor:'red'}} variant='contained' startIcon={<DeleteIcon/>}
                                    onClick={() => { 
                                        //delete form
                                        openDelModal()
                                    }} >Delete Form</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'50px'}}>
                                <iframe
                                    src={props.documentData.pdfURL}
                                    title="PDF Viewer"
                                    width="1000px"
                                    height="800px"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Divider style={{margin:'15px'}}/>

                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: '32px' }}>

                        {loadingPhoto ?
                        <Grid container style={{border:'2px dashed lightGrey', minHeight:'100px'}}>
                            <Grid container justifyContent='center'><Typography variant='h6' style={{color:'lightGrey'}}>Processing...</Typography> </Grid>
                        </Grid>
                        :
                        <Dropzone onDrop={acceptedFiles => handleAddPhotos(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                              
                                <Grid container justifyContent='center' {...getRootProps()} style={{border:'2px dashed lightGrey', minHeight:'100px'}}>
                                    <input {...getInputProps()} />
                                    {/* {newPhotos.length > 0 ? (
                                        <Grid container spacing={2}>
                                        {newPhotos.map((photo) => (
                                            <Grid item key={photo.name}>
                                            <img src={URL.createObjectURL(photo)} alt={photo.name} style={{ height: "80px" }} />
                                            </Grid>
                                        ))}
                                        </Grid>
                                    ) : (
                                        <Typography justifyContent='center' variant='h6' style={{color:'lightGrey'}}>Drop Photos here to add them to this report</Typography>
                                    )} */}

                                    <Typography justifyContent='center' variant='h6' style={{color:'lightGrey'}}>Drop Photos here to add them to this report</Typography>
                                </Grid>
                             
                            )}
                        </Dropzone>
                        }
                        </Grid>


                    </Grid>
                    {props.documentData?.photoUrls?.length > 0 ? <React.Fragment>
                    <Typography variant='h6'>Photos Included</Typography>
                    <Grid>
                        
                        
                        <InlineMediaViewer photoURLs={props.documentData.photoUrls} />

                        {props.documentData?.photoUrls?.length > 0 ?
                            <Grid container spacing={2}>
                                {props.documentData.photoUrls.map((photo) => (
                                    <Grid item key={photo}>
                                        <IconButton>
                                            <DeleteIcon onClick={() => { handleDeletePhoto(photo) }} />
                                        </IconButton>
                                        <img src={photo} alt={photo} style={{ height: "80px", width:'80px' }} />
                                    </Grid>
                                ))}
                            </Grid> : null}
                            

                    </Grid></React.Fragment> : null}
                </Paper>
            </Grid>
        )
    }

    return (
        <div className={classes.content}>
             <Dialog fullWidth
                keepMounted
                open={delModal}
                onClose={closeDelModal}
            >
                <DialogTitle id="alert-dialog-title">Delete Form</DialogTitle>

                <DialogContent>
                    <Typography>Are you sure you want to delete the form?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => props.deleteDocument(props.documentData.docId)}>Delete</Button>
                    <Button variant="contained" color="secondary" onClick={() => closeDelModal()}>Cancel</Button>
                </DialogActions>
            </Dialog>
            {documentCard}
        </div>
    )
}

export default withRouter(DocumentCard)