import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment'
import MaterialTable  from '@material-table/core';
import { TableIcons } from '../../../../constants/tableIcons';
import { firestore } from '../../../../firebase/firebase'
import DocumentOptions from '../../../components/Reports/Documents/DocumentOptions'
import { Grid, Paper, Typography, IconButton, Modal, Checkbox, Button } from '@material-ui/core';
import ReportSelect from '../../../components/Reports/ReportSelect'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import _ from 'lodash'
import clsx from 'clsx';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useUser } from '../../../../providers/UserContext'
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import useAsyncError from '../../../components/UI/AsyncError/AsyncError';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },
    downloadModal: {
        marginRight: '60px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingBottom: '100px',
        marginTop: '50px',
    },
    dlpaper: {
        width: '390px',
        margin: '5px 5px 5px 5px',
    },
}));

const DocumentsReport = (props) => {
    const { users, getUsers } = useUser();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const throwAsyncError = useAsyncError();
    const tableRef = useRef(MaterialTable);

    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [documentData, setDocumentData] = useState(JSON.parse(sessionStorage.getItem("documentData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [filteredData, setFilteredData] = useState(JSON.parse(sessionStorage.getItem("filteredDocuments")) || []);
    const [searchBarValue, setSearchBarValue] = useState("documentSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("documentSearchBarValue")) : null)
    const [searchUsers, setSearchUsers] = useState("documentSearchUsers" in sessionStorage ? JSON.parse(sessionStorage.getItem("documentSearchUsers")) : [])
    const [searchTitles, setSearchTitles] = useState("documentSearchTitles" in sessionStorage ? JSON.parse(sessionStorage.getItem("documentSearchTitles")) : [])
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const [dlModalOpen, setDlModalOpen] = useState(false)
    const [dlData, setdlData] = useState([])
    const [FilteredDlData, setFilteredDlData] = useState([])
    
    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [smallScreen]);

    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const documentsClickHandler = path => {
        props.documentsClickHandler(path);
    }

    async function dateCompat() {
        let query = firestore.collection('documents')

        query.orderBy('submitTime')

        const oldDate = query.where("date", ">=", startDate.startOf('day').toDate()).where("date", "<=", endDate.endOf('day').toDate()).get()
        const newDate = query.where("date", ">=", startDate.startOf('day').utc().valueOf()).where("date", "<=", endDate.endOf('day').utc().valueOf()).get()

        const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]).catch((err) => console.log(err))

        const oldDateArray = oldDateSnapshot.docs
        const newDateArray = newDateSnapshot.docs

        return _.concat(oldDateArray, newDateArray)
    }

    const generateHandler = () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        sessionStorage.removeItem("filteredOrderData");
        sessionStorage.removeItem("documentData");
        setErrorMessage(null);
        if (endDate.isBefore(startDate)) {
            setErrorMessage('Start Date later than End Date!')
            return
        }
        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            return
        }

        setLoading(true);

        dateCompat().then(querySnapshot => {
            let documentResponse = querySnapshot.map((doc) => {

                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'dateSubmit' : doc.data().date.toDate().toDateString(),
                    'uid': users.find(user => user.uuid === doc.data().uid)?.displayName || doc.data().uid,
                }
            })
            documentResponse = documentResponse.filter((doc) => doc.pdfURL !== '');
            setDocumentData(documentResponse)

            setLoading(false);
            sessionStorage.setItem("documentData", JSON.stringify(documentResponse))
        }).catch(e => {

            setDocumentData([]);
            sessionStorage.removeItem("documentData");
            setLoading(false);
            switch(e.name){
                case 'QuotaExceededError':
                    setErrorMessage("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                default:
                    setErrorMessage("Unable to process.");
                    console.log(e.message);
            }
        });

    }

    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };

    //live filter
    useEffect(() => {
        if (!documentData) return;
        let expandedDocuments = [];
        // Remove duplicates from the expanded list
        expandedDocuments = Array.from(new Set(documentData));
        //check for displayNames in searchUsers
        if(searchUsers.length > 0){expandedDocuments = expandedDocuments.filter(data => searchUsers.includes(data.uid)) }
        if(searchTitles.length > 0){expandedDocuments = expandedDocuments.filter(data => searchTitles.includes(data.name)) }
    
        // Update state and session storage
        setFilteredData(expandedDocuments);
        sessionStorage.setItem("documentSearchUsers", JSON.stringify(searchUsers));
        sessionStorage.setItem("documentSearchTitles", JSON.stringify(searchTitles));
        sessionStorage.setItem("filteredDocuments", JSON.stringify(expandedDocuments));
        // eslint-disable-next-line
    }, [documentData, searchUsers, searchTitles]);

    const handleOpenDownloadModal = () => {
        setdlData([...tableRef.current.state.data.map(unit => unit)])
        setFilteredDlData([...tableRef.current.state.data.map(unit => unit)])
        setDlModalOpen(true);
    }

    const handleCloseDownloadModal = () => {
        setdlData([])
        setFilteredDlData([])
        setDlModalOpen(false);
    }

    const handleCheckboxChange = (event, unit) => {
        if(event.target.checked){
            let dlFilteredDataContainer = FilteredDlData;
            dlFilteredDataContainer.push(unit)
            setFilteredDlData([...dlFilteredDataContainer])
        }
        else{
            let dlFilteredDataContainer = FilteredDlData;
            dlFilteredDataContainer.splice(FilteredDlData.map(x=> x.docId).indexOf(unit.docId) , 1)
            setFilteredDlData([...dlFilteredDataContainer])
        }
    }

    const handleDownload =  () => {
        let promises = [];
        for(let i = 0; i < FilteredDlData.length; i++){
            promises.push(URLToBlob(FilteredDlData[i].pdfURL))
        }

        Promise.all(promises).then(async (res) => {
            var zip = new JSZip();
            var zipFilename = "Documents-" + moment().valueOf() + ".zip";
    
            //generating content
            res.map((pdf, index) =>{
                let name = FilteredDlData[index].name + '_' + FilteredDlData[index].uid + '_' + moment.utc(FilteredDlData[index].date).format('YYYY-MM-DD') + '_(' + index + ')'
                return zip.file(name + ".pdf", pdf, { blob: true });
            });
    
            //zip folder and save as
            zip.generateAsync({type:'blob'}).then(function(content) { saveAs(content, zipFilename);});
        })
    }

    const URLToBlob = (url) => {
        return new Promise(resolve => {
            fetch(url).then(async (response) => {
                if(response.ok){
                    response.blob().then((file) => {
                        resolve(file)
                    })
                }
                else{
                    throwAsyncError(new Error(`Failed to fetch pdf from url: ${url}.`));
                }
            })
        })
    }

    return (
        <React.Fragment>
            {/* download button modal */}
            <Modal
            open={dlModalOpen}
            onClose={ () => {handleCloseDownloadModal()}}
            className={classes.downloadModal}
            >
                <Grid className={classes.scroll}>
                    <Grid container style={{backgroundColor: 'white', position: 'fixed', height: '75px', width: '800px', borderBottom: '2px solid', zIndex: '1000'}}>
                        <Grid item xs={6}>
                            <Typography style={{margin: '23px 15px 15px 15px', fontWeight: 500}}>
                                {FilteredDlData.length !== dlData.length ? FilteredDlData.length + "/" + dlData.length +  " PDF'S Selected" : FilteredDlData.length +  " PDF'S Selected"}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button style={{float: 'right', margin: '15px 15px 15px 15px',}} variant='contained' startIcon={<GetAppIcon />} onClick={ () => {handleDownload() }}>Download Selected</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button style={{float: 'right', margin: '15px 30px 15px 15px',}} variant='contained' startIcon={<CloseIcon />} onClick={() => {handleCloseDownloadModal()}}>Close</Button>
                        </Grid>
                    </Grid>
                
                    <Grid>  
                        <Paper style={{width: '800px', paddingTop: '75px', display: 'flex', flexWrap: 'wrap'}}> 
                        { dlData.map((document, index) => {
                             return (
                                <Grid key={index} >
                                    <Paper className={classes.dlpaper} >
                                        <Grid container>
                                            <Grid item xs={10} style={{display: 'flex', justifyContent: 'center', margin: '5px 0px 5px 0px', position: 'relative', left: '25px' }}>
                                                <Typography>{document.name + ' ' + document.uid}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>     
                                                <Checkbox 
                                                style={{position: 'relative', left: '10px'}}
                                                onChange={(event) => { handleCheckboxChange(event, document) }}
                                                checked={(FilteredDlData.map(x => (x.docId)).includes(document.docId) ) ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                             )
                         })
                        }
                        </Paper>
                    </Grid> 
                </Grid>
            </Modal>

            <div className={classes.content} style={expanded ? {paddingRight: '260px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >
                {loading === false ?
                <MaterialTable
                    tableRef={tableRef}
                    columns={[
                        { title: 'Document', field: 'name' },
                        { title: 'Employee', field: 'uid' },
                        { title: 'Date Submit', field: 'dateSubmit' },
                    ]}
                    data={filteredData}
                    title='Documents'
                    icons={TableIcons}
                    isLoading={loading}
                    onSearchChange={ (value) => {setSearchBarValue(value);  sessionStorage.setItem("documentSearchBarValue", JSON.stringify(value))}}
                    onRowsPerPageChange={(num) => {sessionStorage.setItem("documentReportRowsPerPage", JSON.parse(num))}}
                    actions={[
                        {
                            icon: TableIcons.OpenInNew,
                            tooltip: 'View Document Report',
                            onClick: (event, rowData) => documentsClickHandler(rowData['docId']),
                        },
                    ]}
                    onPageChange={ (pageNum) => { sessionStorage.setItem("documentReportPageValue", JSON.stringify(pageNum)) }}
                    options={{
                        pageSize: "documentReportRowsPerPage" in sessionStorage ? JSON.parse(sessionStorage.getItem("documentReportRowsPerPage")) : 20,
                        initialPage: "documentReportPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("documentReportPageValue")) : 0,
                        toolbar: true,
                        paging: true,
                        columnsButton: true,
                        searchText: searchBarValue,
                    }}
                    detailPanel={[
                        rowData => ({
                          disabled: rowData.additionalDetails ? false : true,
                          tooltip: rowData.additionalDetails ? 'Show Additional Details' : null,
                          icon: () => rowData.additionalDetails ? <InfoIcon /> : null,
                          openIcon: () => rowData.additionalDetails ? <InfoOutlinedIcon />: null,
                          render: () => rowData.additionalDetails && 
                                <React.Fragment>
                                <Grid item xs={6}>
                                    <Typography style={{ lineHeight: 1.5, paddingLeft: '15px' }} variant='h6'>Additional Details</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ lineHeight: 1, padding: '15px' }} variant='body1' align='left'>{rowData.additionalDetails}</Typography>
                                </Grid>
                                </React.Fragment>
                        })
                    ]}           
                />
                : <Skeleton variant='rect' width={'80vw'} height={'160vh'}/>}
                {smallScreen ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" unmountOnExit>
                        <Paper className={classes.sidebar}>
                            <ReportSelect
                                reportType={props.reportType}
                                onchange={props.onchange}
                            />
                            <DocumentOptions
                                documentData={documentData}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                searchUsers={searchUsers} setSearchUsers={setSearchUsers}
                                searchTitles={searchTitles} setSearchTitles={setSearchTitles}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                            />
                        </Paper>
                    </Collapse>
                </div>
                :
                <Paper className={classes.sidebar}>
                    <ReportSelect
                        reportType={props.reportType}
                        onchange={props.onchange}
                    />
                    <DocumentOptions
                        documentData={documentData}
                        startDate={startDate} startDateHandler={startDateHandler}
                        endDate={endDate} endDateHandler={endDateHandler}
                        searchUsers={searchUsers} setSearchUsers={setSearchUsers}
                        searchTitles={searchTitles} setSearchTitles={setSearchTitles}
                        generateHandler={generateHandler}
                        errorMsg={errorMessage}
                        loading={loading}
                        handleOpenDownloadModal={handleOpenDownloadModal}
                    />
                </Paper> }
            </div>
        </React.Fragment>
    )
}

export default DocumentsReport