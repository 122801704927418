import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import CustomerCard from '../../components/Reports/Customers/CustomerCard'
import _ from 'lodash'
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useAuth } from '../../../providers/AuthContext';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        paddingTop: '80px',
    },
}));

const Customer = (props) => {

    const classes = useStyles();
    const { currentUser, userInfo } = useAuth();
    //Customer needs to get all reported data to display
    const [customerData, setCustomerData] = useState({});
    const [jobData, setJobData] = useState([]);
    const [unitJobData, setUnitJobData] = useState([]);
    //const [deliveryData, setDeliveryData] useState([]);

    //Firestore load handling
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    //Save and Editing customerData management
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    const updateCustomerData = useCallback((e) => {
        setCustomerData(e)
    },
        [],
    );
    let path = new URLSearchParams(props.location.search).get("docId");
    const deleteCustomer = () => {
        
            //find all Deliveries that have customerID as this customer docId
            firestore.collection('grainOrders').where('customer', '==', customerData.docId).get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    firestore.collection('grainOrders').doc(doc.id).update({customer: 'X1NI49RqxhUeughuiMGF'})
                    firestore.collection('grainOrders').doc(doc.id).update({notes: doc.data().notes + 'Deleted Customer: ' + customerData.docId + ' ' + customerData.name + ' ' + customerData.streetNumber + ' ' + customerData.streetName})
                })
            })

            firestore.collection('deliveries').where('customerID', '==', customerData.docId).get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //set deliveries customerId to X1NI49RqxhUeughuiMGF
                    firestore.collection('deliveries').doc(doc.id).update({customerID: 'X1NI49RqxhUeughuiMGF'})
                    //concat the old customer docId, name and address to the deliveries notes
                    firestore.collection('deliveries').doc(doc.id).update({notes: doc.data().notes + 'Deleted Customer: ' + customerData.docId + ' ' + customerData.name + ' ' + customerData.streetNumber + ' ' + customerData.streetName})
                })
            })
            firestore.collection('customers').doc(customerData.docId).delete()
            .then(() => {
                firestore.collection('notifications').add({
                    'type': 'delete',
                    'message': 'Customer: '+customerData.docId+' has been deleted by '+userInfo.firstName + ' ' + userInfo.lastName,
                    'userEmail': currentUser.email,
                    'userName': userInfo.firstName + ' ' + userInfo.lastName,
                    'timestamp': new Date(),
                    'reason': 'Customer Deletion',
                })
                console.log("Customer successfully deleted!");
                props.history.replace({pathname: '/dashboard/customers'})
            }).catch((error) => {
                console.error("Error removing customer: ", error);
            })
        }

    const saveCustomerData = () => {
        setSaving(true);
        firestore.collection('customers').doc(customerData.docId)
            .update(customerData)
            .then(() => {
                setSaveState(customerData);
                setSaving(false)
            }).catch((e) => setError(e));
    };

    const confirmSave = () => {
        setSaving(true);
        firestore.collection('customers').doc(customerData.docId)
            .update(customerData)
            .then(() => {
                setSaveState(customerData);
                setSaving(false)
            })
            .catch((e) => setError(e));
    };

    const cancelSave = () => {
        setCustomerData(saveState);
    };

    useEffect(() => {
        
        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('customers').where('__name__', "==", path).get()
            .then(querySnapshot => {
                let customerResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                        'isActive': doc.data().isActive ? doc.data().isActive : false,
                        'gstRegNum': doc.data().gstRegNum ? doc.data().gstRegNum : '',
                        'customerType': doc.data().customerType ? doc.data().customerType : 'Customer',
                        'retailSalesTaxNum': doc.data().retailSalesTaxNum ? doc.data().retailSalesTaxNum : '',
                        'deliveryAddress': doc.data().deliveryAddress ? doc.data().deliveryAddress : 
                        {'streetNumber': doc.data().streetNumber, 'streetName': doc.data().streetName, 'city': doc.data().city, 'province': doc.data().province, 'postalCode': doc.data().postalCode},
                        'contacts': doc.data().contacts ? doc.data().contacts : [
                            { 'id': 0, 'name': 'Sample Contact', 'phone': '000-000-0000', 'description': 'Home', 'email': 'emailaddress@email.ca'  },
                        ],
                        'rates': doc.data().rates ? doc.data().rates : [
                            { 'id': 0, 'name': 'Sample Rate', 'rate': 0.00, 'per': 'Hourly', 'typeofrate': 'Cartage Rate' },
                        ],  
                        // 'addressBook': doc.data().addressBook ? doc.data().addressBook : [],
                        // 'isParent': doc.data().isParent ? doc.data().isParent : false,
                        // 'isChild': doc.data().isChild ? doc.data().isChild : false,
                    }
                })     
                
                setCustomerData(customerResponse[0]);
                setSaveState(customerResponse[0]);
               
                //firestore.collection('jobs').where('customer', "==", customerResponse[0].name)
                firestore.collection('jobs').where('address', "==", customerResponse[0].streetNumber + ' ' + customerResponse[0].streetName).get()
                    .then(async (querySnapshot) => {
                        const jobResponse = querySnapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                            }
                        })
                        setJobData(jobResponse);
                        
                        let jobUnits = _.flatten(jobResponse.map(job => job.units))

                        let remainingUnits = jobUnits.length
                        let querySnapshots = [];
                        let query;

                        while (remainingUnits > 0) {
                            const queryLimit = 10;
                            let queryStartPos = remainingUnits - queryLimit >= 0 ? remainingUnits - queryLimit : 0;

                            query = await firestore.collection('units').where('docId', "in", jobUnits.slice(queryStartPos, remainingUnits)).get();
                            querySnapshots.push(query);

                            remainingUnits -= queryLimit;

                            let units = [];

                            querySnapshots.forEach(unitResponse => {
                                units = units.concat(unitResponse.docs.map((doc) => { return { ...doc.data() } }))
                            });

                            setUnitJobData(units);
                        }
                    })
                
                firestore.collection('customers').doc(customerResponse[0].docId)
                    .update(customerResponse[0]).then(() => {

                    setLoading(false);
                    })
            })
            .catch(e => {
                setError("Unable to create customer.");
                setLoading(false);
                console.log(e.message);
            })
           
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    return (
        <div>
            {loading
                ? 
                <Grid className={classes.content}>
                    <Skeleton variant='rect' width={'50vw'} height={'25vh'}/>
                </Grid>
                : 
                <CustomerCard
                    deliveryData={[]} 
                    unitData={[]}
                    unitDeliveryData={[]}
                    customerData={customerData} 
                    setCustomerData={setCustomerData} 
                    saveState={saveState} 
                    setSaveState={setSaveState}
                    jobData={jobData} 
                    unitJobData={unitJobData}
                    loading={loading}
                    cancelSave={cancelSave} 
                    saveCustomerData={saveCustomerData}
                    updateCustomerData={updateCustomerData} 
                    saving={saving}
                    confirmSave={confirmSave} 
                    error={error} 
                    deleteCustomer={deleteCustomer}
                />}
        </div>
    )
}

export default withRouter(Customer);