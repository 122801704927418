import React, { useState, useEffect } from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import DefectCard from '../../../components/Reports/Defects/DefectCard'
import { useUser } from '../../../../providers/UserContext';
import { Paper, Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '42px',
    },
}));

const Defect = (props) => {
    const [defectData, setDefectData] = useState({});
    const [unitData, setUnitData] = useState({});
    const [unitLoading, setUnitLoading] = useState(true);
    const [defectLoading, setDefectLoading] = useState(true);
    const [errorMessage, setError] = useState("");

    const { loading, getUsers, getUser } = useUser();

    const classes = useStyles();

    useEffect(() => {
        getUsers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('defectReports').doc(path).get()
            .then(doc => {
                if (doc.exists) {
                    setDefectData({ ...doc.data(), 'docId': doc.id })
                } else {
                    setError('Defect Report does not exist')
                }
            }).catch(e => {
                setError(e.message)
                console.log(e.message);
            }).finally(() => {
                setDefectLoading(false)
            })

        firestore.collection('units').where("defectID", "==", path).get()
            .then(querySnapshot => {
                const unitResponse = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                setUnitData(unitResponse[0])
            }).catch(e => {
                setError("Unable to Process")
                console.log(e.message);
            }).finally(() => setUnitLoading(false))
    }, [props.location.search]);


    const userData = getUser(defectData.uid);

    return (
        <React.Fragment>
            { errorMessage && <Paper>{errorMessage}</Paper>}
            {(loading || defectLoading || unitLoading) 
            ? 
            <Grid className={classes.content}>
                <Skeleton variant='rect' width={'40vw'} height={'55vh'} />
            </Grid>
            : 
            <DefectCard defectData={defectData} userData={userData} unitData={unitData} />}
        </React.Fragment>
    )

}

export default withRouter(Defect)