import React, { useState, useEffect } from 'react';
import { CircularProgress, makeStyles }  from '@material-ui/core';
import DatePicker from '../../UI/DatePicker/DatePicker';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from '@material-ui/lab';
import { useUser } from '../../../../providers/UserContext'
import { TextField, Typography, Button, Grid, Divider, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    detailsPanel: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(14),
        height: '100vh',
        right: 0,
        zIndex: 12,
        overflowY: 'auto',
    },
    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
}));

const DeliveryOptions = (props) => {

    const { users, userLoading, getUsers } = useUser();
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    //Search Autocomplete Values
    const [typedValue, setTypedValue] = useState('')

    useEffect(() => {
        getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={2}>

            <Grid item sm={12}>
                <Grid item sm={12}>
                    <Grid container className={classes.search}>
                        <Grid item sm={12} className={classes.filterItem}>
                            <DatePicker
                                label="Start Date"
                                value={props.startDate}
                                onChange={props.startDateHandler}
                            />
                        </Grid>

                        <Grid item sm={12} className={classes.filterItem}>
                            <DatePicker
                                label="End Date"
                                value={props.endDate}
                                onChange={props.endDateHandler}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sm={12}>
                    
                    {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
                </Grid>
                <Grid item sm={12} className={classes.btnGroup}>
                    <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
                </Grid>

            <Grid item sm={12}>
                <Grid item sm={12}>
                    <Typography variant="h6">Filters</Typography>
                    <Divider  style={{ marginBottom: '10px' }}/>
                </Grid>

                <Grid container className={classes.search}>
                    <Grid item sm={5}>
                        <Typography>Search By</Typography>
                    </Grid>
                    <Grid item sm={7}>
                        <Select
                            name="type"
                            value={props.searchState.type}
                            onChange={props.searchHandler}
                            native>
                            <option value="customer">Customer</option>
                            <option value="ticket">Ticket</option>
                            <option value="employee">Employee</option>
                        </Select>
                    </Grid>
         
                        
                    {props.searchState.type !== "employee" ?
                        <Grid item sm={12}>
                        
                            <TextField
                                label="Search"
                                size="small"
                                value={props.searchState.value}
                                onChange={props.searchHandler}
                                name="value"
                                InputProps={props.searchState.value ? {
                                    endAdornment: <IconButton onClick={props.clearSearch}><ClearIcon /></IconButton>
                                } : null} />
                        </Grid>
                    :            
                        < Grid item sm={12}>       
                            <Autocomplete
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={userLoading}
                                label="Search"
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                inputValue={typedValue}
                                onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                value={props.searchValue}
                                onChange={(event, newValue) => { props.searchValueChanged(newValue); sessionStorage.setItem("deliveryAutoComplete", JSON.stringify(newValue)); }}
                                options={users.filter(x=> x.employment?.active).map((user) => user.displayName ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Employee"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                                )}

                            />

                        </Grid>
                    }
                    
                </Grid>
            </Grid>


        </Grid>
    )
}

export default DeliveryOptions;