import React, { useEffect } from 'react';
import { Divider, makeStyles, Typography, Button, Grid } from '@material-ui/core';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { useUser } from '../../../../providers/UserContext'
import { useForm } from '../../../../providers/DocumentContext';
import OptionsList from '../../UI/SearchFilters/OptionsList/OptionsList';

const useStyles = makeStyles((theme) => ({
    detailsPanel: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(14),
        height: '100vh',
        right: 0,
        zIndex: 12,
        overflowY: 'auto',
    },
    btnGroup: {
        width: '100%',
    },
    filterItem: {
        marginTop: '4px',
        marginBottom: '4px',
    },
    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        //backgroundColor: '#fafafa',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

const DocumentOptions = (props) => {
    const { users, userLoading, getUsers } = useUser();
    const { forms, formLoading, getForms } = useForm();
    const classes = useStyles();

    useEffect(() => {
        getUsers()
        getForms()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="Start Date"
                            value={props.startDate}
                            onChange={props.startDateHandler}
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.filterItem}>
                        <DatePicker
                            label="End Date"
                            value={props.endDate}
                            onChange={props.endDateHandler}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {(props.errorMsg && 
            <Grid item sm={12}>
                <Typography>{props.errorMsg}</Typography>
            </Grid>)}

            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
            </Grid>

            <Grid item sm={12} title={`Real Time Filters:\nThe lists will only populate based on data within the generated date range.`}>
                <Grid item sm={12}>
                    <Typography variant="h6">Filters</Typography>
                    <Divider  style={{ marginBottom: '10px' }}/>
                </Grid>
                <Grid container className={classes.search}>
                    <Grid item sm={12}>
                        <Typography>Document(s) {props.searchTitles.length > 0 && props.searchTitles.length + ' Selected'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <OptionsList 
                            loading={formLoading} list={forms.filter(form => props.documentData?.map(x => x.name).includes(form.name))
                                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                                ?.map((form) => form.name ?? '')}
                            searchItems={props.searchTitles} setSearchItems={props.setSearchTitles}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.search}>
                    <Grid item sm={12}>
                        <Typography>Employee(s) {props.searchUsers.length > 0 && props.searchUsers.length + ' Selected'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <OptionsList 
                            loading={userLoading} list={users.filter(user => props.documentData?.map(x => x.uid).includes(user.displayName))
                                .filter(x=> x.employment?.active)
                                .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
                                .map((user) => user.displayName ?? '')}
                            searchItems={props.searchUsers} setSearchItems={props.setSearchUsers}
                        />
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" fullWidth disabled={props.loading} onClick={() => {props.handleOpenDownloadModal() }} > Download Table </Button>
            </Grid>

        </Grid>
    )
}

export default DocumentOptions;