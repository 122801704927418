import React, { useState, useEffect } from 'react';
import { Modal, Typography, Button, Paper, Fade, Backdrop, Grid, Divider, MenuItem, Select, TextField, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { firestore } from '../../../../firebase/firebase';
import _ from 'lodash';

const HistoricChart = (props) => {
    const [open, setOpen] = useState(props.open);
    const [year, setYear] = useState(new Date().getFullYear());
    const [taxes, setTaxes] = useState({});
    const [saveState, setSaveState] = useState({});
    const [saving, setSaving] = useState(false);

    const handleClose = () => {
        props.setOpen(false);
        setOpen(false);
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        if (!open) return;
      
        firestore
          .collection("shopSettings")
          .doc("taxes")
          .get()
          .then((doc) => {
            if (doc.exists) {
              let response = doc.data();
              const defaultYear = new Date().getFullYear();
      
              // Ensure historicData exists.
              if (!response.historicData) {
                response.historicData = { [defaultYear]: {} };
              } else if (!response.historicData[defaultYear]) {
                response.historicData[defaultYear] = {};
              }
      
              setTaxes(response);
              setSaveState(response);
            } else {
              console.error("Taxes document not found!");
            }
          })
          .catch((error) => {
            console.error("Error fetching taxes:", error);
          });
      }, [open]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const MONTHS = [
        'january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december',
    ];

      useEffect(() => {
        // Make sure historicData exists and that the selected year is set up
        if (!taxes.historicData) return;
      
        if (!taxes.historicData[year]) {
          // Create an empty object for the year if it doesn't exist
          setTaxes((prev) => ({
            ...prev,
            historicData: {
              ...prev.historicData,
              [year]: {},
            },
          }));
          return; // Let the state update and then re-run this effect.
        }
      
        // If the selected year is the current year, check for the current month
        const currentYear = new Date().getFullYear();
        if (Number(year) === currentYear) {
          const currentMonth = MONTHS[new Date().getMonth()];
          if (!taxes.historicData[year][currentMonth]) {
            // Use your current values (ensure these exist on your taxes object)
            const currentCarbonFee = taxes.carbonFee ?? 0;       // Adjust as needed
            const currentFuelSurcharge = taxes.fuelSurcharge ?? 0; // Adjust as needed
      
            setTaxes((prev) => ({
              ...prev,
              historicData: {
                ...prev.historicData,
                [year]: {
                  ...prev.historicData[year],
                  [currentMonth]: {
                    carbonFee: currentCarbonFee,
                    fuelSurcharge: currentFuelSurcharge,
                  },
                },
              },
            }));
          }
        }
      }, [taxes, year, MONTHS]);
      


    const formatMonthForDisplay = (month) => {
        return month.charAt(0).toUpperCase() + month.slice(1);
    };

    const cancelSave = () => {
        setSaving(false);
        setTaxes(saveState);
    };

    const saveData = () => {
        setSaving(true);
        firestore
            .collection("shopSettings")
            .doc("taxes")
            .set({ ...taxes })
            .then(() => {
                console.log("Data saved successfully");
                setSaveState(taxes);
                setSaving(false);
            })
            .catch((error) => {
                console.error("Error saving data:", error);
                setSaving(false);
            });
    };

    const handleDataChange = (month, field, value) => {
        setTaxes((prevTaxes) => {
            const updatedTaxes = {
                ...prevTaxes,
                historicData: {
                    ...prevTaxes.historicData,
                    [year]: {
                        ...prevTaxes.historicData[year],
                        [month]: {
                            ...(prevTaxes.historicData[year]?.[month] || {}),
                            [field]: value,
                        },
                    },
                },
            };
            return updatedTaxes;
        });
    };

    return (
        <React.Fragment>
            <Modal
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 0 }}
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 200 }}
            >
                <Fade in={open}>
                    <Paper style={{ padding: '16px', minWidth: '90%', maxWidth: '90%' }}>
                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Select
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    variant="outlined"
                                    style={{ marginLeft: '16px', fontSize: '30px' }}
                                >
                                    {taxes?.historicData &&
                                        Object.keys(taxes.historicData).map((year, index) => (
                                            <MenuItem key={index} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            {MONTHS.map((month, index) => {
                                return (
                                    <Grid
                                        item
                                        xs={3}
                                        key={index}
                                        style={{
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '2px',
                                        }}
                                    >
                                        <Typography>
                                            <b>{formatMonthForDisplay(month)}</b>
                                        </Typography>
                                        <Divider />
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography style={{marginTop:'18px'}}>Carbon Fee:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    //label="Carbon Fee"
                                                    value={taxes?.historicData?.[year]?.[month]?.carbonFee ?? ''}
                                                    //variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                <small>{taxes?.historicData?.[year]?.[month]?.carbonFee ? (taxes?.historicData?.[year]?.[month]?.carbonFee)*100+'%' : ''}</small>
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    style={{ marginTop: '8px' }}
                                                    onChange={(e) => handleDataChange(month, "carbonFee", e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography style={{marginTop:'18px'}}>Fuel Surcharge:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    //label="Carbon Fee"
                                                    value={taxes?.historicData?.[year]?.[month]?.fuelSurcharge ?? ''}
                                                    //variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                <small>{taxes?.historicData?.[year]?.[month]?.fuelSurcharge ? (taxes?.historicData?.[year]?.[month]?.fuelSurcharge)*100+'%' : ''}</small>
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    style={{ marginTop: '8px' }}
                                                    onChange={(e) => handleDataChange(month, "fuelSurcharge", e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                            <Grid item xs={12} style={{ marginTop: '16px', textAlign: 'center' }}>
                                <Button startIcon={<CloseIcon />} variant="contained" onClick={handleClose}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
            <Snackbar
                open={!_.isEqual(taxes, saveState) && open}
                message={saving ? 'Saving...' : 'Save Data?'}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                action={
                    <React.Fragment>
                        {!saving && (
                            <>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={saveData}>Confirm</Button>
                            </>
                        )}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default HistoricChart;