import _ from "lodash";
import { useEffect, useState } from "react";
import { firestore } from "../../../../firebase/firebase";
import LoadingSpinner from "../../../../Public/components/LoadingSpinner/LoadingSpinner";
import ShopSettingsCard from "../../../components/Shop/ShopSettings/ShopSettingsCard";

const ShopSettings = (props) => {

    const [shopSettings, setShopSettings] = useState({});
    const [saveState, setSaveState] = useState({});
    const [saveError, setSaveError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true)
    const [documents, setDocuments] = useState({})
    

    useEffect(() => {
        firestore.collection('shopSettings').get()
        .then(querySnapshot => {
            const response = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,
            }
            })
            let documentsContainer = {
                contactInformation: '',
                shopSettings: '',
                socialInformation: '',
                taxes: '',
                datesClosed: '',
            }
            for(let i = 0; i < _.size(response); i++){
                for (const [key] of Object.entries(documentsContainer)){
                    if(response[i].docId.toString() === [key].toString()){
                        documentsContainer = {...documentsContainer, [key]: i}
                    }
                }
            }

            setDocuments({...documentsContainer})
            setShopSettings({...response});
            setSaveState({...response});
        })
        setLoading(false);
        //eslint-disable-next-line
    }, [])

    const handleSave = (event) => {
        event.preventDefault();
        setSaving(true);
        
        for(let i = 0; i < Object.keys(shopSettings).length; i++){
            if(JSON.stringify(shopSettings[i]) !== JSON.stringify(saveState[i])){
                firestore.collection('shopSettings').doc(shopSettings[i].docId.toString()).update(saveState[i])
                .then(() => {
                    setSaving(false);
                    setSaveError(false);
                    setShopSettings({ ...saveState })
                }).catch((e) => {
                    setSaving(false);
                    setSaveError(true);
                });
            }
            //if fuelSurcharge or carbonFee also add it to the historicData map inside the current year map for the current month
            if(shopSettings[i].docId.toString() === 'taxes'){
                let currentYear = new Date().getFullYear();
                let currentMonthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date());
                let currentYearData = shopSettings[i].historicData[currentYear] || {};
                let currentMonthData = currentYearData[currentMonthName.toLowerCase()] || {};
                let newCurrentMonthData = {
                    ...currentMonthData,
                    fuelSurcharge: saveState[i].fuelSurcharge,
                    carbonFee: saveState[i].carbonFee,
                }
                let newCurrentYearData = {
                    ...currentYearData,
                    [currentMonthName.toLowerCase()]: newCurrentMonthData,
                }
                firestore.collection('shopSettings').doc(shopSettings[i].docId.toString()).update({
                    [`historicData.${currentYear}`]: newCurrentYearData,
                })
                .then(() => {
                    setSaving(false);
                    setSaveError(false);
                    setShopSettings({ ...saveState })
                }).catch((e) => {
                    setSaving(false);
                    setSaveError(true);
                });
            }
        }
        setSaving(false);
    }

    const cancelSave = () => {
        setSaveState({ ...shopSettings })
    }

    return !loading && !_.isEmpty(shopSettings) && !_.isEmpty(saveState)
        ? <ShopSettingsCard
            shopSettings={shopSettings}
            documents={documents}
            saveState={saveState}
            cancelSave={cancelSave}
            setSaveState={setSaveState}
            handleSave={handleSave}
            saving={saving}
            saveError={saveError} 
            />
        : <LoadingSpinner />
}

export default ShopSettings;