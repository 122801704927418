import React, {useEffect, useCallback} from 'react';
import { makeStyles } from '@material-ui/core';
import { IconButton, Paper,
    AppBar, Tabs, Tab, useMediaQuery, Box, Grid, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NoteIcon from '@material-ui/icons/Note';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { useAuth } from '../../../providers/AuthContext';
// import BuildIcon from '@material-ui/icons/Build';
// import TollIcon from '@material-ui/icons/Toll';
// import DriveEtaIcon from '@material-ui/icons/DriveEta';

// tabs
// import PartsTab from '../../components/Equipment/PartsTab'
// import TireTab from '../../components/Equipment/TireTab'
// import WheelBrakesTab from '../../components/Equipment/WheelBrakesTab'
import InformationTab from '../../components/Equipment/InformationTab'
import RegistrationTab from './RegistrationTab';
import NotesTab from './NotesTab';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        zIndex: '998'
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const EquipmentCard = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { roles } = useAuth();

    const [value, setValue] = React.useState(1);
    const [edit, setEdit] = React.useState(false);

    let equipmentCard = null;

    useEffect(() => {}, [smallScreen]);

    const TabPanel = useCallback((props) => {
        const { children, value, index, ...other } = props;
        return (
            <div
              role="tabpanel"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box style={{margin:'20px'}}>
                  {children}
                </Box>
              )}
            </div>
          );
    }, []);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleEdit = () => {
        if(edit){ props.cancelSave(); }
        setEdit(!edit);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (!_.isUndefined(props.equipmentData)) {
        equipmentCard = (
            <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                </Grid>
                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        <AppBar className={classes.tabBar} position="static" elevation={0} >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                centered={false}
                                variant={smallScreen ? "scrollable" : "fullWidth"}
                                scrollButtons={"on"}
                                style={{margin:'0px'}}
                            >
                                <IconButton onClick={(e) => props.history.push("/dashboard/equipment/printing/?docId=" + props.equipmentData?.docId)} title="Print Equipment"><PrintIcon /></IconButton>
                                <Tab label={<div><LocalShippingIcon style={{verticalAlign: 'middle'}} /> Information </div>} {...a11yProps(0)} />
                                {/* <Tab label={<div><BuildIcon style={{verticalAlign: 'middle'}} /> Part Numbers </div>} {...a11yProps(1)} /> */}
                                {/* <Tab label={<div><TollIcon style={{verticalAlign: 'middle'}} /> Tires </div>}  {...a11yProps(2)} /> */}
                                {/* <Tab label={<div><DriveEtaIcon style={{verticalAlign: 'middle'}} /> Wheels/Brakes </div>} {...a11yProps(3)} /> */}
                                <Tab label={<div><AssignmentIcon style={{verticalAlign: 'middle'}} /> Documents</div>} {...a11yProps(1)} />
                                <Tab label={<div><NoteIcon style={{verticalAlign: 'middle'}} /> Notes</div>} {...a11yProps(2)} />
                                <IconButton onClick={handleEdit} style={edit ? { color: 'black', } : roles.includes('Create|Update|Delete Equipment')  ? {visibility: 'visible'} : {visibility: 'hidden'}} title="Edit Equipment"><EditIcon /></IconButton>
                            </Tabs>
                        </AppBar>
                    </div>

                    <TabPanel value={value} index={1}>
                        <InformationTab
                            edit={edit}
                            equipmentData={props.equipmentData}
                            updateEquipmentData={props.updateEquipmentData}
                            equipmentClasses={props.equipmentClasses}
                            loading={props.loading}
                            customers={props.customers}
                            duplicateSerial={props.duplicateSerial}
                        />
                    </TabPanel>

                    {/* <TabPanel value={value} index={2}>
                        <PartsTab
                            equipmentData={props.equipmentData} saveState={props.saveState} edit={edit}
                            cancelSave={props.cancelSave} confirmSave={props.confirmSave}
                            updateEquipmentData={props.updateEquipmentData} saving={props.saving}
                            setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <TireTab
                            equipmentData={props.equipmentData} saveState={props.saveState} edit={edit}
                            cancelSave={props.cancelSave} confirmSave={props.confirmSave}
                            updateEquipmentData={props.updateEquipmentData} saving={props.saving}
                            setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={4}>
                        <WheelBrakesTab
                            equipmentData={props.equipmentData} saveState={props.saveState} edit={edit}
                            cancelSave={props.cancelSave} confirmSave={props.confirmSave}
                            updateEquipmentData={props.updateEquipmentData} saving={props.saving}
                            setSaveState={props.setSaveState} setEquipmentData={props.setEquipmentData}
                        />
                    </TabPanel> */}

                    <TabPanel value={value} index={2}>
                        <RegistrationTab
                            equipmentData={props.equipmentData}
                            edit={edit}
                            updateEquipmentData={props.updateEquipmentData}
                            //newRegistrationPDF={props.newRegistrationPDF}
                            //setNewRegistrationPDF={props.setNewRegistrationPDF}
                            handleAddToLocal={props.handleAddToLocal}
                            handleRemoveFromLocal={props.handleRemoveFromLocal}
                            handleDeleteClick={props.handleDeleteClick}
                            deleteData={props.deleteData}
                            setDeleteData={props.setDeleteData}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <NotesTab
                            edit={edit}
                            equipmentData={props.equipmentData}
                            updateEquipmentData={props.updateEquipmentData}
                        />
                    </TabPanel>

                </Paper>
            </Grid>
        )
    }

    return (
        <div>
            {equipmentCard}
        </div>
)
}

export default withRouter(EquipmentCard)