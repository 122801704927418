import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import HRCard from '../../components/HumanResources/HRCard';
import { useHRSettings } from '../../../providers/HRSettingsProvider';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        padding: '32px', 
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const HR = (props) =>  {

    const classes = useStyles();
    const { subtypes } = useHRSettings();

    const [hrData, setHrData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    let docTypes = []

    const updateHrData = useCallback((e) => {
            setHrData(e)
        },
        [],
    );

    const saveHrData = () => {
        setSaving(true);
        firestore.collection('humanResources').doc(hrData.docId)
            .update(hrData)
            .then(() => {
                setSaveState(hrData);
                setSaving(false)
            }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setHrData(saveState);
    };

    const handleCheckboxChange = (e) => {
        setHrData({
            ...hrData,
            [e.target.name]: e.target.checked
        })
    }


    useEffect(() => {

        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('humanResources').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const hrResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'appearsInEmployeePage': doc.data().appearsInEmployeePage ? doc.data().appearsInEmployeePage : false,
                    'emails': doc.data().emails ? doc.data().emails : [],
                    'gathersWeatherData': doc.data().gathersWeatherData ? doc.data().gathersWeatherData : false,
                    'hasPhotos': doc.data().hasPhotos ? doc.data().hasPhotos : false,
                    'sendsEmail': doc.data().sendsEmail ? doc.data().sendsEmail : false,
                    'allowAppSubmission': doc.data().allowAppSubmission ? doc.data().allowAppSubmission : false,

                }
            })
            setHrData(hrResponse[0]);
            setSaveState(hrResponse[0]);
            //need better way to hold these values hard coded here (and ) is bad :(
            // eslint-disable-next-line array-callback-return
            subtypes.filter((subtype) => subtype.parentValue === hrResponse[0].hrType).map((subtype) => {
                docTypes.push({value: subtype.value, display: subtype.name})
            })

            setLoading(false);
        })
        .catch(e => {
            setError("HR Document not found.");
            setLoading(false);
            console.log(e.message);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search]);
        
    return (
        <div>
            {loading 
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'40vw'} height={'45vh'}/>
            </div>
            : 
            <React.Fragment>
                <HRCard 
                    error={error} handleCheckboxChange={handleCheckboxChange}
                    hrData={hrData} 
                    saveState={saveState} 
                    cancelSave={cancelSave} 
                    saveHrData={saveHrData} 
                    updateHrData={updateHrData} 
                    saving={saving}
                    setSaveState={setSaveState}
                    docTypes={docTypes}
                />
            </React.Fragment>
            }
                
        </div>
    )
}

export default withRouter(HR);