import React, { useContext, useEffect, useState } from "react"
import { firestore } from "../firebase/firebase"
import _ from "lodash";

const SettingsContext = React.createContext()

export function useSettings() {
    return useContext(SettingsContext);
}

export function SettingsProvider({ children }) {

    const [settings, setSettings] = useState([]);
    const [contact, setContact] = useState([])
    const [social, setSocial] = useState([])
    const [taxes, setTaxes] = useState([])
    const [datesClosed, setDatesClosed] = useState([])
    const [zones, setZones] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      firestore.collection('shopSettings').get()
      .then(querySnapshot => {
          const response = querySnapshot.docs.map((doc) => {
          return {
              ...doc.data(),
              'docId': doc.id,
          }
          })
          let documents = {
              contactInformation: '',
              shopSettings: '',
              socialInformation: '',
              taxes: '',
              datesClosed: '',
              zoneSettings: '',
          }
          for(let i = 0; i < _.size(response); i++){
              for (const [key] of Object.entries(documents)){
                  if(response[i].docId.toString() === [key].toString()){
                      documents = {...documents, [key]: i}
                  }
              }
          }
          setContact(response[documents['contactInformation']]) 
          setSettings(response[documents['shopSettings']]) 
          setTaxes(response[documents['taxes']])
          setSocial(response[documents['socialInformation']])
          setDatesClosed(response[documents['datesClosed']])
          setZones(response[documents['zoneSettings']] || {})
        setLoading(false)
      })
      
    }, [])

    const value = {
        setSettings,
        settings,
        //setContact,
        contact,
        //setSocial,
        taxes,
        social,
        loading,
        datesClosed,
        zones,
    }

    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    )
}