import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import thunk from 'redux-thunk'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemesProvider } from './providers/ThemesContext';
import { BrowserRouter } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import productReducer from './store/reducers/productsReducer';
import userReducer from './store/reducers/userReducer';
import customerReducer from './store/reducers/customerReducer';
import equipInvReducer from './store/reducers/equipmentInvReducer';
import './fonts/murrayhillboldregular.ttf'
import reportReducer from './store/reducers/reportReducer';
import careersReducer from './store/reducers/careersReducer';
import cartReducer from './store/reducers/cartReducer';
import checkoutReducer from './store/reducers/checkoutReducer';
import ordersReducer from './store/reducers/ordersReducer';
import reportStorageReducer from './store/reducers/reportStorageReducer';
import "@fontsource/roboto"


//update reducers here
const rootReducer = combineReducers({
  /*example/

  name: someReducer

  Note: "name" is how you will access your state within your containers/components.
  name.state.someStateProp

  Note 2: dont forget to import the reducer

  /*/
  careers: careersReducer,
  cart: cartReducer,
  products: productReducer,
  checkout: checkoutReducer,
  users: userReducer,
  customers: customerReducer,
  equipmentInventory: equipInvReducer,
  reports: reportReducer,
  reportStorage: reportStorageReducer,
  orders: ordersReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const helmet = Helmet.renderStatic();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
ReactDOM.render(
  <BrowserRouter>
    <ThemesProvider>
      <Provider store={store}>


        <App />


      </Provider>
    </ThemesProvider>

  </BrowserRouter>,
  document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

