import React from 'react';
import {Grid, makeStyles, Button, Typography, Paper, TextField, FormControl, Select,
        Checkbox, FormControlLabel, FormLabel, RadioGroup, Radio, MenuItem, } from '@material-ui/core';
import { withRouter } from 'react-router';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import MultiSelectList from '../UI/MultiSelectList/MultiSelectList';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        marginTop: '20px',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    radioGroup: {
      border: '1px solid black', 
      padding: '10px',
      marginBottom: '10px',
    },
    rowGroup: {
      paddingTop: '10px', 
      paddingLeft: '20px', 
      paddingRight: '20px',
      paddingBottom: '10px'
    },
  }));
  
const InvoiceForm = (props) => {

    const classes = useStyles();
    //const theme = useTheme();
    //const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onRowSelectionChange = (rows) => {
      props.setSelectedRow(rows)
    }

    return (  
      <Grid className={classes.content}>     
        <Grid container className={classes.backButton}>
          <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
        </Grid>

        <Paper>
          <Grid container className={classes.rowGroup}>

            <Grid item xs={12} md={4} className={classes.rowGroup}>
              <Grid container>
                <Grid container>
                    <Grid item xs={4}>
                      <Grid container justifyContent='flex-end'>
                        <Typography style={{marginRight: '5px', marginTop: '5px'}}>Invoice Date:</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <KeyboardDatePicker
                        fullWidth
                        value={props.currentInvoice.invoiceDate}
                        onChange={(value)=> { props.handleChangeInvoice(value, 'invoiceDate', value)}}
                      />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={4}>
                      <Grid container justifyContent='flex-end' >
                        <Select 
                          fullWidth
                          value={props.currentInvoice.secondaryNumberType}
                          style={{marginRight: '5px'}}
                          onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'secondaryNumberType') }}
                        >
                          <MenuItem value={'Purchase Order#'}>Purchase Order #</MenuItem>
                          <MenuItem value={'Way bill#'}>Waybill #</MenuItem>
                          <MenuItem value={'Shipping Document#'}>Shipping Document #</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        value={props.currentInvoice.secndaryNumberValue}
                        onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'secndaryNumberValue') }}
                      />
                    </Grid>
                </Grid>

                
                <Grid container>
                    <Grid item xs={4}>
                      <Grid container justifyContent='flex-end'>
                        <Typography style={{marginRight: '5px', marginTop: '5px'}}>Invoice Terms:</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Select 
                        fullWidth
                        value={props.currentInvoice.invoiceTerms}
                        onChange={(e)=> {  props.handleChangeInvoice(e.target.value, 'invoiceTerms') }}
                      >
                        <MenuItem value={'Net 30 Days'}>Net 30 Days</MenuItem>
                        <MenuItem value={'C.O.D'}>C.O.D</MenuItem>
                        <MenuItem value={'Net 10 Days'}>Net 10 Days</MenuItem>
                        <MenuItem value={'Per Quote'}>Per Quote</MenuItem>
                        <MenuItem value={'End Of Month'}>End of Month</MenuItem>
                        <MenuItem value={'End Of Week'}>End of Week</MenuItem>
                      </Select>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={4}>
                      <Grid container justifyContent='flex-end'>
                        <Typography style={{marginRight: '5px', marginTop: '5px'}}>Attention:</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        value={props.currentInvoice.attention}
                        onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'attention') }}
                      />
                    </Grid>
                </Grid>

                <FormControlLabel
                  label="Combine Material and Cartage Sales"
                  control={
                      <Checkbox 
                          variant="contained" 
                          checked={props.currentInvoice.combinedMaterialCartageSales}
                          onClick={(e) => { props.handleChangeInvoice(e.target.checked, 'combinedMaterialCartageSales') }}
                      >
                      </Checkbox>
                  } />

              </Grid>
            </Grid>

            <Grid item xs={12} md={4} className={classes.rowGroup}>
              <Grid container justifyContent='center'>
                <FormControl>
                  <FormLabel style={{textAlign: 'center'}}>Tax Options</FormLabel>
                  <RadioGroup 
                    className={classes.radioGroup}
                    name="taxOptions" 
                    value={props.currentInvoice.taxOptions} 
                    onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'taxOptions') }}
                  >
                    <FormControlLabel value="calculate" control={<Radio />} label="Calculate GST and RST" />
                    <FormControlLabel value="override" control={<Radio />} label="Override GST and RST" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid container>
                  <Grid item xs={4}>
                    <Grid container justifyContent='flex-end'>
                      <Typography style={{marginRight: '5px'}}>{props.currentInvoice.taxOptions === 'calculate' ? "GST Rate:" : "GST Charged:"}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      value={props.currentInvoice.gstRate}
                      InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              <small>{(props.currentInvoice.gstRate * 100).toFixed(2)}%</small>
                            </React.Fragment>
                          ),
                        }}
                      onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'gstRate') }}
                    />
                  </Grid>
              </Grid>
              
              <Grid container>
                  <Grid item xs={4}>
                    <Grid container justifyContent='flex-end'>
                      <Typography style={{marginRight: '5px'}}>{props.currentInvoice.taxOptions === 'calculate' ? "RST Rate:" : "RST Charged:"}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      value={props.currentInvoice.rstRate}
                      InputProps={{
                        endAdornment: (
                          <React.Fragment>
                            <small>{(props.currentInvoice.rstRate * 100).toFixed(2)}%</small>
                          </React.Fragment>
                        ),
                      }}
                      onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'rstRate') }}
                    />
                  </Grid>
              </Grid>
              
              <Grid container justifyContent='center' style={{marginBottom: '10px'}}>
                <FormControlLabel
                  label="Use System Logo"
                  control={
                      <Checkbox 
                          variant="contained" 
                          checked={props.currentInvoice.useSystemLogo}
                          onClick={(e) => { props.handleChangeInvoice(e.target.checked, 'useSystemLogo') }}
                      >
                      </Checkbox>
                  } />
                </Grid>

                <Grid container>
                  <Grid container justifyContent='center'>
                    <Typography >Charge on Cartage Only</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent='flex-end'>
                      <Select 
                        value={props.currentInvoice.cartageOnlyFeeType}
                        onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'cartageOnlyFeeType', props.currentInvoice.invoiceDate) }}
                        fullWidth
                        style={{marginRight: '5px', marginBottom: '5px'}}
                      >
                        <MenuItem value={'fuelSurcharge'}>Fuel Surcharge</MenuItem>
                        <MenuItem value={'fuelEscalationCharge'}>Fuel Escalation Charge</MenuItem>
                        <MenuItem value={'weekendPremiumFee'}>Weekend Premium Fee</MenuItem>
                        <MenuItem value={'costPlusOverhead'}>Cost Plus Overhead</MenuItem>
                        <MenuItem value={'carbonFee'}>Carbon Fee</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={props.currentInvoice.cartageOnlyFee}
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              <small>{(props.currentInvoice.cartageOnlyFee * 100).toFixed(2)}%</small>
                            </React.Fragment>
                          ),
                        }}
                        onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'cartageOnlyFee') }}
                      />
                  </Grid>
                </Grid>

                <Grid container style={{marginBottom: '10px'}}>
                  <Grid container justifyContent='center'>
                    <Typography >Charge on Cartage and Material</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select 
                      value={props.currentInvoice.cartageAndMaterialFeeType}
                      onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'cartageAndMaterialFeeType', props.currentInvoice.invoiceDate) }}
                      fullWidth
                      style={{marginRight: '5px', marginBottom: '5px'}}
                    >
                      <MenuItem value={'weekendPremiumFee'}>Weekend Premium Fee</MenuItem>
                      <MenuItem value={'costPlusOverhead'}>Cost Plus Overhead</MenuItem>
                      <MenuItem value={'carbonFee'}>Carbon Fee</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={props.currentInvoice.cartageAndMaterialFee}
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              <small>{(props.currentInvoice.cartageAndMaterialFee * 100).toFixed(2)}%</small>
                            </React.Fragment>
                          ),
                        }}
                        onChange={(e)=> { props.handleChangeInvoice(e.target.value, 'cartageAndMaterialFee') }}
                      />
                  </Grid>
                </Grid>

                <Grid container>
                  <FormControlLabel
                  label="Invoice and Post by individual Ticket Numbers or ANY combination of Ticket Numbers instead of by Customer Name"
                  control={
                      <Checkbox 
                          variant="contained" 
                          checked={props.invoiceFilter.findByTicketNumber}
                          onClick={(e) => { props.handleChangeFilter(e.target.checked, 'findByTicketNumber') }}
                      >
                      </Checkbox>
                  } />
                </Grid>
            </Grid>

            <Grid item xs={12} md={4} className={classes.rowGroup} style={{border: '1px solid black',}}>
              <MultiSelectList 
                onRowSelectionChange={onRowSelectionChange}
                style={{maxHeight: '400px'}}
                header={props.invoiceFilter.findByTicketNumber ? 'Ticket Number' : 'Contact Name'}
                //multiListDataTitle field needed for display
                listData={props.filteredListData}
              />
              
              <Button
                style={{ marginBottom: '5px'}}
                fullWidth
                variant='contained'
                disabled={props.selectedRow.length < 1 || props.generatingInvoice}
                onClick={()=> {
                  props.handlePreviewPDF()
                }}
              >
                {props.generatingInvoice ? "Generating" : "Preview the Invoice"}
              </Button>

              <Grid container>
                  <Grid item xs={5}>
                    <KeyboardDatePicker 
                        value={props.invoiceFilter.startDate}
                        onChange={(value)=> { props.handleChangeFilter(value, 'startDate') }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{textAlign: 'center'}}>To</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <KeyboardDatePicker 
                      value={props.invoiceFilter.endDate}
                      onChange={(value)=> { props.handleChangeFilter(value, 'endDate') }}
                    />
                  </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Paper>
      </Grid>
    )
  }
  
  export default withRouter(InvoiceForm);