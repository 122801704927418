import React from 'react';
import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';

//tabs from card
import InformationTab from './InformationTab';
// import NotesTab from './NotesTab';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        maxWidth: '1000px',
        flexDirection: 'column',
        margin: 'auto',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
    },

    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const EquipmentForm = (props) => {

    const classes = useStyles();

    return (
        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()} >Back</Button>
            </Grid>

            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>

                        <Grid item sm={12} className={classes.title}>
                            <MenuBookIcon style={{ marginRight: '8px' }} />
                            <Typography variant="h4" align="center">New Equipment</Typography>
                        </Grid>

                        <InformationTab
                            edit={true}
                            equipmentData={props.equipmentData}
                            updateEquipmentData={props.updateEquipmentData}
                            equipmentClasses={props.equipmentClasses}
                            loading={props.loading}
                            customers={props.customers}

                            duplicateSerial={props.duplicateSerial}
                        />

                        {/* <Grid item sm={12} style={{marginTop: '10px'}}>
                            <NotesTab
                                edit={true}
                                equipmentData={props.equipmentData}
                                updateEquipmentData={props.updateEquipmentData}
                            />
                        </Grid> */}

                        <Grid item sm={12}>
                            <Typography type='url' align="center" style={{ color: 'red' }}>{props.error}</Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                style={props.submitting === 2 ? {background: 'green', color: 'white' } : null}
                                disabled={props.submitting === 1 || props.submitting === 2}
                                type="submit"
                            >
                                {props.submitting === 0 && "Create Equipment"}
                                {props.submitting === 1 && "Submitting"}
                                {props.submitting === 2 && "Success"}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(EquipmentForm);