import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TimesheetTablePayrollPDF from '../../../components/Reports/Timesheets/TimesheetTablePayrollPDF'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../../providers/UserContext';
import { firestore } from '../../../../firebase/firebase';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

function TimesheetTablePayrollPrint(props) {
    const [timesheetData, setTimesheet] = useState({});
    const [loading, setLoading] = useState([]);
    const [fullData, setFullData] = useState({})
    const { getUsers, users } = useUser();
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        if(users.length <= 0){
            getUsers();
        }
        let data = props.location.state.data
        setTimesheet(data)
        setFullData(props.location.state)
        setLoading(false)
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.state]);

    //needs to check if item is payrolled, approved, ect
    const markAsPaid = () => {
        //batch update firestore to mark timesheets as paid
        let batch = firestore.batch();
        timesheetData.forEach((timesheet) => {
            let docRef = firestore.collection('timesheets').doc(timesheet.docId);
            batch.update(docRef, {paid: true});
        })
        batch.commit().then(() => {

            //update the timesheets in timesheetData and filteredData in sessionStorage
            let updatedTimesheetData = timesheetData.map((timesheet) => {
                return {...timesheet, paid: true}
            })
            setTimesheet(updatedTimesheetData);
            let updatedFullData = {...fullData, data: updatedTimesheetData}
            setFullData(updatedFullData)
            
            //find all timesheets in sessionStorage's filteredData that match the timesheets in timesheetData and update them
            let filteredData = JSON.parse(sessionStorage.getItem('filteredData'));
            let updatedFilteredData = filteredData.map((timesheet) => {
                let match = updatedTimesheetData.find((updatedTimesheet) => updatedTimesheet.docId === timesheet.docId)
                if(match){
                    return {...timesheet, paid: true}
                }
                return timesheet
            })
            sessionStorage.setItem('filteredData', JSON.stringify(updatedFilteredData));

            props.history.push('/dashboard/reporting');
        })
    }

    return (
        loading
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'63vw'} height={'80vh'} />
            </div>
            : 
            <TimesheetTablePayrollPDF
                timesheetData={timesheetData}
                users={users} markAsPaid={markAsPaid}
                fullData={fullData} />
    )
}

export default withRouter(TimesheetTablePayrollPrint)