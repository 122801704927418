import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Paper, Typography, TextField, Button, Divider, Snackbar, MenuItem, Select, Checkbox, FormControlLabel, Collapse,
    IconButton
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import PaperTitle from '../../components/Orders/OrderPage/PaperTitle';
import _ from 'lodash';
import AddBoxIcon from '@material-ui/icons/AddBox';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHRSettings } from '../../../providers/HRSettingsProvider';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin:'10px',
  },

  grid: {
    marginTop: theme.spacing(2),
    maxWidth: '1200px',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const HRSettings = (props) => {
    const classes = useStyles();

    const [hrData, setHrData] = useState({});
    const [error, setError] = useState(false);
    const [saveState, setSaveState] = useState({});
    const [saving, setSaving] = useState(false);
    const { settings, loading } = useHRSettings();
    const [hrTypes, setHrTypes] = useState([]);
    const [hrSubtypes, setHrSubtypes] = useState([]);
    

    const initialType = {
        name: '',
        value: '',
        order: 0
    }

    const [newType, setNewType] = useState(initialType);

    useEffect(() => {
        if(settings){
            setHrData(settings);
            setSaveState(settings);
            //if no subtypes exist, set it to an empty array
            if(!settings.subtypes){
                setHrData({...settings, subtypes: []});
                setSaveState({...settings, subtypes: []});
            }
            setHrTypes(settings.types||[]);
            setHrSubtypes(settings.subtypes||[]);
        }

    }, [settings]);  

    useEffect(() => {

    }, [hrData]);

    const saveHrData = () => {
        setSaving(true)
        //check if collection exists
        if(!saveState?.docId){
            firestore.collection('humanResourcesSettings').add(hrData)
            .then(() => {
                setSaveState(hrData);
                setSaving(false)
            }).catch((e) => setError(e));
            return;
        }
        firestore.collection('humanResourcesSettings').doc(hrData.docId)
        .update(hrData)
        .then(() => {
            setSaveState(hrData);
            setSaving(false)
        }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setHrData(saveState);
        setHrTypes(saveState.types);
        setHrSubtypes(saveState.subtypes);
    }

    const exportASJSON = () => {
        //first make sure all the subtypes have the same keys as the initialSubType and if not, copy them
        let initialSubType = {
            name: '',
            value: '',
            hidden: false,
            override: false,
            overrideValue: '',
            appValue: '',
            hasParentFolder: false,
            hasSubfolders: false,
            parentValue: '',
            order: 0
        }

        let newSubtypes = hrData.subtypes.map((sub) => {
            let newSub = {...sub};  // Start with the existing subtype data
            for(let key in initialSubType){
                if(!(key in sub)){  // If the key is missing in the subtype
                    newSub[key] = initialSubType[key];  // Assign the default value
                }
            }
            return newSub;
        });

        let newHRData = {...hrData, subtypes: newSubtypes};
        
        setHrData({...hrData, subtypes: newSubtypes});

        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(newHRData));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", 'FolderSettings.json');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    const importASJSON = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function(e) {
            let data = JSON.parse(e.target.result);
            setHrData(data);
            console.log(data)
        }
    }

    return (
        <div className={classes.content}>
        {loading ? <Typography>Loading...</Typography> :
          <Grid>
            <Paper style={{margin:'10px', padding:'15px', minWidth:'850px', maxWidth:'1300px'}}>
                <Grid container spacing={2} className={classes.row}>
                    <Grid item xs={8} style={{ display: 'flex', margin:'auto'}}>
                        <PaperTitle title="Folder Settings" />
                    </Grid>
                    <Grid container item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', margin:'auto'}}>
                        <Grid item xs={6}>
                            <Button startIcon={<GetAppIcon/>} variant={'contained'} onClick={()=>exportASJSON()}>
                                Export JSON
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{marginTop:'-10px'}}><small>Import JSON</small></Typography>
                            <input name='file-upload' type="file" onChange={(e) => importASJSON(e)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} className={classes.row}>
                    <Grid item xs={10}>
                        <TextField
                            margin="normal"
                            variant='outlined'
                            id="name"
                            label="Name"
                            name="name"
                            onChange={(e) => {setNewType({...newType, name: e.target.value})}}
                            value={newType.name}/>
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', margin:'auto'}}>
                        <Button color='primary' variant='contained' startIcon={<AddBoxIcon/>}
                            disabled={newType.name === ''}
                            onClick={() => {
    
                                let addingtype = {
                                    name: newType.name,
                                    value: newType.name.toLowerCase().replace(/ /g, ''),
                                }
    
                                let newarray = hrData.types || [];
                                newarray.push(addingtype);
                                setHrTypes(newarray);
                                setHrData({...hrData, types: newarray})
                            }}
                        >
                            Category
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginBottom:'10px'}}>
                    
                </Grid>
                {loading && <Typography>Loading...</Typography>}
                {error && <Typography>{error}</Typography>}
                {hrTypes && hrTypes.map((type, index) => (
                    <Grid container spacing={1} className={classes.row} key={index}>
                        <Grid item xs={8}>
                            <TextField
                                
                                value={type.name}
                                fullWidth
                                size="small"
                                onChange={(event) => { 
                                    let newTypes = hrTypes.map((t) => {
                                        if(t.value === type.value){
                                            return {...t, name: event.target.value}
                                        }
                                        return t;
                                    })
                                    setHrTypes(newTypes);
                                    setHrData({...hrData, types: newTypes})
                                 }}
                                inputProps={{ style: { fontSize: '1.5rem' } }} />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', }}>
                            <Button color='primary' size="small" variant='contained' startIcon={<AddBoxIcon/>}
                                onClick={() => {
                                    let newarray = hrData.subtypes || [];
    
                                    let newSubtype = {
                                        name: 'New Subcategory ' + (hrSubtypes.filter(sub => sub.parentValue === type.value).length + 1),
                                        value: 'newsubcategory' + (hrSubtypes.filter(sub => sub.parentValue === type.value).length + 1),
                                        hidden: false,
                                        override: false,
                                        overrideValue: '',
                                        appValue: '',
                                        hasParentFolder: false,
                                        hasSubfolders: false,
                                        parentValue: type.value,
                                        order: hrSubtypes.filter(sub => sub.parentValue === type.value).length
                                    }
                                    newarray.push(newSubtype);
                                    setHrData({...hrData, subtypes: newarray})
                                }}
                            >
                                Sub-Category
                            </Button>
                            <Button size="small" color="secondary" variant='contained' style={{marginLeft:'5px'}}
                                disabled={hrSubtypes.filter(sub => sub.parentValue === type.value).length > 0}
                                onClick={() => {
                                    let newTypes = hrTypes.filter(t => t.value !== type.value);
                                    setHrTypes(newTypes);
                                    setHrData({...hrData, types: newTypes})
                                }}
                            >
                                Delete
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
    
                        <Grid container >
                        {hrSubtypes && hrSubtypes.filter(subType => subType.parentValue === type.value).sort(
                            (a, b) => (a.order > b.order) ? 1 : -1
                        ).map((subType, index) => (
                            
                            <Grid item xs={3} style={{margin:'5px'}} key={index}>
                                <Paper style={{marign:'10px',borderTopLeftRadius:'15px', borderTopRightRadius:'15px'}}>
                                    <Grid container style={{backgroundColor: subType.hidden?'grey':'#ffbf00', borderTopLeftRadius:'15px', borderTopRightRadius:'15px', borderBottom: '1px solid grey', padding:'4px'}}>
                                        <Grid item xs={8}>
                                            <Typography>{subType.name.length>22?subType.name.substring(0,22)+'...':subType.name}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Select
                                                value={subType.order}
                                                onChange={(e) => {
                                                    ///change order of subtypes and swap order values with the one it is swapping with
                                                    let newSubtypes = hrSubtypes.map((sub) => {
                                                        if(sub.value === subType.value && sub.parentValue === subType.parentValue){
                                                            return {...sub, order: e.target.value}
                                                        }
                                                        if(sub.order === e.target.value && sub.parentValue === subType.parentValue){
                                                            return {...sub, order: subType.order}
                                                        }
                                                        return sub;
                                                    })
                                                    setHrSubtypes(newSubtypes);
                                                    setHrData({...hrData, subtypes: newSubtypes})
                                                }}
                                            >
                                                {hrSubtypes.filter(sub => sub.parentValue === type.value).map((sub, index) => (
                                                    <MenuItem key={index} value={index}>{index + 1}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton  size="small" color="secondary" variant='contained' style={{marginLeft:'5px'}}
                                                onClick={() => {
                                                    let newSubtypes = hrSubtypes.filter(sub => sub.value !== subType.value);
    
                                                    //any subtypes that have a higher order than the one being deleted, reduce their order by 1
                                                    newSubtypes = newSubtypes.map((sub) => {
                                                        if(sub.order > subType.order && sub.parentValue === subType.parentValue){
                                                            return {...sub, order: sub.order - 1}
                                                        }
                                                        return sub;
                                                    }
                                                    )
    
                                                    setHrSubtypes(newSubtypes);
                                                    setHrData({...hrData, subtypes: newSubtypes})
                                                }}
                                            ><DeleteIcon/></IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{padding:'4px', margin:'4px'}}>
                                        <Grid item xs={6}>
                                            <TextField 
                                                margin="normal"
                                                variant='outlined'
                                                id="name"
                                                label="Name"
                                                name="name"
                                                onChange={(e) => {
    
                                                    setHrSubtypes(hrSubtypes.map((sub) => {
                                                        if(sub.value === subType.value){
                                                            return {...sub, name: e.target.value}
                                                        }
                                                        return sub;
                                                    }))
    
                                                    setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                        if(sub.value === subType.value){
                                                            return {...sub, name: e.target.value}
                                                        }
                                                        return sub;
                                                    })})
                                                }}
                                                value={subType.name}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField 
                                                margin="normal"
                                                variant='outlined'
                                                id="value"
                                                label="Value"
                                                name="value"
                                                onChange={(e) => {
                                                    
                                                    setHrSubtypes(hrSubtypes.map((sub) => {
                                                        if(sub.name === subType.name){
                                                            return {...sub, value: e.target.value}
                                                        }
                                                        return sub;
                                                    }))
                                                    setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                        if(sub.name === subType.name){
                                                            return {...sub, value: e.target.value}
                                                        }
                                                        return sub;
                                                    })})
                                                }}
                                                value={subType.value}/>
                                        </Grid>
                                        <Grid item xs={12}> <Divider/></Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={subType.hidden}
                                                        onChange={(e) => {
    
                                                            setHrSubtypes(hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, hidden: e.target.checked}
                                                                }
                                                                return sub;
                                                            }
                                                            ))
    
                                                            setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, hidden: e.target.checked}
                                                                }
                                                                return sub;
                                                            })})
                                                        }}
                                                        icon={<VisibilityIcon />}
                                                        checkedIcon={<VisibilityOffIcon />}
                                                        name="hidden"
                                                    />
                                                }
                                                label={subType.hidden?"Hidden":"Visible"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={subType.override}
                                                        disabled={subType.hasSubfolders}
                                                        onChange={(e) => {
    
                                                            setHrSubtypes(hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, override: e.target.checked}
                                                                }
                                                                return sub;
                                                            }
                                                            ))
                                                            setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, override: e.target.checked}
                                                                }
                                                                return sub;
                                                            })})
                                                        }}
                                                        name="override"
                                                    />
                                                }
                                                label={"Override URL"}
                                            />
                                        </Grid>
                                        <Collapse in={subType.override} timeout="auto" unmountOnExit>
                                            <Grid item xs={12}>
                                                <TextField 
                                                    margin="normal"
                                                    variant='outlined'
                                                    id="overrideValue"
                                                    label="Override URL"
                                                    placeholder='/...'
                                                    name="overrideValue"
                                                    onChange={(e) => {
                                                        setHrSubtypes(hrSubtypes.map((sub) => {
                                                            if(sub.name === subType.name){
                                                                return {...sub, overrideValue: e.target.value}
                                                            }
                                                            return sub;
                                                        }
                                                        ))
    
                                                        setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                            if(sub.name === subType.name){
                                                                return {...sub, overrideValue: e.target.value}
                                                            }
                                                            return sub;
                                                        })})
                                                    }}
                                                    value={subType.overrideValue}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField 
                                                    margin="normal"
                                                    variant='outlined'
                                                    id="appValue"
                                                    label="Override App Route"
                                                    name="appValue"
                                                    onChange={(e) => {
                                                        setHrSubtypes(hrSubtypes.map((sub) => {
                                                            if(sub.name === subType.name){
                                                                return {...sub, appValue: e.target.value}
                                                            }
                                                            return sub;
                                                        }
                                                        ))
                                                        setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                            if(sub.name === subType.name){
                                                                return {...sub, appValue: e.target.value}
                                                            }
                                                            return sub;
                                                        })})
                                                    }}
                                                    value={subType.appValue}/>
                                            </Grid>
                                        </Collapse>
                                        <Grid item xs={12}> <Divider/></Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                 disabled={subType.override || subType.hasParentFolder}
                                                control={
                                                    <Checkbox
                                                        checked={subType.hasSubfolders}
                                                        disabled={subType.override || subType.hasParentFolder}
                                                        onChange={(e) => {
                                                            setHrSubtypes(hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, hasSubfolders: e.target.checked}
                                                                }
                                                                return sub;
                                                            }
                                                            ))
                                                            setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, hasSubfolders: e.target.checked}
                                                                }
                                                                return sub;
                                                            })})
                                                        }}
                                                        name="hasSubfolders"
                                                    />
                                                }
                                                label={"Has Subfolders"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                disabled={subType.hasSubfolders}
                                                control={
                                                    <Checkbox
                                                        checked={subType.hasParentFolder}
                                                        disabled={subType.hasSubfolders}
                                                        onChange={(e) => {
                                                            setHrSubtypes(hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, hasParentFolder: e.target.checked}
                                                                }
                                                                return sub;
                                                            }
                                                            ))
                                                            setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                                if(sub.name === subType.name){
                                                                    return {...sub, hasParentFolder: e.target.checked}
                                                                }
                                                                return sub;
                                                            })})
                                                        }}
                                                        name="parentFolder"
                                                    />
                                                }
                                                label={"Has Parent Folder"}
                                            />
                                        </Grid>
                                        <Collapse in={subType.hasParentFolder} timeout="auto" unmountOnExit>
                                            <Grid item xs={12}>
                                                <TextField 
                                                    margin="normal"
                                                    variant='outlined'
                                                    id="parentFolderValue"
                                                    label="Parent Folder Value"
                                                    name="overridparentFolderValueeValue"
                                                    onChange={(e) => {
                                                        setHrSubtypes(hrSubtypes.map((sub) => {
                                                            if(sub.name === subType.name){
                                                                return {...sub, parentFolder: e.target.value}
                                                            }
                                                            return sub;
                                                        }
                                                        ))
                                                        setHrData({...hrData, subtypes: hrSubtypes.map((sub) => {
                                                            if(sub.name === subType.name){
                                                                return {...sub, parentFolder: e.target.value}
                                                            }
                                                            return sub;
                                                        })})
                                                    }}
                                                    value={subType.parentFolder}/>
                                            </Grid>
                                        </Collapse>
                                    </Grid>
    
                                </Paper>
                            </Grid>
                        ))}
                        </Grid>
                    </Grid>
                ))}
    
            </Paper>
          </Grid>}
          <Snackbar
                open={!_.isEqual(hrData, saveState)}
                message={saving ? 'Saving...' : 'Save Settings?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={()=>cancelSave()} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={()=>saveHrData()}>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                }
            />
            
        </div>
      )
    }
    
    export default withRouter(HRSettings);