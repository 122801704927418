import React from 'react';
import { Grid, makeStyles, Paper, TextField, Typography} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import NoteIcon from '@material-ui/icons/Note';

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    tabBar: {
        width: '100%',
        maxWidth: '1000px',

    },

    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }

    },

    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },

    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: '5px',
        borderRadius: '0px',
        margin: '5px',
        width: '100%',
        maxWidth: '2000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const NotesTab = (props) => {

    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid container>
                <Grid container item xs={12} >
                    <Paper className={classes.paper}>
                        <Typography variant="h5">Notes <NoteIcon></NoteIcon></Typography>
                        <div style={{border: '1px dashed #d3d3d3', padding: '10px'}}>
                            {props.edit ?
                                <TextField
                                    placeholder='Enter Notes Here...'
                                    multiline
                                    minRows={10}
                                    value={props.equipmentData.notes ? props.equipmentData.notes : ""}
                                    style={{width: '100%'}}
                                    variant="outlined"
                                    onChange={(event) => { props.updateEquipmentData({ ...props.equipmentData, 'notes': event.target.value }) }}
                                /> :
                                props.equipmentData?.notes ? props.equipmentData?.notes : "No Notes to Display"
                            }
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default withRouter(NotesTab)