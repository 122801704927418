import React, { useState, useEffect } from 'react'
import { Typography, Grid, withStyles, LinearProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { storage } from '../../../../firebase/firebase';
import Dropzone from 'react-dropzone';
import { amber } from '@material-ui/core/colors';


/**
 * File Uploader component by Derek
 * This uses the react-dropzone library to handle drag n drop uploads. If no accept types are specified, the component will be disabled.
 * @param {String} props.filePath - The path to upload the file to. Defaults to 'Uploads/'
 * @param {String} props.fileName - The name of the file to upload. Defaults to the name of the file uploaded
 * @param {Boolean} props.acceptPDF - Accept PDF files
 * @param {Boolean} props.acceptImage - Accept Image files
 * @param {Function} props.onUploadSuccess - Callback function to call after successful upload
 */
const FileUploader = (props) => {
    const [uploading, setUploading] = useState(false);
    const [fileProgress, setFileProgress] = useState(0);

    useEffect(() => {

        //eslint-disable-next-line
    }, [])

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
        height: 10,
        borderRadius: 5,
        border: '1px solid white',
        },
        colorPrimary: {
        backgroundColor: 'grey',
        },
        bar: {
        borderRadius: 5,
        backgroundColor: amber[500],
        },
    }))(LinearProgress);

    const handleUpload = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (!file) return;
        setUploading(true);
        const path = storage.ref().child(
            `${(props.filePath || 'Uploads/')}${props.fileName || file.name}`
        );
    
        const uploadTask = path.put(file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setFileProgress(progress);
                console.log(`Upload is ${progress}% done`);
            },
            (error) => {
                console.error("Error uploading file:", error);
                setUploading(false);
            },
            async () => {
                const returnURL = await path.getDownloadURL();
                setUploading(false);
                setTimeout(() => { setFileProgress(0); }, 3000);
                
                // Call the callback function to update companyData.logoURL
                if (props.onUploadSuccess) {
                    props.onUploadSuccess(returnURL);
                }
            }
        );
    };

    let acceptedTypes = {}

    if(props.acceptPDF){
        acceptedTypes = {...acceptedTypes, 'application/pdf': ['.pdf']}
    } 
    if(props.acceptImage){
        acceptedTypes = {...acceptedTypes, 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG']}
    }

    return (
        <Grid container item xs={6} justifyContent="center" alignItems="center" style={{ display: 'flex', margin:'auto'}}>
            <Dropzone accept={acceptedTypes} onDrop={handleUpload} disabled={(!props.acceptImage && !props.acceptPDF) || uploading}>
                {({ getRootProps, getInputProps }) => (
                <Grid container item xs={12} {...getRootProps()} justifyContent="center" alignItems="center" style={{opacity:'75%',cursor: 'pointer', border: '1px dashed', minHeight: '100px', minWidth: '250px'}}>
                    <input {...getInputProps()} style={{ display: 'none' }} />
                    <Grid item xs={12} container alignItems="center" justifyContent="center" style={{ display: 'flex', flexDirection: 'column' }}>
                        <PublishIcon style={{ marginBottom: '5px' }} />
                        <Typography style={{ fontSize: '11px', textAlign: 'center' }}>
                            {(!props.acceptImage && !props.acceptPDF) ? "Error: No file types accepted":"Click or Drag And Drop File to Upload"}
                        </Typography>
                    </Grid>
                    <p><small>Accepting types: {props.acceptPDF ? '.pdf, ' : null} {props.acceptImage ? '.png, .jpeg, .jpg' : null}</small></p>
                </Grid>
                
                )}
            </Dropzone>

            {uploading && (
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                    <BorderLinearProgress variant="determinate" value={fileProgress} />
                </Grid>
            )}

        </Grid>

    )
}

export default FileUploader;