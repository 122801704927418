import React, { useState} from 'react'
import { Typography, Grid, TextField, InputAdornment, IconButton, Checkbox, Menu, MenuItem, FormControl, Select, Chip} from '@material-ui/core';
import _ from 'lodash';

import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import RefreshIcon from '@material-ui/icons/Refresh';

const DataGridTableTitle = (props) => {
    const [columnSelectorOpen, setColumnSelectorOpen] = useState(false)
    const [searchValue, setSearchValue] = useState("grainOrdersReportSearchValues" in sessionStorage 
        ? JSON.parse(sessionStorage.getItem("grainOrdersReportSearchValues")) 
        : {value: '', column: 'all', condition: 'contains'});

    const handleOpenColumnSelector = (e) => {
        setColumnSelectorOpen(e.currentTarget);
      };
    
    const handleCloseColumnSelector = () => {
        setColumnSelectorOpen(null);
    };

    const handleChangeColumnsVisible = (index) => {
        let container = _.cloneDeep(props.columns)
        container[index].active =  !_.isUndefined(container[index].active) ? !container[index].active : false
        props.setColumns(container)
    }

    const camelCaseToTitleCase = (string) => {
        const result = string.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
 
    const handleUpdateSearch = (value, type) => {
        setSearchValue((previousValues) => { 
            let container = {...previousValues, [type]: value}
            sessionStorage.setItem("grainOrdersReportSearchValues", JSON.stringify(container));
            return container;
        })
    }

    //console.log('rerendered dataGridTableTitle')

    return (
        <>
            <Grid item lg={4} md={4} xs={12}>
                <Typography variant='h6'>
                    Grain Orders
                    {props.rowsSelected.length > 0 && ` (${props.rowsSelected.length} rows selected)`}
                </Typography>
            </Grid>

            <Grid item lg={8} md={8} xs={12} >
                <Grid container justifyContent='flex-end'>
                    <Grid style={{marginRight: '10px'}}>
                        <FormControl>         
                            <Select
                                value={searchValue.column}
                                onChange={(event) => { handleUpdateSearch(event.target.value, 'column') }}
                            >
                                <MenuItem 
                                    key={'all'}
                                    value={'all'}
                                >
                                    All Columns
                                </MenuItem>

                                {props.columns.map((column) => {
                                    return (
                                        <MenuItem 
                                            key={column.field}
                                            value={column.field}
                                        >
                                            {column.title}
                                        </MenuItem>
                                    )  
                                })}
                            </Select>
                        </FormControl>

                        <FormControl style={{marginRight: '5px', marginLeft: '5px'}}>         
                            <Select
                                value={searchValue.condition}
                                onChange={(event) => { handleUpdateSearch(event.target.value, 'condition') }}
                            >
                                <MenuItem name='contains' key="contains" value='contains'>Contains</MenuItem>
                                <MenuItem name='doesNotContain' key="doesNotContain" value='doesNotContain'>Doesnt Contain</MenuItem>
                            </Select>
                        </FormControl>

                        <form style={{display: 'inline-flex'}} onSubmit={(event)=> { event.preventDefault(); props.handleAddSearchCard(searchValue)}}>
                            <TextField
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={'Search'}
                                                size='small'
                                                type="submit"
                                                edge="end"
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>,
                                }}
                                placeholder='Keyword'
                                value={searchValue.value}
                                onChange={(e) => { handleUpdateSearch(e.target.value, 'value') }}
                            />
                        </form>
                    </Grid>
                                
                    <Grid>
                        <IconButton
                            size='small'
                            onClick={(e)=> { props.updateSort(props.tabSelected, props.searchValueArray) }}
                        >
                            <RefreshIcon/>
                        </IconButton>

                        <IconButton
                            size='small'
                            onClick={(e)=> { handleOpenColumnSelector(e) }}
                        >
                            <ViewColumnIcon/>
                        </IconButton>

                        <Menu
                            anchorEl={columnSelectorOpen}
                            keepMounted
                            open={columnSelectorOpen}
                            onClose={handleCloseColumnSelector}
                        >
                            <Typography noWrap style={{fontSize: '12px', fontWeight: '600', padding: '15px'}}>Add or remove columns</Typography>
                            {props.columns.map((column, index) => {
                                return (
                                    <MenuItem 
                                        key={index}
                                        value={index}
                                        onClick={(e) => { handleChangeColumnsVisible(index) }}
                                    >
                                        <Checkbox checked={!_.isUndefined(column?.active) ? column.active : true} />
                                        <Typography style={{marginTop: '5px'}}>{column.title}</Typography>    
                                    </MenuItem>
                                )  
                            })}
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container style={{marginTop: '5px'}}>  
                {props.searchValueArray.map((value, index) => {
                    return (
                        <Chip
                            label={`${camelCaseToTitleCase(value.column)} ${camelCaseToTitleCase(value.condition)} "${value.value}"`}
                            onDelete={() => { props.handleRemoveSearchCard(index) }}
                        />
                    )
                })}
            </Grid>
        </>
    )
}
  export default DataGridTableTitle;