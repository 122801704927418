import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Grid, Paper, useTheme, useMediaQuery, Typography, TextField, Select, MenuItem, InputLabel, FormControl, CircularProgress, 
        Divider, InputAdornment, Checkbox} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1200px',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    container: {
        marginBottom: '10px',
        padding: '15px'
    },
    textRow: {
        lineHeight: 2,
        marginBottom: '8px',
    },
    textRowTypography: {
        marginTop: '4px',
        lineHeight: 2,
        marginBottom: '8px',
    },
    selectRow: {
        padding: '5px',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const GrainOrdersCreateCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultAutocompletes = {
        operator: {open: false, value: ''},
        customer: {open: false, value: ''},
        unit: {open: false, value: ''},
        trailer1: {open: false, value: ''},
        trailer2: {open: false, value: ''},
        material: {open: false},
        origin: {open: false},
        destination: {open: false},
        operatorPer: {open: false},
        ownerPer: {open: false},
    }

    const [autocompletes, setAutocompletes] = useState(defaultAutocompletes)

    //changes currentGrainOrder
    useEffect(() => {
        setInitalAutocompleteValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setInitalAutocompleteValues = () => {
        let container = _.cloneDeep(autocompletes)

        //operator
        let operatorFind = props.users.find(x=> x.docId === props.currentGrainOrder.operator)
        container.operator.value = props.currentGrainOrder.operator !== "" && !_.isUndefined(operatorFind) ? operatorFind.docId : props.currentGrainOrder.operator;

        //customer
        let customerFind = props.customers.find(x=> x.docId === props.currentGrainOrder.customer)
        container.customer.value = props.currentGrainOrder.customer !== "" && !_.isUndefined(customerFind) ? customerFind : props.currentGrainOrder.customer;

        //unit
        let unitFind = props.units.find(x=> x.docId === props.currentGrainOrder.equipment)
        container.unit.value = props.currentGrainOrder.equipment !== "" && !_.isUndefined(unitFind) ? unitFind : props.currentGrainOrder.equipment;
        
        //trailer1
        let trailer1Find = props.units.find(x=> x.docId === props.currentGrainOrder.trailer1)
        container.trailer1.value = props.currentGrainOrder.trailer1 !== "" && !_.isUndefined(trailer1Find) ? trailer1Find : props.currentGrainOrder.trailer1;

        //trailer2
        let trailer2Find = props.units.find(x=> x.docId === props.currentGrainOrder.trailer2)
        container.trailer2.value = props.currentGrainOrder.trailer2 !== "" && !_.isUndefined(trailer2Find) ? trailer2Find : props.currentGrainOrder.trailer2;

        setAutocompletes(container)
    }

    //function to display user displayname instead of uuid
    const getUserDisplayName = (docId) => {
        let user = _.find(props.users, {docId: docId});
        if (!_.isUndefined(user)) {
            return user.displayName;
        } else {
            return docId;
        }
    }

    const uniqueOrderNumberCheck = (inputValue) => {
        if(_.isUndefined(props.grainOrders.find(x=> x.orderNumber === inputValue)) || inputValue === '' ||
            (!_.isUndefined(props.grainOrders.find(x=> x.orderNumber === inputValue)) && props.grainOrders.find(x=> x.orderNumber === inputValue).docId === props.path)){
            return true
        }
        else{
            return false
        }
    }

    return (
        <Grid>
            <Grid container className={classes.backButton}>
                <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
            </Grid>

            <Paper className={classes.paper}>
                <Grid >
                    <Grid container className={classes.container}>

                        <Grid container>
                            <Grid item md={4} xs={12} >
                                <Grid container >
                                    <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>GeeTee Order #: &nbsp;</Typography>
                                        <Typography className={classes.textRowTypography}>New Grain Order</Typography>
                                </Grid>
                            </Grid>
                    
                            <Grid item md={4} xs={12} >
                                <Grid container >
                                    <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>Order #: &nbsp;</Typography>
                                    <TextField 
                                        className={classes.textRow}
                                        value={props.currentGrainOrder.orderNumber}
                                        onChange={(event) => {
                                            props.setCurrentGrainOrder({...props.currentGrainOrder, orderNumber: event.target.value}) 
                                        }}
                                        onBlur={(event) => {
                                            if((!_.isUndefined(event.target.value) && uniqueOrderNumberCheck(event.target.value))){
                                                props.setOrderNumberSaveState(event.target.value)
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, orderNumber: event.target.value}) 
                                            }else{
                                                window.alert("This order number is already in use!");
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, orderNumber: props.orderNumberSaveState}) 
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item md={4} xs={12} >
                                <Grid container justifyContent={ smallScreen ? 'flex-start' : 'flex-end' }>
                                    <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>Order Type: &nbsp;</Typography>
                                        
                                    <Select
                                            onChange={(event) => {
                                            props.setCurrentGrainOrder({...props.currentGrainOrder, orderType: event.target.value}) 
                                        }}
                                        style={{minWidth: '200px', marginBottom: '15px'}}
                                        value={props.currentGrainOrder.orderType}
                                    >
                                        {props.orderTypes.map((type) => {
                                            return (
                                                <MenuItem key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</MenuItem>
                                            )
                                        })}
                                        
                                    </Select>
                                </Grid>
                            </Grid>

                        </Grid>
                        
                    </Grid>

                    <Paper className={classes.container}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Typography><small>Data Entry Date</small></Typography>
                                <Typography>{moment(props.currentGrainOrder.dateCreated).format('YYYY/MM/DD h:mm:ss a')}</Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    label="Ticket Date"
                                    value={props.currentGrainOrder.ticketDate}
                                    placeholder={''}
                                    clearable
                                    autoOk
                                    format="yyyy/MM/DD"
                                    onChange={(date)=> { 
                                        let newValue = date === null ? date : moment(date).valueOf()
                                        props.setCurrentGrainOrder({...props.currentGrainOrder, ticketDate: newValue}) 
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl  className={classes.selectRow} style={{marginTop: '3px'}} size='small' fullWidth variant='outlined'>
                                <InputLabel className={classes.selectRow} >Order Status</InputLabel>
                                    <Select
                                        value={props.currentGrainOrder.orderStatus || ''}
                                        label="Order Status"
                                        onChange={(event) => {
                                            props.setCurrentGrainOrder({...props.currentGrainOrder, orderStatus: event.target.value}) 
                                        }}
                                    >
                                        {props.statuses.map((status, index) => {
                                            return <MenuItem key={'status' + index} value={status}>{status.charAt(0).toUpperCase() + status.slice(1)} Order</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Paper>

                    <Paper className={classes.container}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Autocomplete
                                    open={autocompletes.customer.open}
                                    onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, customer: {...previousValues.customer, open: true}} }) }}
                                    onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, customer: {...previousValues.customer, open: false}} }) }}
                                    loading={props.loading}
                                    label="Customer"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    disableClearable
                                    //freeSolo={true}
                                    className={classes.selectRow}
                                    fullWidth
                                    value={autocompletes.customer.value}
                                    onChange={(event, newValue) => { 
                                        // setCustomerAutoCompleteValue(newValue)
                                        setAutocompletes((previousValues) => { return {...previousValues, customer: {...previousValues.customer, value: newValue}} })
                                        props.setCurrentGrainOrder({ ...props.currentGrainOrder, customer: newValue.docId })  
                                    }}
                                    options={props.customers.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                                    getOptionLabel={(option) => option.name ? option.name + ' - ' + option.streetNumber + ' ' + option.streetName : ''  }
                                    getOptionSelected={(option, value) =>  option.name === value.name || {} }
                                    renderOption={(option) =>( 
                                        props.customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                            return(
                                                <Grid key={option.docId} >
                                                    <Typography>{customerObj.name}</Typography>
                                                    <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                                </Grid>
                                            )
                                        })
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Customer"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    open={autocompletes.operator.open}
                                    onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, operator: {...previousValues.operator, open: true}} }) }}
                                    onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, operator: {...previousValues.operator, open: false}} }) }}
                                    loading={props.userLoading}
                                    label="Operator"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    disableClearable
                                    //freeSolo={true}
                                    className={classes.selectRow}
                                    fullWidth
                                    value={getUserDisplayName(autocompletes.operator.value)}
                                    onChange={(event, newValue) => {
                                        //initial changes
                                        let localGrainDataContainer = { ...props.currentGrainOrder, operator: newValue }
                                        let autocompleteContainer = _.cloneDeep(autocompletes)
                                        //setUserAutoCompleteValue(newValue)
                                        autocompleteContainer.operator.value = newValue;

                                        //check if equipment is not present
                                        if(( !props.currentGrainOrder.equipment)){
                                            //try to get realated value
                                            let unitContainer = props.users.find(x=> x.docId === newValue)?.commonEquipment?.fullUnit?.docId
                                            let unitAutoContiner = props.units.find(x=> x.docId === props.users.find(x=> x.docId === newValue)?.commonEquipment?.fullUnit?.docId)

                                            if(!_.isUndefined(unitContainer)){  
                                                localGrainDataContainer.equipment = unitContainer 
                                                //setUnitAutoCompleteValue(unitAutoContiner)
                                                autocompleteContainer.unit.value = unitAutoContiner;
                                            }
                                        }

                                        setAutocompletes(autocompleteContainer)
                                        props.setCurrentGrainOrder(localGrainDataContainer) 
                                    }}
                                    options={props.users.filter(x=> x.employment?.active).map((user) => user.docId ?? '').sort((a, b) => getUserDisplayName(a).toLowerCase() > getUserDisplayName(b).toLowerCase())}
                                    getOptionSelected={(option, value) => option?.docId === value?.docId || {}}
                                    getOptionLabel={(option) => getUserDisplayName(option) || '' }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Operator"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl className={classes.selectRow} size='small' fullWidth variant='outlined'>
                                <InputLabel className={classes.selectRow}> Order Priority</InputLabel>
                                    <Select
                                        value={props.currentGrainOrder.orderPriority || ''}
                                        label="Order Priority"
                                        onChange={(event) => {
                                            props.setCurrentGrainOrder({...props.currentGrainOrder, orderPriority: event.target.value}) 
                                        }}
                                    >
                                        {props.priorities.map((priority, index) => {
                                            return <MenuItem key={'priority' + index} value={priority}>{priority}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    open={autocompletes.unit.open}
                                    onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, unit: {...previousValues.unit, open: true}}}) }}
                                    onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, unit: {...previousValues.unit, open: false}}}) }}
                                    loading={props.unitLoading}
                                    label="Equipment"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    disableClearable
                                    //freeSolo={true}
                                    className={classes.selectRow}
                                    fullWidth
                                    value={autocompletes.unit.value}
                                    onChange={(event, newValue) => { 
                                        //inital changes
                                        let localGrainDataContainer = { ...props.currentGrainOrder, equipment: newValue.docId }
                                        let autocompletesContainer = _.cloneDeep(autocompletes)
                                        //setUnitAutoCompleteValue(newValue)
                                        autocompletesContainer.unit.value = newValue;

                                        //check if operator is not present
                                        if(( !props.currentGrainOrder.operator)){
                                            let userContainer = props.users.filter(x=> x.commonEquipment?.fullUnit?.docId === newValue.docId).pop()?.docId
                                            if(!_.isUndefined(userContainer)){  
                                                localGrainDataContainer.operator = userContainer 
                                                //setUserAutoCompleteValue(userContainer)
                                                autocompletesContainer.operator.value = userContainer;
                                            }
                                        }

                                        setAutocompletes(autocompletesContainer)
                                        props.setCurrentGrainOrder(localGrainDataContainer) 
                                    }}
                                    options={props.units || {}}
                                    getOptionSelected={(option, value) => option.unitNumber === value.unitNumber || {}}
                                    getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : ''}
                                    renderOption={(unit) =>(
                                        <Grid >
                                            <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Equipment"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    open={autocompletes.trailer1.open}
                                    onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer1: {...previousValues.trailer1, open: true}} }) }}
                                    onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer1: {...previousValues.trailer1, open: false}} }) }}
                                    loading={props.unitLoading}
                                    label="Trailer 1"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    disableClearable
                                    //freeSolo={true}
                                    className={classes.selectRow}
                                    fullWidth
                                    value={autocompletes.trailer1.value}
                                    onChange={(event, newValue) => { 
                                        //setUnitTrailer1AutoCompleteValue(newValue)
                                        setAutocompletes((previousValues) => { return {...previousValues, trailer1: {...previousValues.trailer1, value: newValue}} })
                                        props.setCurrentGrainOrder({ ...props.currentGrainOrder, trailer1: newValue.docId }) 
                                    }}
                                    options={props.units || {}}
                                    getOptionSelected={(option, value) => option?.unitNumber === value?.unitNumber || {}}
                                    getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : '' }
                                    renderOption={(unit) =>(
                                        <Grid >
                                            <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Trailer 1"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    open={autocompletes.trailer2.open}
                                    onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer2: {...previousValues.trailer2, open: true}} }) }}
                                    onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, trailer2: {...previousValues.trailer2, open: false}} }) }}
                                    loading={props.unitLoading}
                                    label="Trailer 2"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    disableClearable
                                    //freeSolo={true}
                                    className={classes.selectRow}
                                    fullWidth
                                    value={autocompletes.trailer2.value}
                                    onChange={(event, newValue) => { 
                                        //setUnitTrailer2AutoCompleteValue(newValue)
                                        setAutocompletes((previousValues) => { return {...previousValues, trailer2: {...previousValues.trailer2, value: newValue}} })
                                        props.setCurrentGrainOrder({ ...props.currentGrainOrder, trailer2: newValue.docId }) 
                                    }}
                                    options={props.units || {}}
                                    getOptionSelected={(option, value) => option?.unitNumber === value?.unitNumber || {}}
                                    getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : '' }
                                    renderOption={(unit) =>(
                                        <Grid >
                                            <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Trailer 2"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </Paper>

                    <Paper className={classes.container}>
                        <Grid container>
                            <Grid item xs={6}>

                                <Autocomplete
                                    open={ autocompletes.origin.open }
                                    onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, origin: {...previousValues.origin, open: true}} }) }}
                                    onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, origin: {...previousValues.origin, open: false}} }) }}
                                    label="Search"
                                    id="originAutocomplete"
                                    key="originAutocomplete"
                                    className={classes.selectRow}
                                    autoComplete
                                    autoHighlight
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    freeSolo={true}
                                    inputValue={props.currentGrainOrder.origin || ''}
                                    onInputChange={(event, newValue, reason) => { 
                                        if(reason === 'input') { props.setCurrentGrainOrder({...props.currentGrainOrder, origin: newValue}) }
                                    }}
                                    value={props.currentGrainOrder.origin || ''}
                                    onChange={(event, newValue) => { 
                                        props.setCurrentGrainOrder({...props.currentGrainOrder, origin: newValue}) 
                                    }}
                                    options={props.places.map((place) => place ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                    getOptionSelected={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Origin (From)"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={6}>

                                <Autocomplete
                                    open={autocompletes.destination.open}
                                    onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, destination: {...previousValues.destination, open: true}} }) }}
                                    onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, destination: {...previousValues.destination, open: false}} }) }}
                                    label="Search"
                                    id="destinationAutocomplete"
                                    className={classes.selectRow}
                                    autoComplete
                                    autoHighlight
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    freeSolo={true}
                                    inputValue={props.currentGrainOrder.destination || ''}
                                    onInputChange={(event, newValue, reason) => { 
                                        if(reason === 'input') { props.setCurrentGrainOrder({...props.currentGrainOrder, destination: newValue})  }
                                    }}
                                    value={props.currentGrainOrder.destination || ''}
                                    onChange={(event, newValue) => { 
                                        props.setCurrentGrainOrder({...props.currentGrainOrder, destination: newValue}) 
                                    }}
                                    options={props.places.map((place) => place ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                    getOptionSelected={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Destination (To)"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper className={classes.container}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <Grid container justifyContent='center'> 
                                    <Typography style={{fontWeight: 600}} className={classes.textRow}>Material</Typography>
                                </Grid>
                                <Divider className={classes.textRow}/>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Material:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            open={autocompletes.material.open}
                                            onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, material: {...previousValues.material, open: true}} }) }}
                                            onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, material: {...previousValues.material, open: false}} }) }}
                                            id="materialAutocomplete"
                                            //className={classes.selectRow}
                                            autoComplete
                                            autoHighlight
                                            fullWidth
                                            freeSolo={true}
                                            size='small'
                                            onInputChange={(event, newValue, reason) => { 
                                                if(reason === 'input') {props.setCurrentGrainOrder({...props.currentGrainOrder, material: newValue}) }
                                            }}
                                            value={props.currentGrainOrder.material || null}
                                            onChange={(event, newValue) => { 
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, material: newValue}) 
                                            }}
                                            options={props.materials.map((material) => material ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                            getOptionSelected={(option, value) => option === value}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    //variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Fuel Surcharge :</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <TextField 
                                            className={classes.textRow}
                                            value={props.currentGrainOrder.percentageFuelSurcharge}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            onChange={(event) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, percentageFuelSurcharge: event.target.value})
                                            }}
                                            onBlur={(event) => {
                                                let dollarSurchargeValue = Number(Number(event.target.value / 100)  * Number(props.currentGrainOrder.cartageSalesTotal || 0.00)).toFixed(2)

                                                props.setCurrentGrainOrder({...props.currentGrainOrder, percentageFuelSurcharge: Number(event.target.value).toFixed(3), 
                                                    dollarFuelSurcharge: dollarSurchargeValue})
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6} className={classes.textRowTypography}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            className={classes.textRow}
                                            style={{borderBottom: '1px solid grey'}}
                                            fullWidth
                                        >
                                            $&nbsp;&nbsp;&nbsp;{props.currentGrainOrder.dollarFuelSurcharge}
                                        </Typography>
                                    </Grid>
                    
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Grid container justifyContent='center'> 
                                    <Typography style={{fontWeight: 600}} className={classes.textRowTypography}>Cartage</Typography>
                                </Grid>
                                <Divider className={classes.textRow}/>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Cartage Sales:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            value={props.currentGrainOrder.cartageSales}
                                            onChange={(event) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, cartageSales: event.target.value}) 
                                            }}
                                            onBlur={(event) => {
                                                let adjustedBase = props.currentGrainOrder.adjustedBase || 0.00;
                                                let payroll = props.currentGrainOrder.payroll || 0.00;
                                                let cartageSalesTotal = props.currentGrainOrder.cartageSalesTotal || 0.00;
                                                let dollarFuelSurcharge = props.currentGrainOrder.dollarFuelSurcharge || 0.00;

                                                if(!_.isUndefined(props.currentGrainOrder.linkOperatorToSalesRate) && props.currentGrainOrder.linkOperatorToSalesRate){
                                                    adjustedBase = Number((Number(event.target.value)) * (Number((props.currentGrainOrder.operatorBaseMultiplier || 100.00) / 100))).toFixed(2)
                                                    //console.log('adjustedBase', adjustedBase)

                                                    payroll = Number( adjustedBase * (props.currentGrainOrder.operatorUnits || 0) * ((props.currentGrainOrder.operatorRate || 0))).toFixed(2)
                                                    //console.log('payroll', payroll)
                                                }

                                                dollarFuelSurcharge = Number(Number((props.currentGrainOrder.percentageFuelSurcharge || 0.000) / 100)  * Number(Number(event.target.value) * Number((props.currentGrainOrder.cartageSalesUnits || 0)))).toFixed(2)
                                                //console.log('dollarFuelSurcharge', dollarFuelSurcharge)
                                                
                                                cartageSalesTotal = Number((Number(event.target.value) * Number((props.currentGrainOrder.cartageSalesUnits || 0)))).toFixed(2)
                                                //console.log('cartageSalesTotal', cartageSalesTotal)

                                                props.setCurrentGrainOrder({...props.currentGrainOrder, cartageSales: Number(event.target.value).toFixed(2),
                                                        cartageSalesTotal: cartageSalesTotal, adjustedBase: adjustedBase, payroll: payroll, dollarFuelSurcharge: dollarFuelSurcharge}) 
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Cartage Sales Units:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            value={props.currentGrainOrder.cartageSalesUnits}
                                            onChange={(event) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, cartageSalesUnits: event.target.value}) 
                                            }}
                                            onBlur={(event) => {
                                                let operatorUnits = props.currentGrainOrder.operatorUnits || 0;
                                                let payroll = props.currentGrainOrder.payroll || 0.00;
                                                let dollarFuelSurcharge = props.currentGrainOrder.dollarFuelSurcharge || 0.00;

                                                if(!_.isUndefined(props.currentGrainOrder.linkOperatorToSalesRate) && props.currentGrainOrder.linkOperatorToSalesRate){
                                                    operatorUnits = Number(event.target.value)
                                                    payroll = Number((props.currentGrainOrder.adjustedBase || 0.00) * Number(event.target.value) * ((props.currentGrainOrder.operatorRate || 0))).toFixed(2)
                                                }

                                                dollarFuelSurcharge = Number(Number((props.currentGrainOrder.percentageFuelSurcharge || 0.000) / 100)  * Number(Number((props.currentGrainOrder.cartageSales || 0.00)) * Number(event.target.value))).toFixed(2)

                                                props.setCurrentGrainOrder({...props.currentGrainOrder, cartageSalesTotal: Number(Number(event.target.value) * Number((props.currentGrainOrder.cartageSales|| 0.00))).toFixed(2), 
                                                    operatorUnits: operatorUnits, payroll: payroll, dollarFuelSurcharge: dollarFuelSurcharge}) 
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Cartage Per:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <Autocomplete
                                            open={autocompletes.ownerPer.open}
                                            onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, ownerPer: {...previousValues.ownerPer, open: true}} }) }}
                                            onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, ownerPer: {...previousValues.ownerPer, open: false}} }) }}
                                            label="Search"
                                            id="ownerPerAutocomplete"
                                            //className={classes.selectRow}
                                            autoComplete
                                            autoHighlight
                                            disableClearable
                                            fullWidth
                                            size='small'
                                            freeSolo={true}
                                            inputValue={props.currentGrainOrder.ownerPer}
                                            onInputChange={(event, newValue, reason) => { 
                                                if(reason === 'input') { props.setCurrentGrainOrder({...props.currentGrainOrder, ownerPer: newValue}) } 
                                            }}
                                            value={props.currentGrainOrder.ownerPer || ''}
                                            onChange={(event, newValue) => { 
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, ownerPer: newValue}) 
                                            }}
                                            options={props.per.map((per) => per ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                            getOptionSelected={(option, value) => option === value}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    //label="Destination (To)"
                                                    //variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Cartage Sales:</Typography>
                                    </Grid>
                                    <Grid item xs={6} >

                                        <Typography
                                            className={classes.textRow}
                                            style={{borderBottom: '1px solid grey'}}
                                            fullWidth
                                        >
                                            $&nbsp;&nbsp;&nbsp;{props.currentGrainOrder.cartageSalesTotal}
                                        </Typography>

                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography className={classes.textRowTypography}><small>Link Operator Rate to the Sales Rate:</small></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justifyContent='flex-end'>
                                            <Checkbox 
                                                className={classes.textRow}
                                                variant="contained" 
                                                checked={props.currentGrainOrder.linkOperatorToSalesRate}
                                                onClick={(event) => {
                                                    props.setCurrentGrainOrder({ ...props.currentGrainOrder, linkOperatorToSalesRate: event.target.checked })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {(!_.isUndefined(props.currentGrainOrder.linkOperatorToSalesRate) && props.currentGrainOrder.linkOperatorToSalesRate) &&
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Operator Base Multiplier:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            value={props.currentGrainOrder.operatorBaseMultiplier}
                                            onChange={(event) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, operatorBaseMultiplier: event.target.value}) 
                                            }}
                                            onBlur={(event) => {
                                                let adjustedBase = Number((Number((props.currentGrainOrder.cartageSales || 0.00))) * (Number(event.target.value / 100))).toFixed(2)

                                                let payroll = Number((Number(event.target.value / 100) * Number((props.currentGrainOrder.cartageSales|| 0.00)) * Number((props.currentGrainOrder.operatorUnits || 0))) * ((props.currentGrainOrder.operatorRate || 0))).toFixed(2)

                                                props.setCurrentGrainOrder({...props.currentGrainOrder, operatorBaseMultiplier: Number(event.target.value).toFixed(2),
                                                    adjustedBase: adjustedBase, payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                }
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Grid container justifyContent='center'> 
                                    <Typography style={{fontWeight: 600}} className={classes.textRow}>Operator</Typography>
                                </Grid>
                                <Divider className={classes.textRow}/>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Payroll Rate:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            
                                            value={props.currentGrainOrder.operatorRate}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            onChange={(event) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, operatorRate: event.target.value}) 
                                            }}
                                            onBlur={(event) => {
                                                let payroll = Number(Number((props.currentGrainOrder.adjustedBase || 0.00)) * Number((props.currentGrainOrder.operatorUnits || 0)) * (Number(event.target.value))).toFixed(2)
                                                //console.log('payroll', payroll)

                                                props.setCurrentGrainOrder({...props.currentGrainOrder, operatorRate: Number(event.target.value).toFixed(2),
                                                    payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Adjusted Base:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            value={props.currentGrainOrder.adjustedBase}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            onChange={(event) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, adjustedBase: event.target.value}) 
                                            }}
                                            onBlur={(event) => {
                                                let payroll = props.currentGrainOrder.payroll || 0.00;
                                                let adjustedBase = props.currentGrainOrder.adjustedBase || 0.00;

                                                if(!_.isUndefined(props.currentGrainOrder.linkOperatorToSalesRate) && props.currentGrainOrder.linkOperatorToSalesRate){
                                                    adjustedBase = Number((Number(props.currentGrainOrder.cartageSales|| 0.00)) * (Number((props.currentGrainOrder.operatorBaseMultiplier || 100.00)) / 100)).toFixed(2)
                                                    payroll = Number(Number(adjustedBase) * Number((props.currentGrainOrder.operatorUnits || 0)) * (Number((props.currentGrainOrder.operatorRate || 0)))).toFixed(2)
                                                }else{
                                                    adjustedBase = Number(event.target.value).toFixed(2)
                                                    payroll = Number((Number(adjustedBase) * Number((props.currentGrainOrder.operatorUnits || 0))) * Number((props.currentGrainOrder.operatorRate || 0))).toFixed(2)
                                                }

                                                props.setCurrentGrainOrder({...props.currentGrainOrder, adjustedBase: adjustedBase, payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Payroll Units:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            value={props.currentGrainOrder.operatorUnits}
                                            onChange={(event) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, operatorUnits: event.target.value}) 
                                            }}
                                            onBlur={(event) => {
                                                let payroll = props.currentGrainOrder.payroll || 0.00;
                                                let operatorUnits = props.currentGrainOrder.adjustedBase || 0.00;

                                                if(!_.isUndefined(props.currentGrainOrder.linkOperatorToSalesRate) && props.currentGrainOrder.linkOperatorToSalesRate){
                                                    operatorUnits = Number((props.currentGrainOrder.cartageSalesUnits || 0))
                                                    payroll = Number((Number((props.currentGrainOrder.adjustedBase || 0.00)) * Number(operatorUnits)) * (Number((props.currentGrainOrder.operatorRate || 0)))).toFixed(2)
                                                }else{
                                                    operatorUnits = Number(event.target.value)
                                                    payroll = Number((Number((props.currentGrainOrder.adjustedBase|| 0.00)) * Number(operatorUnits)) * (Number((props.currentGrainOrder.operatorRate || 0)))).toFixed(2)
                                                }

                                                props.setCurrentGrainOrder({...props.currentGrainOrder, operatorUnits: operatorUnits, payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Payroll Per:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            open={autocompletes.operatorPer.open}
                                            onOpen={() => { setAutocompletes((previousValues) => { return {...previousValues, operatorPer: {...previousValues.operatorPer, open: true}} }) }}
                                            onClose={() => { setAutocompletes((previousValues) => { return {...previousValues, operatorPer: {...previousValues.operatorPer, open: false}} }) }}
                                            label="Search"
                                            id="operatorPerAutocomplete"
                                            //className={classes.selectRow}
                                            autoComplete
                                            autoHighlight
                                            disableClearable
                                            fullWidth
                                            size='small'
                                            freeSolo={true}
                                            inputValue={props.currentGrainOrder.operatorPer}
                                            onInputChange={(event, newValue, reason) => { 
                                                if(reason === 'input') { props.setCurrentGrainOrder({...props.currentGrainOrder, operatorPer: newValue}) }
                                            }}
                                            value={props.currentGrainOrder.operatorPer || ''}
                                            onChange={(event, newValue) => {
                                                props.setCurrentGrainOrder({...props.currentGrainOrder, operatorPer: newValue}) 
                                            }}
                                            options={props.per.map((per) => per ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                            getOptionSelected={(option, value) => option === value}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    //label="Destination (To)"
                                                    //variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography className={classes.textRowTypography}>Payroll:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            className={classes.textRow}
                                            style={{borderBottom: '1px solid grey'}}
                                            fullWidth
                                        >
                                            $&nbsp;&nbsp;&nbsp;{props.currentGrainOrder.payroll}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>

                    <Grid container>
                        <Paper className={classes.paper}>
                            <Typography style={{fontWeight: 600}}>Operator Instructions:</Typography>

                            <TextField
                                style={{marginTop: '10px',}}
                                variant='outlined'
                                fullWidth
                                multiline={true}
                                minRows={20}
                                placeholder='Type your instructions here...'
                                value={props.currentGrainOrder.instructions}
                                onChange={(event) => {

                                    props.setCurrentGrainOrder({...props.currentGrainOrder, instructions: event.target.value}) 
                                }}
                            />
                        </Paper>
                    </Grid>

                    <Grid container justifyContent='flex-end'>
                        <Button 
                            style={props.saving === -1 ? {marginTop: '20px', marginBottom: '20px', color:'white', backgroundColor:'green'} : {marginTop: '20px', marginBottom: '20px'}}
                            variant='contained'
                            disabled={props.saving}
                            onClick={async ()=> {
                                await props.saveData(props.currentGrainOrder).then(() => {
                                    setAutocompletes(defaultAutocompletes)
                                })
                            }}
                        >
                            {props.saving === 1 ? 'Processing...' : props.saving === -1 ? 'Success!' : 'Create'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withRouter(GrainOrdersCreateCard)