import React, { useEffect, useState } from 'react';
import { Divider, makeStyles, Typography, Button, Grid, Checkbox, FormControlLabel, FormLabel, FormControl, FormGroup, TextField } from '@material-ui/core';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { Autocomplete } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import OptionsList from '../../UI/SearchFilters/OptionsList/OptionsList';
import { useUser } from '../../../../providers/UserContext'
import { useTimesheetType } from '../../../../providers/TimesheetTypeContext';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        width: '100%',
    },

    filterItem: {
        marginTop: '2px',
        marginBottom: '2px',
    },

    search: {
        display: 'flex',
        boxShadow: 'inset 0px 0px 2px 2px rgba(207,207,207,0.49)',
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

const TimesheetOptions = (props) => {

    const { users, userLoading, getUsers } = useUser();
    const { timesheetType } = useTimesheetType();

    //const [open, setOpen] = useState(false);
    const [openTypeFilter, setOpenTypeFilter] = useState(false);
    const classes = useStyles();

    //Search Autocomplete Values
    //const [typedValue, setTypedValue] = useState(JSON.parse(sessionStorage.getItem("timesheetOptionsSearchValue")) || '')
    const [typedTimesheetTypeValue, setTypedTimesheetTypeValue] = useState(JSON.parse(sessionStorage.getItem("timesheetTypeSearchValue")) || '')

    useEffect(() => {
        getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>

                <Grid container className={classes.search}>
                    <Grid item sm={12} className={classes.filterItem}>
                            <DatePicker
                                label="Start Date"
                                value={props.startDate}
                                onChange={props.startDateHandler}
                            />
                        </Grid>

                        <Grid item sm={12} className={classes.filterItem}>
                            <DatePicker
                                label="End Date"
                                value={props.endDate}
                                onChange={props.endDateHandler}
                            />
                    </Grid>
                </Grid>

                <Grid item sm={12}>
                    {(props.errorMsg && <Typography>{props.errorMsg}</Typography>)}
                </Grid>
                <Grid item sm={12} className={classes.btnGroup}>
                    <Button variant="contained" fullWidth disabled={props.loading} onClick={props.generateHandler} > Generate </Button>
                </Grid>

            </Grid>

            <Grid item sm={12}>
                <Grid item sm={12}>
                    <Typography variant="h6">Filters</Typography>
                    <Divider  style={{ marginBottom: '10px' }}/>
                </Grid>

                <Grid container className={classes.search}>

                    {/*  timesheetType auto complete here */}
                    <Grid item sm={12}>
                        <Autocomplete
                            open={openTypeFilter}
                            onOpen={() => {
                                setOpenTypeFilter(true);
                            }}
                            onClose={() => {
                                setOpenTypeFilter(false);
                            }}
                            loading={userLoading}
                            label="Search"
                            id="timesheetTypeAutocomplete"
                            autoComplete
                            autoHighlight
                            inputValue={typedTimesheetTypeValue}
                            onInputChange={(event, newValue) => { setTypedTimesheetTypeValue(newValue); props.typeSearchValueChanged(newValue);  }}
                            value={props.typeSearchValue}
                            onChange={(event, newValue) => { props.typeSearchValueChanged(newValue); sessionStorage.setItem("timesheetTypeAutoComplete", JSON.stringify(newValue)); }}
                            options={timesheetType}
                            //getOptionSelected={(option, value) => option === value}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type"
                                    variant="outlined"
                                />
                            )}

                        />
                    </Grid>

                    <Grid item sm={12}>
                        <Typography>Employee(s) {props.searchUsers.length > 0 && props.searchUsers.length + ' Selected'}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <OptionsList 
                            loading={userLoading} list={users.filter(user => props.timesheetData.map(x => x.uid).includes(user.displayName))
                                .filter(x=> x.employment?.active)
                                .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
                                .map((user) => user.displayName ?? '')}
                            searchItems={props.searchUsers} setSearchItems={props.setSearchUsers}
                        />
                     </Grid>

                    <Grid item sm={12} className={classes.filterItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend" className={classes.formLabel}>Include:</FormLabel>
                            <FormGroup>
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.unsubmitted} onChange={props.handler} name="unsubmitted" />}
                                    label="Unsubmitted"
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.submitted} onChange={props.handler} name="submitted" />}
                                    label="Submitted"
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.approved} onChange={props.handler} name="approved" />}
                                    label="Approved"
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.payrolled} onChange={props.handler} name="payrolled" />}
                                    label="Payrolled"
                                />
                                <FormControlLabel 
                                    control={<Checkbox checked={props.checkboxState.paid} onChange={props.handler} name="paid" />}
                                    label="Paid"
                                />
                            </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend" className={classes.formLabel}>Status</FormLabel>
                            <FormGroup>
                                {/*<FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.unseen} onChange={props.handler} name="unseen" />}
                                    label="Unseen"
                                />*/}
                                {/*<FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.viewed} onChange={props.handler} name="viewed" />}
                                    label="Viewed"
                                />*/}
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.printed} onChange={props.handler} name="printed" />}
                                    label="Printed"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.notprinted} onChange={props.handler} name="notprinted" />}
                                    label="Not Printed"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={props.checkboxState.both} onChange={props.handler} name="both" />}
                                    label="Both"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sm={12} className={classes.btnGroup}>
                <Button variant="contained" size={"small"} style={{marginBottom:'1px'}} fullWidth disabled={props.loading} onClick={props.handlePrint} > Print Table </Button>
                <Button variant="contained" size={"small"} style={{marginBottom:'1px'}} fullWidth disabled={props.loading} onClick={props.handlePrintPayroll} > Print Payroll Report </Button>
                <Button variant="contained" size={"small"} fullWidth disabled={props.loading} onClick={props.handlePrintTotal} > Print Total Hours </Button>
            </Grid>

        </Grid>
    )
}

export default TimesheetOptions;