import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility'

const initialState = {
    timesheetsReport: [],
    jobReportTable: [],
    defectReportTable: [],
    unitReportTable: [],
    deliveriesReportTable: [],
    checklistReportTable: [],
    photosReportTable: [],
    documentsReportTable: [],
    trainingReportTable: [],
}

const setTimesheetReport = (state, action) => {
    return {
        ...state,
        timesheetsReport: action.timesheetsReport,
    };
}

const reportStorageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TIMESHEETREPORT:
            return updateObject(state, setTimesheetReport(state, action))
        default:
            return state;
    }
};

export default reportStorageReducer;