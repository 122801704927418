import React, { useState, useEffect } from 'react';
import {Grid, makeStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import MaterialTable from '@material-table/core';
//import moment from 'moment'
import { TableIcons } from '../../../constants/tableIcons';
import { useAuth } from '../../../providers/AuthContext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({

  card: {
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  table: {
    display: 'flex',
    justifyContent: 'center',
  },

  tile: {
    width: '300px',
    height: '300px',
  },

  tileContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },

  tileIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100px',
    height: '100px',
  },

  tileText: {
    fontSize: '1.10rem',
  },
  btn: {
    marginBottom: '20px',
    marginTop: '20px',
},
}));

const EquipmentHome = (props) => {

  const [equipmentData, setEquipmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState("equipmentSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("equipmentSearchBarValue")) : null)
  const [page, setPage] = useState("equipmentPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("equipmentPageValue")) : 0)

  const { roles } = useAuth();

  const classes = useStyles();

  useEffect(() => {
    firestore.collection('unitDescriptions').get()
      .then(querySnapshot => {
        const equipmentResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,  
          }
        })
        console.log('unitDescriptions Home : ' + equipmentResponse.length );

        setEquipmentData(equipmentResponse);
        setLoading(false);
      })
      .catch(e => {
        setError("Unable to Process");
        setLoading(false);
        console.log(e.message);
      })
  }, []);

  const handleOnSearchChange = (value) => {
    setSearchBarValue(value);  
    sessionStorage.setItem("equipmentSearchBarValue", JSON.stringify(value));
    setPage(0);  
    sessionStorage.setItem("equipmentPageValue", JSON.stringify(0));
  }

  const handleOnPageChange = (pageNum) => {
    setPage(pageNum);  
    sessionStorage.setItem("equipmentPageValue", JSON.stringify(pageNum));
  }

  const tileClickHandler = (path) => {
    props.history.push({
      pathname: 'equipment/' + path,
    })
  };

  return (
    <div className={classes.content}>
      <Grid>

        <Grid item xs={12} className={classes.btn}>
          <Button variant='contained' onClick={() => tileClickHandler('new')} startIcon={<AddBoxIcon/>} 
                  style={roles.includes('Create|Update|Delete Equipment')  ? {visibility: 'visible'} : {visibility: 'hidden'}}>
            Create New Equipment
          </Button>
        </Grid>


        {!loading && !error
          ? <Grid item sm={12} xs={12} className={classes.table}>
            <MaterialTable
              icons={TableIcons}
              columns={[
                { title: 'Unit Number', field: 'unitNumber' },
                { title: 'Description', field: 'description' },  
                { title: 'Serial Number', field: 'serialNum' },  
                { title: 'License Plate', field: 'licensePlate' },  
                { title: 'Equipment Type', field: 'class' },  
                { title: 'Is Active', field: 'isActive' },                  
              ]}
              data={equipmentData}
              style={{minWidth: '65vw'}}
              title={'Equipment'}
              onSearchChange={ (value) => {handleOnSearchChange(value) }}
              onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
              actions={[
                rowData => ({
                  icon: TableIcons.OpenInNew,
                  tooltip: 'Show Equipment',
                  onClick: (event, rowData) => {
                    props.history.push({
                      pathname: '/dashboard/equipment/form',
                      search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                    })
                  }
                })
              ]}

              options={{
                toolbar: true,
                paging: true,
                initialPage: page,
                pageSizeOptions: false,
                pageSize: 10,
                actionsColumnIndex: -1,
                search: true,
                emptyRowsWhenPaging: false,
                thirdSortClick: false,
                searchText: searchBarValue,
                rowStyle: {
                    whiteSpace: 'pre-wrap',
                },
              }}
            />
            </Grid>
          : 
          <Grid>
            <Skeleton variant='rect' width={'65vw'} height={'76vh'} />
          </Grid>
          }
        </Grid>
    </div>
  )
}

export default withRouter(EquipmentHome);