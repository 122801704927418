import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router'
import { firestore, removeFile, storage } from '../../../firebase/firebase'
import EquipmentCard from '../../components/Equipment/EquipmentCard'
import { Grid, makeStyles, Snackbar, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash'
import { useCustomer } from '../../../providers/CustomerContext';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width:'100%',
        padding: '32px',
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const Equipment = (props) => {
    const classes = useStyles();
    const { customers, loading, getCustomers } = useCustomer();
    const throwAsyncError = useAsyncError();

    const [duplicateSerial, setDuplicateSerial] = useState(false)
    const [allEquipmentData, setAllEquipmentData] = useState([])
    const [equipmentData, setEquipmentData] = useState({});
    const [localLoading, setLocalLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});
    const [deleteData, setDeleteData] = useState(null)
    //const [defectData, setDefectData] = useState([])
    //const [unitReportData, setUnitReportData] = useState([])
    //const [deliveriesData, setDeliveriesData] = useState([])
    //const [geotabData, setGeotabData] = useState([])

    const initialequipmentData = {
        unitNumber: '',
        year: '',
        class: null,
        model: '',
        make: '',
        licensePlate: '',
        features: '',
        serialNum: '',
        tare: '',
        isOwned: true,
        isActive: true,
        owner: null,
        marketValue: '',
        purchasePrice: '',
        purchasedFrom: '',
        purchaseDate: moment().valueOf(),
        leasedFinancedFrom: '',
        description: '',
        registrations: [],
    }

    const equipmentClasses = [
        'Equipment - Dozer', 'Equipment - Drum Roller', 'Equipment - Excavator', 'Equipment - Grader', 'Equipment - Loader', 'Equipment - Skid Steer',
        'Equipment - Other', 'Truck - Tandem', 'Truck - Tractor', 'Trailer - Belly Dump', 'Trailer - Deck', 'Trailer - End Dump', 'Trailer - Grain Bulker',
        'Trailer - Side Dump', 'Truck - Pick up or Similar', 'Trailer - Cargo/Utility', 'Trailer - Water',
    ];
    let path = new URLSearchParams(props.location.search).get("docId");

    //gets initial data  
    useEffect(() => {
        try{
            getCustomers()

            firestore.collection('unitDescriptions').get()
                .then(querySnapshot => {
                    let equipmentResponse = (querySnapshot.docs.map((doc) => {
                        return {
                        ...doc.data(),
                        'docId': doc.id,
                        }
                    }))

                    setAllEquipmentData(equipmentResponse)
                    let currentEquipment = equipmentResponse.find(x=> x.docId === path)
                    //ensure current doc has all neccessary fields to not prompt save toast immediately
                    for (const [key, value] of Object.entries(initialequipmentData)) {
                        if(!currentEquipment.hasOwnProperty(key)){
                            currentEquipment[key] = value;
                        }
                    }
                    currentEquipment.serialNum !== '' && setDuplicateSerial( handleDuplicateSerialCheck(currentEquipment.serialNum, equipmentResponse, path) )
                    setEquipmentData(currentEquipment);
                    setSaveState(currentEquipment);
                    //here for the time being until below is implemented
                    setLocalLoading(false);

                    // firestore.collection('units').where('parentUnitDocId', '==', currentEquipment.docId).get()
                    // .then(querySnapshot => {
                    //     const unitReportResponse = querySnapshot.docs.map((doc) => {
                    //     return {
                    //         ...doc.data(),
                    //         'docId': doc.id,
                    //     }
                    //     })
                    //     setUnitReportData(unitReportResponse)

                    //     let unitDocIdArray = unitReportResponse.map(x=> x.docId)
                    //     let timesheetIdArray = unitReportResponse.map(x=> x.timesheetID)
                    //     let defectDocIdArray = unitReportResponse.map(x=> x.defectID).filter(x=> !_.isEmpty(x))

                    //     let promiseArray = [
                    //         //this should stay because we should get geotab data here (all these fetches are happening concurrently )
                    //         batchGetDefects(defectDocIdArray),
                    //         batchGetDeliveries(timesheetIdArray, unitDocIdArray),
                    //     ]
        
                    //     Promise.all(promiseArray).then((res) => {
                    //         console.log('res', res)
                    //         //set all states from promiseArray
                    //         setLocalLoading(false);
                    //     })
                    // })
            })
        }
        catch(e){
            console.log(e.message);
            throwAsyncError(new Error(e.message, e));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    // const batchGetDefects = (docIdArray) => {
    //     return new Promise(async (resolve) => {
    //         let promiseArray = []
    //         for(let docId of docIdArray){ promiseArray.push(firestore.collection('defectReports').doc(docId).get()); }

    //         Promise.all(promiseArray).then(querySnapshots => {
    //             let value = querySnapshots.map(doc => { return {...doc.data(),'docId': doc.id} });
    //             resolve({defectReports: value})
    //         })
    //     })
    // }

    // //MUCH too slow set up index for this query ??? find different way to query this ???
    // const batchGetDeliveries = (docIdArray, unitDocIdArray) => {
    //     return new Promise((resolve) => {
    //         let promiseArray = []
    //         for(let docId of docIdArray){ promiseArray.push(firestore.collection('deliveries').where('timesheetID', '==', docId).get()) }

    //         Promise.all(promiseArray).then(querySnapshots => {
    //             let value = querySnapshots.map(querySnapshot => querySnapshot.docs.map((doc) => { return {...doc.data(), 'docId': doc.id,} }) )
    //             //remove empty values
    //             value = value.filter(x=> !_.isEmpty(x))
    //             //add each delivery query together
    //             value = value.reduce((accumulator, value) => accumulator.concat(value), []);
    //             //ensure each delivery has a unit within thats associated with this unit description  NEEDS FURTHER TESTING
    //             value.filter((delivery) => {
    //                 for(let unit of delivery.units){  if(unitDocIdArray.includes(unit)){ return true } }
    //                 return false
    //             })

    //             resolve({deliveries: value})
    //         })
    //     })
    // }

    const updateEquipmentData = useCallback((object, name) => {
        if(name === 'serialNum' && object.serialNum !== ''){
            setDuplicateSerial( handleDuplicateSerialCheck(object.serialNum, allEquipmentData, path) )
        }

        setEquipmentData(object)
    }, [allEquipmentData, path], );

    const confirmSave = async () => {
        try{
            let container = _.cloneDeep(equipmentData)
            setSaving(true);

            if(equipmentData.registrations?.filter(x=> typeof x.pdfURL === 'object' ).length > 0){
                container['registrations'] = await uploadAndOverwriteRegistration()
            }

            setEquipmentData(container);
            setSaveState(container);
            firestore.collection('unitDescriptions').doc(path).update(container)
                .then(() => {
                    setSaveState(container);
                    setSaving(false)
                })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    };

    const cancelSave = () => {
        saveState.serialNum !== '' && setDuplicateSerial( handleDuplicateSerialCheck(saveState.serialNum, allEquipmentData, path) )
        setEquipmentData(saveState);
    };

    const handleAddToLocal = (acceptedFiles) => {
        let equipmentContainer = _.cloneDeep(equipmentData)
        acceptedFiles.forEach((file) => { equipmentContainer.registrations.push({pdfURL: file, name: file.name, docId: path}) })
        setEquipmentData(equipmentContainer)
    }

    const handleRemoveFromLocal = (index) => {
        let equipmentContainer = _.cloneDeep(equipmentData)
        equipmentContainer.registrations.splice(index, 1)
        setEquipmentData(equipmentContainer)
    }

    const uploadAndOverwriteRegistration = () => {
        return new Promise(async (resolve) => {
            try{
                let registrationContainer = _.cloneDeep(equipmentData.registrations);
                const storageRef = storage.ref();

                for (const [index, file] of registrationContainer.entries()) {
                    if(typeof file.pdfURL === 'object'){
                        const pdfRef = storageRef.child('Registrations/' + file.name + '_' + moment().valueOf() + '_' + path);
                        const snapshot = await pdfRef.put(file.pdfURL);
                        registrationContainer[index].pdfURL = await snapshot.ref.getDownloadURL()
                    }
                }
                resolve(registrationContainer)
            }
            catch(e){
                throwAsyncError(new Error(e.message, e));
            }
        })
    }

    const handleDeleteClick = async (pdfURL) => {
        try{
            setSaving(true);
            await removeFile(pdfURL)
            .then(() => {
                let container = _.cloneDeep(equipmentData)
                let registrationIndex = container.registrations.findIndex(x=> x.pdfURL === pdfURL)
                container.registrations.splice(registrationIndex, 1)

                setEquipmentData(container);
                setSaveState(container);
                firestore.collection('unitDescriptions').doc(path)
                    .update(container)
                    .then(() => {
                        setSaveState(container);
                        setSaving(false)
                        setDeleteData(null)
                    })
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    const handleDuplicateSerialCheck = (serialNumber, checkAgainstArray, currentDocId) => {
        let arrayContainer = checkAgainstArray.filter(x=> x.docId !== currentDocId)
        for(const equipment of arrayContainer) { if(equipment.serialNum === serialNumber){ return true } }
        return false
    }

    return (
        <Grid>
            {localLoading
                ?
                <Grid className={classes.content}>
                    <Skeleton variant='rect' width={'50vw'} height={'83vh'}/>
                </Grid>
                :
                <Grid>
                    <EquipmentCard
                        equipmentData={equipmentData}
                        saveState={saveState}
                        setSaveState={setSaveState}
                        setEquipmentData={setEquipmentData}
                        cancelSave={cancelSave}
                        confirmSave={confirmSave}
                        updateEquipmentData={updateEquipmentData}
                        saving={saving}
                        customers={customers}
                        //customer loading just with a shit name
                        loading={loading}
                        equipmentClasses={equipmentClasses}
                        path={path}
                        handleAddToLocal={handleAddToLocal}
                        handleRemoveFromLocal={handleRemoveFromLocal}
                        handleDeleteClick={handleDeleteClick}
                        deleteData={deleteData}
                        setDeleteData={setDeleteData}
                        duplicateSerial={duplicateSerial}
                    />

                    <Snackbar
                        open={!_.isEqual(equipmentData, saveState)}
                        message={saving ? 'Saving...' : 'Save Document?'}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        action={
                            <React.Fragment>
                                {saving
                                    ? null
                                    : <React.Fragment>
                                        <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                        <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        } />
                </Grid>}
        </Grid>
    )
}

export default withRouter(Equipment);